import React from 'react'

import { Button, ButtonProps, Card, theme } from '@middesk/components'
import styled from 'styled-components'

const { colors, spacing, typography } = theme

const StyledCard = styled(Card)`
  border: 2px solid ${colors.dawn};
  display: block;
  grid-column: span 2;
  padding: ${spacing.xxlarge};
  text-align: center;
  width: 100%;
`

const StyledImg = styled.img`
  margin: 0 auto;
  max-width: 100px;
`

const Header = styled.h2`
  font-family: ${typography.faces.display}, sans-serif;
  font-size: 30px;
  margin: ${spacing.large} 0;
`

const Message = styled.div`
  color: ${colors.graphite};
  margin-bottom: 30px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`

const NotFound = ({
  image,
  header,
  message,
  button,
  secondaryButton
}: {
  image: { src: string; alt: string }
  header: string
  message: string
  button: ButtonProps
  secondaryButton?: ButtonProps
}) => {
  return (
    <StyledCard>
      <StyledImg src={image.src} alt={image.alt} />
      <Header>{header}</Header>
      <Message>{message}</Message>
      <ButtonContainer>
        {secondaryButton && (
          <Button
            onClick={secondaryButton?.onClick}
            type='secondary'
            href={secondaryButton?.href}
          >
            {secondaryButton?.children}
          </Button>
        )}
        <Button to={button.to} onClick={button?.onClick}>
          {button.children}
        </Button>
      </ButtonContainer>
    </StyledCard>
  )
}

export default NotFound
