import React from 'react'

import { theme } from '@middesk/components'
import moment from 'moment'
import styled from 'styled-components'

const { colors } = theme

export default styled(function ({
  format,
  children,
  exact = false,
  relative = false,
  ...props
}) {
  if (!children) {
    return <time />
  }

  const time = moment(children)
  const lastWeek = moment().subtract(1, 'w')
  let formatted =
    !exact && (relative || lastWeek < time ? time.fromNow() : time.format('LL'))

  if (format) {
    formatted = format(formatted)
  }

  return (
    <time dateTime={children} title={time.format('LLL Z')} {...props}>
      {formatted}
    </time>
  )
})`
  ${({ secondary }) =>
    secondary
      ? `
    color: ${colors.karl};
    display: block;
    margin: 0.25rem 0;
  `
      : ''}
`
