import React, { useEffect, useState } from 'react'

import { Card, theme } from '@middesk/components'
import { captureException } from '@sentry/browser'
import { isRouteErrorResponse, useRouteError } from 'react-router'
import styled from 'styled-components'

import NotFound from 'components/BusinessHome/NotFound'
import { Env } from 'env'

const { colors } = theme

const Page = styled.div`
  background-color: ${colors.frostLight};
  margin-top: 100px;
  text-align: center;
`

const StyledCard = styled(Card)`
  border: 2px solid ${colors.dawn};
  border-radius: 10px;
  margin: auto;
  max-width: 800px;
  padding: 40px;

  h2 {
    margin-top: 0;
  }
`

const Fallback = ({
  openSupportModal
}: {
  openSupportModal?: () => void
}): React.ReactElement => {
  return (
    <Page>
      <StyledCard>
        <NotFound
          image={{
            src: process.env.PUBLIC_URL + '/images/business-not-found.png',
            alt: 'An error occurred'
          }}
          header='An error occurred'
          message='Please try refreshing the page or click on the 
          link below to return to the business list. If the problem persists contact support.'
          button={{
            to: '/businesses',
            children: 'Go to business list'
          }}
          secondaryButton={{
            onClick: openSupportModal,
            href: !openSupportModal ? 'mailto:support@middesk.com' : undefined,
            children: 'Contact Support'
          }}
        />
      </StyledCard>
    </Page>
  )
}

type Props = {
  openSupportModal?: () => void
  children?: React.ReactNode
}

const ErrorBoundary = ({ openSupportModal }: Props) => {
  const routeError = useRouteError()

  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (routeError) {
      let error = isRouteErrorResponse(routeError)
        ? routeError.data
        : routeError

      if (typeof error === 'string') {
        error = new Error(error)
      } else if (!(error instanceof Error)) {
        error = new Error(JSON.stringify(error))
      }

      try {
        const hash = error.request
          ?.replace(`${Env.APP_URL}/static/js/`, '')
          ?.replace('.chunk.js', '')

        if (
          error.name === 'ChunkLoadError' &&
          !window.sessionStorage.getItem(hash)
        ) {
          window.sessionStorage.setItem(hash, 'true')
          window.location.reload()
        } else {
          throw error
        }
      } catch (e) {
        captureException(error)

        setHasError(true)
      }
    }
  }, [routeError])

  return hasError ? <Fallback openSupportModal={openSupportModal} /> : <></>
}

export default ErrorBoundary
