import React from 'react'

import { theme } from '@middesk/components'
import { useSpring } from 'react-spring'
import styled from 'styled-components'

import PageBanner from 'components/Banners/PageBanner'
import { Close } from 'components/Icons'
import { API_SANDBOX_GUIDE_LINK } from 'constants/agent_constants'
import { useSandboxMode } from 'containers/SandboxProvider'

import cya from '../../macros/cya.macro' // eslint-disable-line no-relative-import-paths/no-relative-import-paths

const { colors, typography } = theme

const Title = styled.div<{ $agentOnly: boolean }>`
  color: ${({ $agentOnly }) => ($agentOnly ? colors.black : colors.white)};
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};

  span {
    font-weight: ${typography.weights.normal};
  }

  a {
    color: ${({ $agentOnly }) => ($agentOnly ? colors.black : colors.white)};
    cursor: pointer;
    font-weight: ${typography.weights.normal};
    text-decoration: underline;
  }
`

const StyledClose = styled(Close)<{ $agentOnly: boolean }>`
  cursor: pointer;
  fill: ${({ $agentOnly }) => ($agentOnly ? colors.black : colors.white)};
  height: 25px;
  stroke: ${({ $agentOnly }) => ($agentOnly ? colors.black : colors.white)};
  width: 25px;
`

const StyledPageBanner = styled(PageBanner)`
  margin-left: -74px;
  padding-left: 24px;
  z-index: 4;
`

const SandboxBanner = ({ agentOnly }: { agentOnly: boolean }) => {
  const { sandboxMode, toggleSandboxMode } = useSandboxMode()

  const style = useSpring({
    marginTop: sandboxMode ? '0px' : '-45px',
    config: {
      duration: 250
    }
  })

  const sandboxGuide = agentOnly
    ? API_SANDBOX_GUIDE_LINK
    : 'https://docs.middesk.com/docs/using-the-test-environment#basic-test-businesses'

  const primaryColor = agentOnly ? colors.yellowLight : colors.silasDark3

  return (
    <StyledPageBanner
      {...{ style, primaryColor }}
      {...cya('sandboxBanner')}
      $agentOnly={agentOnly}
    >
      <Title $agentOnly={agentOnly}>
        Sandbox&nbsp;&nbsp;<span>To learn more</span>{' '}
        <a href={sandboxGuide} target='_blank' rel='noreferrer'>
          view our docs
        </a>
      </Title>
      <StyledClose
        {...{
          onClick: () => {
            toggleSandboxMode(false)
          }
        }}
        {...cya('sandboxClose')}
        $agentOnly={agentOnly}
      />
    </StyledPageBanner>
  )
}

export default SandboxBanner
