import React from 'react'

import { theme } from '@middesk/components'
import PropTypes from 'prop-types'
import ReactAvatar, { getRandomColor } from 'react-avatar'
import styled from 'styled-components'

const { colors } = theme

const COLORS = [
  colors.redLight,
  colors.blueLight,
  colors.orangeLight,
  colors.yellowLight,
  colors.greenLight
]

const StyledReactAvatar = styled(ReactAvatar)`
  &.sb-avatar {
    align-items: center;
    border-radius: ${({ round }) => round};
    display: inline-flex;
    font-family: inherit;
    height: ${({ size }) => size};
    overflow: hidden;
    vertical-align: middle;
    width: ${({ size }) => size};
  }

  .sb-avatar__image {
    height: auto;
    max-height: ${({ size }) => size};
    max-width: ${({ size }) => size};
    width: 100%;
  }

  .sb-avatar__text {
    background-color: ${({ color }) => color};
    color: ${colors.black};
    font-size: ${({ size }) => `${Math.round(parseInt(size, 10) / 3)}px`};
    height: inherit;
    line-height: initial;
    text-align: center;
    text-transform: uppercase;
    width: inherit;

    > div {
      display: table;
      height: 100%;
      table-layout: fixed;
      width: 100%;

      > span {
        display: table-cell;
        font-size: 100%;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
`

const Avatar = ({ className, name, size, round, src }) => {
  const props = name
    ? { name: name, color: getRandomColor(name, COLORS) }
    : {
        value: ' ',
        color: colors.karl
      }

  return (
    <StyledReactAvatar
      {...props}
      maxInitials={1}
      className={className}
      unstyled
      size={size}
      round={round}
      src={src}
    />
  )
}

Avatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  round: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  src: PropTypes.string
}

export default Avatar
