import React, { useEffect, createContext, useContext } from 'react'

import { useDispatch } from 'react-redux'

import { toggleSandboxMode } from 'actions'
import useLocalStorage from 'hooks/storage/useLocalStorage'

type SandboxContextType = {
  toggleSandboxMode: (sandboxMode: boolean) => void
  sandboxMode: boolean
}

const SandboxContext = createContext<SandboxContextType | undefined>(undefined)

type ProviderProps = {
  children?: React.ReactNode
}
const SandboxProvider = ({ children }: ProviderProps) => {
  const [sandboxMode, setSandboxMode] = useLocalStorage('sandbox_mode', false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(toggleSandboxMode({ sandboxMode }))
  }, [dispatch, sandboxMode, setSandboxMode])

  return (
    <SandboxContext.Provider
      value={{
        sandboxMode: sandboxMode || false,
        toggleSandboxMode: setSandboxMode
      }}
    >
      {children}
    </SandboxContext.Provider>
  )
}

const useSandboxMode = () => {
  const context = useContext(SandboxContext)

  if (context === undefined) {
    throw new Error(
      'useSandboxMode must be used within a SandboxProvider parent component'
    )
  }

  return context
}

export { SandboxProvider, useSandboxMode }
