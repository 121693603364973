import { Dispatch } from '@reduxjs/toolkit'

import { ReviewTasks } from 'containers/Businesses/BusinessList/types'
import {
  Lien,
  LienStatusCategory,
  LienType,
  SecuredPartySettings
} from 'containers/Liens/types'
import { POLICY_AUTO_DECISION_TYPE } from 'containers/Settings/Policies/constants'
import {
  PolicyUpdateParams,
  UpdatePoliciesParams
} from 'containers/Settings/Policies/types'
import { WatchlistExclusion } from 'containers/Settings/Watchlists/types'
import store from 'store'

import {
  Address,
  AdverseMediaExclusionSettings,
  Business,
  Business as BusinessObject,
  Person
} from './containers/BusinessHome/types'

export type AppDispatch = typeof store.dispatch

export enum AbrvState {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}

/**
 * API Keys Resource
 */
export interface APIKey {
  value: string
  name: string
  created_at: string
  id: string
}

export interface APIKeyCollection {
  data: APIKey[] | undefined
}

interface APIKeysState {
  data: APIKeyCollection
}

/**
 * Webhook Resource
 */

export interface Webhook {
  id?: string
  url?: string
  created_at?: string
  enabled_events?: string[]
  secret?: string
  oauth_client_id?: string
  disabled_at?: string | null
}

export interface WebhookCollection {
  data: Webhook[] | undefined
}

interface WebhookState {
  data: WebhookCollection
}

export interface WebhookLog {
  id: string
  created_at: string
  completed_at: string
  event: {
    id: string
    namespace: string
    root_object_id: string
  }
  event_id: string
  event_type: string
  object: string
  request: {
    url: string
    headers: {
      [key: string]: string
    }
    body: {
      [key: string]: string
    }
  }
  response: {
    status: number
    headers: {
      [key: string]: string
    }
    body: string
    webhook_id: string
  }
}

interface WebhookLogState {
  isFetching: boolean
  data: WebhookLog
}

/**
 * Observable Events Resource that can be hooked onto via Webhooks
 */

export interface ObservableEventCollection {
  data: string[]
}

interface ObservableEventState {
  data: ObservableEventCollection
}

/**
 * OAuthClient Resource
 */

export interface OAuthClient {
  name?: string
  client_id?: string
  secret?: string
  redirect_uri: string
  logo?: OAuthLogo | string
}

interface OAuthLogo {
  data: string
  filename: string
}

export interface OAuthClientCollection {
  data: OAuthClient[] | undefined
}

/**
 * FileExports Resource
 */

export interface FileExport {
  id: string
  name: string
  status: string
  created_at: string

  business_export?: BusinessExport
}

interface BusinessExport {
  requester_name: string
  business_count: number
}

export type AccountFeature =
  | 'adverse_media'
  | 'agent'
  | 'bankruptcies'
  | 'certifications'
  | 'dashboard_order_experience'
  | 'document_verification'
  | 'documents'
  | 'enhanced_screenings'
  | 'fmcsa_registrations'
  | 'identity'
  | 'in_audit_notification'
  | 'industry_classification_inference'
  | 'kyc'
  | 'liens'
  | 'litigations'
  | 'monitoring'
  | 'monitoringv2'
  | 'new_business_overview'
  | 'packages'
  | 'ppp_loan_data'
  | 'scores'
  | 'scrape_google_places_website'
  | 'signal_businesses'
  | 'signals'
  | 'single_sign_on'
  | 'stale_registrations_task'
  | 'tax_exempt_organizations'
  | 'tax_registrations_dashboard'
  | 'tin_person_match'
  | 'true_industry_debundled'
  | 'v1_packages'
  | 'v2_packages'
  | 'watchlist_hits_scoring'

export type Account = {
  id: string
  features: { [feature in AccountFeature]: boolean }
  level: 'direct_sale' | 'trial' | 'self-serve'
  name: string
  settings: {
    agent: {
      peo_account: boolean | null
      local_taxes_allowed: boolean | null
    }
    assignment: { default_assignee_id: string | null }
    documents: {
      documents_v2_enabled: boolean
      default_document_types: string[]
      order_documents_automatically: boolean
    }
    kyc: { kyc_enabled: boolean }
    liens: {
      order_liens_automatically: boolean
      lien_filing_enabled: boolean
      default_collateral: string | null
      secured_party_details: SecuredPartySettings | null
      lien_reflection_enabled: boolean
      alternative_designations_enabled: boolean
      lien_documents_enabled: boolean
      lien_documents_v1_a_la_carte: boolean
    }
    litigations: { order_litigations_automatically: boolean }
    monitoring: {
      version: number
      event_recipient_email: string
      should_send_event_email: boolean
    }
    reordering: {
      enabled_packages: Partial<Record<Package, boolean>>
    }
    package_settings: PackageSetting[]
    people_bankruptcies: { people_bankruptcies_enabled: boolean }
  }
  slug: string
  agent_partner_profile?: {
    enabled_at?: string
    reseller: boolean
    read_only_dashboard: boolean
    api_access: boolean
    dashboard_access: boolean
    api_integration_checklist_data?: ApiIntegrationChecklistData
    revenue_share_percentage?: number
    analytics_dashboard_access: boolean
  }
  agent_account_settings?: {
    enabled_at?: string
  }
  has_active_agent_affiliate_profiles: boolean
  dashboard_requested: boolean
  partner_referral_link_url: string
  lien_filing_enabled: boolean
}

export type PackageSetting = {
  package_type: Package
  enabled: boolean
}

export type ApiIntegrationChecklistData = {
  initial_registration_request_id: string
  registration_request_created: boolean
  registration_request_fetched: boolean
  registration_request_webhooks_setup: boolean
  skip_webhooks_step: boolean
}

export type UserSettings = {
  notifications: {
    business_review_email_enabled: boolean
    tin_retry_email_enabled: boolean
    lien_filed_email_enabled: boolean
    lien_terminated_email_enabled: boolean
    lien_filing_summary_email_enabled: boolean | null
    lien_reflected_email_enabled: boolean
    agent_partner_report_frequency?: string
  }
  onboarding?: {
    show_welcome_modal?: boolean
    show_upgrade?: boolean
  }
  receives_agent_emails?: boolean
}

export type User = {
  object: 'user'
  id: string
  image_url?: string
  access_token?: string
  account: Account
  email?: string
  name?: string
  roles?: string[]
  settings: UserSettings
  last_login_at: string
  impersonator?: { id: string; name: string; email: string }
  isFetching?: boolean
}

interface Session {
  user?: User
}

export interface SessionState {
  data?: Session
}

interface AgenciesState {
  data?: Record<string, unknown>[]
  isFetching: boolean
}

interface Audit {
  auto_resolved: boolean
  completed_at: string | null
  created_at: string
  id: string
  object: 'audit'
  source: string
  status: string
  type: string
  updated_at: string
}

interface AuditsState {
  data?: Array<Audit>
  isFetching: boolean
}

interface PartnerInsightsState {
  data: PartnerInsight[]
  isFetching: boolean
}

interface PartnerAccountUsersListState {
  isFetching: boolean
  data?: User[]
  totalCount?: number
}

interface PartnerAccountUserCreationState {
  id?: string
  isFetching: boolean
}

export type PartnerAnalytics = {
  isFetching: boolean
  by_date: {
    date: string
    registration_count: number
    revenue_in_cents?: number
  }[]
  errors?: string[]
}

interface EmployerCommsList {
  isFetching: boolean
  totalCount: number
  data: MailItem[]
  has_more: boolean
}

interface PartnerCommsList {
  data: Array<MailItem | Email>
  totalCount: number
  hasMore: boolean
  isFetching: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartnerInsight = { key: string; value: any }

interface SandboxState {
  sandboxMode: boolean
}

export interface ModalState {
  modal: string
  type: string
  id?: string
  client?: boolean
  lien?: Lien
  policy?: PolicyModalState
  user?: { name: string; id: string }
  errors?: Array<{ message: string; deletionError: boolean }>
  src?: string
  roles?: Array<string>
  name?: string
}

type PolicyModalState = {
  id: string
  name: string
  updateParams: PolicyUpdateParams
  updatePoliciesParams: UpdatePoliciesParams
  navigateTo: string
}

interface ReferralLinkState {
  link: string
  isFetching: boolean
  isCreating: boolean
}

type LienFilters = {
  lienStatuses: Partial<Record<LienStatusCategory, Lien[]>>
  lienTypes: Partial<Record<LienType, Lien[]>>
}

export type BusinessState = {
  data?: BusinessObject & LienFilters
  errors?: { message: string }[]
  isFetching: boolean
  isNotFound?: boolean
  isRefreshing: boolean
  isSnapshot?: boolean
  isUpdating: boolean
  registrationSortBy: string
  registrationSortOrder: string
}

interface BusinessesState {
  data?: Business[]
  total?: number
  isFetching: boolean
}

interface UserState {
  data?: User[]
  total: number
  isFetching: boolean
}

interface BusinessBatchesState {
  data?: {
    id: string
    name: string
    business_count: number
    completed_business_count: number
    filename: string
  }[]
}

interface InsightKeysState {
  data?: {
    [key: string]: string[]
  }
  isFetching: boolean
}

export type InsightKeyStatus = 'success' | 'warning' | 'failure' | 'unknown'

export type InsightKeyStatusMapping = {
  [key: string]: {
    subkey: string
    status: InsightKeyStatus
  }
}

interface InsightKeyStatusMappingsState {
  data?: InsightKeyStatusMapping[]
  isFetching: boolean
}

interface KYCReasonCodesState {
  data?: {
    [key: string]: string
  }
  isFetching: boolean
}

interface FilteredBusinessesCSVState {
  isFetching: boolean
  errors?: string[]
  enqueued: boolean
}

interface SettingsState {
  isFetching: boolean
  data?: {
    adverse_media: {
      exclusion: AdverseMediaExclusionSettings
    }
    watchlist: {
      enabled: boolean
      exclusion: WatchlistExclusion
    }
    address_sources: {
      excluded_sources: string[]
    }
    monitoring: {
      event_recipient_email: string
      should_send_event_email: boolean
      automatic_monitor_types: string[]
    }
  }
}

interface PDFState {
  blob: string
  id: string
  isFetching: boolean
}

interface DecisionPolicyResult {
  id: string
  name: string
  result: string
  created_at: string
  updated_at: string
  business_id: string
  policy_enabled: boolean
  matched: boolean
}

interface PolicyResultsState {
  isFetching: boolean
  data?: DecisionPolicyResult[]
}

export type PolicyAction = {
  action_type: string
  id: string
  options: {
    user_id?: string
    order_type?: string
    assignee_id?: string
    subproduct?: string
    decision?: PolicyAutoDecisionType
    monitor_type?: string
  }
  policy_version_id: string
}

export type PolicyActionResult = {
  id: string
  executed: boolean
  policy_action: PolicyAction
}
export type PolicyAutoDecisionType =
  | POLICY_AUTO_DECISION_TYPE.APPROVE
  | POLICY_AUTO_DECISION_TYPE.REJECT

export type Policy = {
  id: string
  author_name: string
  updated_at: string
  rule_set: {
    [key: string]: string[]
  }
  policy_actions: PolicyAction[]
  name: string
  description: string | null
  enabled: boolean
}

interface PolicyState {
  isFetching: boolean
  data?: Policy
  errors?: string[]
}

interface EventState {
  isFetching: boolean
  data?: Event[]
}

interface MonitoringEventState {
  isFetching: boolean
  data?: MonitoringEvent[]
  total: number
  perPage: number
  page: number
}

interface MonitoringMonitorState {
  isFetching: boolean
  data?: MonitoringMonitor[]
  total: number
  perPage: number
  page: number
}

interface PoliciesState {
  isFetching: boolean
  data?: Policy[]
  errors?: string[]
}

interface TaskState {
  data?: {
    business: Business
    tasks: ReviewTasks
  }
}

interface DownloadWatchlistSourcesCSVState {
  isFetching: boolean
  data?: Blob
}

interface DownloadFileExportFileState {
  isFetching: boolean
  data?: Blob
  errors?: string[]
}

interface AffiliateReferralState {
  isFetching: boolean
  data?: {
    referring_partner_name: string
    referring_partner_logo: string
    has_logo: boolean
  }
  error?: string
}

interface AffiliateAccountsState {
  isFetching: boolean
  data: AffiliateAccountType[]
  error?: string
  totalCount: number
  perPage: number
  page: number
}

interface AffiliateUsersState {
  isFetching: boolean
  data: User[]
  error?: string
  totalCount: number
  perPage: number
  page: number
}

interface AffiliateProfilesState {
  isFetching: boolean
  data: AffiliateProfile[]
  error?: string
  totalCount: number
  perPage: number
  page: number
}

export type SignalReason = {
  category: 'identification' | 'verification' | 'evaluation'
  group: string
  code: string
  description: string
}

export type Signal = {
  id?: string
  object?: string
  created_at?: string
  external_id?: string | null
  name?: string
  tin?: string | null
  address?: string | null
  addresses?: string[]
  person?: string | null
  people?: string[]
  score?: number
  identification_score?: number | null
  verification_score?: number | null
  evaluation_score?: number | null
  reasons?: SignalReason[]
  business_id?: string
}

export interface SignalState {
  isCreating: boolean
  isFetching: boolean
  data?: Signal
}

interface SignalsState {
  isFetching: boolean
  data: Signal[]
}

export type Identity = {
  object: string
  id: string
  canonical_name: string
  addresses: Partial<Address[]>
  people: Partial<Person[]>
}

export interface RootState {
  agencies: AgenciesState
  audits: AuditsState
  policies: PoliciesState
  apiKeys: APIKeysState
  business: BusinessState
  businessBatches: BusinessBatchesState
  businesses: BusinessesState
  currentPolicy: PolicyState
  currentUser: User
  events: EventState
  filteredBusinessesCSV: FilteredBusinessesCSVState
  insightKeys: InsightKeysState
  insightKeyStatusMappings: InsightKeyStatusMappingsState
  isFetching: boolean
  kycReasonCodes: KYCReasonCodesState
  modal: ModalState
  monitoringEvents: MonitoringEventState
  monitoringMonitors: MonitoringMonitorState
  observableEvents: ObservableEventState
  partnerInsights: PartnerInsightsState
  pdf: PDFState
  policy: PolicyState
  policyResults: PolicyResultsState
  sandbox: SandboxState
  session: SessionState
  settings: SettingsState
  setupIntent: { stripeClientSecret?: string }
  tasks: TaskState
  referralLink: ReferralLinkState
  users: UserState
  webhooks: WebhookState
  webhook_log: WebhookLogState
  partnerAccountUsersList: PartnerAccountUsersListState
  partnerAccountUserCreation: PartnerAccountUserCreationState
  employerCommsList: EmployerCommsList
  partnerCommsList: PartnerCommsList
  downloadWatchlistSourcesCSV: DownloadWatchlistSourcesCSVState
  partnerAnalytics: PartnerAnalytics
  downloadFileExportFile: DownloadFileExportFileState
  affiliateReferral: AffiliateReferralState
  signal: SignalState
  signals: SignalsState
  affiliateAccounts: AffiliateAccountsState
  affiliateUsers: AffiliateUsersState
  affiliateProfiles: AffiliateProfilesState
}
export interface Pagination {
  page: number
  perPage: number
  total: number
  onPage?: (page: number) => void
}

export interface PaginationParams {
  page?: number
  per_page?: number
}

export interface ActionState {
  state: RootState
  useDispatch: () => Dispatch
}

export interface SigninState {
  token?: string
  email?: string
  password?: string
}

export interface SigninWithGoogleProps {
  onError: (error: string) => void
  onSuccess: (state: SigninState) => void
}

export type Status =
  | 'completed'
  | 'submitted'
  | 'waiting_on_mail'
  | 'blocked_payroll_date'
  | 'action_required'
  | 'active'
  | 'processing'
  | 'not_included'
  | 'unmanaged'

export type CustomError = {
  status: number
  data: {
    errors: [
      {
        message: string
      }
    ]
  }
}

export interface UserProfile {
  confirmation_token: string
  name: string
  email: string
}

export type TaxAgencyRegistration = {
  agency: string
  company: CompanyType
  created_at: string
  blocked_by?: string
  blocked_until?: string
  foreign_qualification?: TarForeignQualification
  id: string
  items?: TaxRegistrationItemType[]
  incomplete_exceptions: {
    id: string
    url: string
  }[]
  state: string
  status: string
  status_updated_at?: string
  submitted_at?: string
  tax_ids_available_at?: string
  tax_ids_copied_at?: string
  tax_types: string[]
  tax_account: {
    id: string
    agency: string
    agency_link: string
    email?: string
    password?: string
    state: string
    username?: string
    pin?: string
    security_questions?: {
      id: string
      question: string
      answer: string
    }
  }
  tax_registration?: {
    questions: {
      id: string
    }[]
  }
  updated_at: string
  employer_managed: boolean
  object: string
}

export type AgencyRegistration = {
  id: string
  status: string
  state: string
  created_at: string
  completed_at: string
  submitted_at: string
  updated_at: string
  jurisdiction: {
    slug: string
    name: string
    tax_type: string
    type?: string
  }
  agency?: {
    state: string
    name: string
    slug: string
  }
  company: {
    id: string
    legal_name: string
    dba_name: string
    external_id: string
    provider_company_id: string
  }
  incomplete_exceptions: {
    id: string
    url: string
  }[]
  agency_account?: {
    id: string
    email: string
    password: string
    username: string
    pin: string
    security_questions?: {
      id: string
      question: string
      answer: string
    }
  }
  items: {
    key: string
    label: string
    value: string
    reminder: string
    updated_at: string
  }[]
  agency_registration_items?: {
    id: string
    key: string
    label: string
    value: string
  }[]
  object: string
}

export type TarForeignQualification = {
  id: string
  status: string
  internal_status: string
  state: string
  completed_at: string
  documents: Document[]
  filing_date: string
  object: string
  registered_agent: string
  contact_email: string
  questions: {
    id: string
  }[]
}

export type ShortCompany = {
  id: string
  legal_name?: string
  dba_name?: string
  external_id?: string
  provider_company_id?: string
}

export type ApplicationInvitation = {
  company: ShortCompany
  id: string
  state: string
  tax_registration_types: string[]
  jurisdictions?: string[]
  jurisdiction_names?: string[]
  pending: boolean
  started: boolean
  status: string
  created_at: string
}

export type Passcode = {
  id: string
  expires_at: string
  url: string
  email_logs: EmailLog[]
}

export type EmailLog = {
  created_at: string
  parameters: {
    host: string
    path: string
    email: string
  }
}

export type CompanyType = {
  id: string
  dba_name: string
  legal_name: string
  account_id: string
  pending_application_invitations_states: string[]
  in_progress_applications_states: string[]
  registration_submitted_states: string[]
  registration_available_states: { [state: string]: string[] } | null
  external_id: string
  application_invitations: ApplicationInvitation[]
  loading: boolean
  status: string
  information_requested_agencies_count: number
  tax_ids_copied_count: number
  started_application_invitations_count: number
  requested_application_invitations_count: number
  tax_ids_available_count: number
  tax_ids_pending_count: number
  provider_company_id?: string
  ein: string
  partner_notes: string
}

export type PartnerCompanies = {
  partnerCompanies?: {
    data: CompanyType[]
    totalCount: number
    hasMore: boolean
    isFetching: boolean
  }
}

export type AffiliateAccountType = {
  id: string
  name: string
  slug: string
  status: string
  information_requested_agencies_count: number
  tax_ids_copied_count: number
  started_application_invitations_count: number
  requested_application_invitations_count: number
  tax_ids_available_count: number
  tax_ids_pending_count: number
}

export type AffiliateProfile = {
  referral_code: string
}

export type ItemChangeType = {
  id: string
  effective_at: string
  from_value: string
  to_value: string
}

export type TaxRegistrationItemType = {
  id: string
  agency: string
  label: string
  value: string
  key: string
  state: string
  status: string
  link: string
  company: CompanyType
  tax_agency_registration: TaxAgencyRegistration
  item_changes: ItemChangeType[]
}

export type PartnerTaxAgencyRegistrations = {
  partnerTaxAgencyRegistrations: {
    data: TaxAgencyRegistration[]
    totalCount: number
    hasMore: boolean
    isFetching: boolean
  }
}

export type IndustryConstant = {
  category: string
  description?: string
}

export type Document = {
  content_type: string
  created_at: string
  document_type?: string
  download_url: string
  filename: string
  filing_date?: string
  id: string
  metadata: unknown
  size: number
  source: {
    id: string
    metadata: {
      state: string
    }
    type: string
  }
}

export const DocumentTypes = {
  utility: 'Utility Bill',
  bank_statement: 'Bank Statement',
  lease: 'Lease',
  ein: 'EIN Document',
  other: 'Other'
}

type EIN = {
  ein: string
}

export const ADDRESS_VERIFICATION_TYPE = 'address'
export const EIN_VERIFICATION_TYPE = 'ein'
export const VERIFICATION_TYPES = [
  ADDRESS_VERIFICATION_TYPE,
  EIN_VERIFICATION_TYPE
]

type BaseDocumentVerification = {
  id: string
  created_at: string
  document: Document
  document_type?: keyof typeof DocumentTypes
  verification_type:
    | typeof EIN_VERIFICATION_TYPE
    | typeof ADDRESS_VERIFICATION_TYPE
  document_id: string
  status: string
  verified: boolean
}

type BusinessName = { business_name: string }

type DocumentDate = { document_date: string }

export type AddressDocumentVerification = BaseDocumentVerification & {
  verification_fields: Address
  extracted_fields?: BusinessName & Address & DocumentDate
}

export type EINDocumentVerification = BaseDocumentVerification & {
  verification_fields: EIN
  extracted_fields?: BusinessName & EIN
}

export type DocumentVerification =
  | AddressDocumentVerification
  | EINDocumentVerification

export const isAddressVerification = (
  verification: DocumentVerification
): verification is AddressDocumentVerification => {
  return verification.verification_type === 'address'
}

export const isEINVerification = (
  verification: DocumentVerification
): verification is EINDocumentVerification => {
  return verification.verification_type === 'ein'
}

export type MailItem = {
  agency_name: string
  arrived_at: string
  company: {
    id: string
    legal_name: string
    dba_name?: string
    external_id?: string
    provider_company_id?: string
    parent_account_name?: string
  }
  created_at: string
  document: Document
  id: string
  internal_status: string
  mail_viewed: boolean
  recipient_name: string
  source: {
    id: string
    type: string
  }
  state: string
  tag_names: string[]
  updated_at: string
  object: string
}

export type Email = {
  id: string
  created_at: string
  agency_name: string
  sender_name: string
  arrived_at: string
  state: string
  subject: string
  object: string
  company: {
    id: string
    legal_name: string
    dba_name?: string
    external_id?: string
    provider_company_id?: string
    parent_account_name?: string
  }
}

export type Event = {
  account_id: string
  author: string
  completed_at: string | null
  created_at: string
  state: string
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    object: any
    previous_attributes?: {
      status: string
    }
  }
  id: string
  note: string | null
  object: string
  metadata: {
    previous_status: string
    current_status: string
  }
  reason: string
  title: string
  type: string
  type_of?: string | undefined // This is coerced in event log from actions
  updated_at: string
  rule_set: {
    [key: string]: string[]
  }
  policy_versions_matched: Policy[]
  policy_action_results: PolicyActionResult[]
  policy_name?: string
}

type MonitoringEvent = Event & {
  business_name: string
  business_id: string
}

export type MonitorType = {
  type: string
  status: string
  enabled_at: string
}

export type MonitoringMonitor = {
  id: string
  business_name: string
  business_id: string
  monitor_types: MonitorType[]
}

export type Kyc = {
  name?: string // Used for merging KYC + Associated People objects
  first_name: string
  last_name: string
  dob: string
  ssn: string
  full_address: {
    address_line1: string
    address_line2?: string
    city: string
    state: string
    postal_code: string
  }
  phone_number: string
  email: string
}

export type SSOConnection = {
  id: string
  name: string
  connection_type: string
  state: string
  status: string
}

export type CompanyPasscode = {
  companyPasscode: Passcode
}

export type CompanyInsight = {
  key: string
  label: string
  status?: string
  value: number
}

export type Package = `${PackageEnum}`

export enum PackageEnum {
  AdverseMedia = 'adverse_media',
  Bankruptcies = 'bankruptcies',
  BusinessVerificationQualify = 'business_verification_qualify',
  BusinessVerificationVerify = 'business_verification_verify',
  Documents = 'documents',
  EnhancedScreenings = 'enhanced_screenings',
  Identity = 'identity',
  Kyc = 'kyc',
  KycDocV = 'kyc_doc_v',
  Liens = 'liens',
  Litigations = 'litigations',
  PeopleBankruptcies = 'people_bankruptcies',
  TaxLiens = 'tax_liens',
  Tin = 'tin',
  UccLiens = 'ucc_liens',
  Website = 'website'
}

export enum APITags {
  BUSINESS_EXPORT = 'business_export'
}

export type SignalsBatch = {
  object: string
  id: string
  name: string
  processed_at: string
  signals_count: number
  created_at: string
  updated_at: string
  filename: string
}
