import React from 'react'

import { theme } from '@middesk/components'
import styled from 'styled-components'

const year = new Date().getFullYear()

const Footer = styled(({ className }) => {
  return <footer {...{ className }}>© {year} Middesk, Inc.</footer>
})`
  color: ${theme.colors.karl};
  font-size: ${theme.typography.sizes.medium};
  padding: var(--spacing) 98px;
`

export default Footer
