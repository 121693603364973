import get from 'lodash/get'
import qs from 'qs'
import { authenticate, getSessionData, updateSession } from 'redux-simple-auth'

import { SANDBOX_DENYLIST } from 'constants/constants'
import { Env } from 'env'
import { router } from 'index'
import { api as RTKAPI } from 'lib/api'
import splitTasks from 'lib/tasks'
import { APITags } from 'types'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE'

export const FETCH_FORM_REQUEST = 'FETCH_FORM_REQUEST'
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS'
export const FETCH_FORM_FAILURE = 'FETCH_FORM_FAILURE'
export const SUBMIT_FORM_REQUEST = 'SUBMIT_FORM_REQUEST'
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS'
export const SUBMIT_FORM_FAILURE = 'SUBMIT_FORM_FAILURE'

export const ENTER_FORM_STAGE = 'ENTER_FORM_STAGE'

export const CREATE_BUSINESS_REQUEST = 'CREATE_BUSINESS_REQUEST'
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS'
export const CREATE_BUSINESS_FAILURE = 'CREATE_BUSINESS_FAILURE'

export const FETCH_WEBSITE_REQUEST = 'FETCH_WEBSITE_REQUEST'
export const FETCH_WEBSITE_SUCCESS = 'FETCH_WEBSITE_SUCCESS'
export const FETCH_WEBSITE_FAILURE = 'FETCH_WEBSITE_FAILURE'
export const FETCH_BUSINESS_REQ_REQUEST = 'FETCH_BUSINESS_REQ_REQUEST'
export const FETCH_BUSINESS_REQ_SUCCESS = 'FETCH_BUSINESS_REQ_SUCCESS'
export const FETCH_BUSINESS_REQ_FAILURE = 'FETCH_BUSINESS_REQ_FAILURE'
export const FETCH_BUSINESSES_REQUEST = 'FETCH_BUSINESSES_REQUEST'
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS'
export const FETCH_BUSINESSES_FAILURE = 'FETCH_BUSINESSES_FAILURE'
export const CREATE_BUSINESS_SEARCH_REQUEST = 'CREATE_BUSINESS_SEARCH_REQUEST'
export const CREATE_BUSINESS_SEARCH_SUCCESS = 'CREATE_BUSINESS_SEARCH_SUCCESS'
export const CREATE_BUSINESS_SEARCH_FAILURE = 'CREATE_BUSINESS_SEARCH_FAILURE'
export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST'
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE'
export const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST'
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS'
export const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE'
export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST'
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS'
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE'
export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST'
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS'
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE'
export const FETCH_REGISTRATION_DOCUMENTS_REQUEST =
  'FETCH_REGISTRATION_DOCUMENTS_REQUEST'
export const FETCH_REGISTRATION_DOCUMENTS_SUCCESS =
  'FETCH_REGISTRATION_DOCUMENTS_SUCCESS'
export const FETCH_REGISTRATION_DOCUMENTS_FAILURE =
  'FETCH_REGISTRATION_DOCUMENTS_FAILURE'
export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST'
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS'
export const CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE'
export const FETCH_RESULTS_REQUEST = 'FETCH_RESULTS_REQUEST'
export const FETCH_RESULTS_SUCCESS = 'FETCH_RESULTS_SUCCESS'
export const FETCH_RESULTS_FAILURE = 'FETCH_RESULTS_FAILURE'

export const CREATE_INQUIRY_REQUEST = 'CREATE_INQUIRY_REQUEST'
export const CREATE_INQUIRY_SUCCESS = 'CREATE_INQUIRY_SUCCESS'
export const CREATE_INQUIRY_FAILURE = 'CREATE_INQUIRY_FAILURE'

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE'

export const UPDATE_BUSINESS = 'UPDATE_BUSINESS'
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS'
export const UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE'

export const UPDATE_ORDERS = 'UPDATE_ORDERS'
export const UPDATE_ORDERS_SUCCESS = 'UPDATE_ORDERS_SUCCESS'
export const UPDATE_ORDERS_FAILURE = 'UPDATE_ORDERS_FAILURE'

export const ADD_RECORD = 'ADD_RECORD'
export const REMOVE_RECORD = 'REMOVE_RECORD'

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST'
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS'
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE'

export const CREATE_RECORD_REQUEST = 'CREATE_RECORD_REQUEST'
export const CREATE_RECORD_SUCCESS = 'CREATE_RECORD_SUCCESS'
export const CREATE_RECORD_FAILURE = 'CREATE_RECORD_FAILURE'
export const UPDATE_RECORD_REQUEST = 'UPDATE_RECORD_REQUEST'
export const UPDATE_RECORD_SUCCESS = 'UPDATE_RECORD_SUCCESS'
export const UPDATE_RECORD_FAILURE = 'UPDATE_RECORD_FAILURE'
export const UPDATE_BUSINESS_RECORD = 'UPDATE_BUSINESS_RECORD'

export const FILE_LIEN_SUCCESS = 'FILE_LIEN_SUCCESS'
export const FILE_LIEN_FAILURE = 'FILE_LIEN_FAILURE'

export const RESET = 'RESET'

export const FETCH_TASKS = 'FETCH_TASKS'

export const AUTOCOMPLETE_FAILURE = 'AUTOCOMPLETE_FAILURE'

export const reset = (...stores) => {
  return dispatch => stores.forEach(store => dispatch({ type: 'RESET', store }))
}

class APIError extends Error {
  constructor(res) {
    super('API error')

    this.status = res.status
  }

  setJSON(value) {
    this.json = value
    this.errors = value.errors
    this.messages = this.errors.map(err => err.message)
  }
}

class API {
  BLOB_CONTENT_TYPES = ['application/pdf', 'application/zip', 'text/csv']

  constructor(dispatch, state) {
    this.dispatch = dispatch
    this.token = get(state, 'session.data.access_token')
    this.sandboxMode = !!get(state, 'sandbox.sandboxMode')
  }

  headers(path) {
    const headers = {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    }

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`

      if (this.shouldIncludeSandboxHeader(path)) {
        headers['X-Middesk-Sandbox-Mode'] = this.sandboxMode
      }
    }

    return new Headers(headers)
  }

  shouldIncludeSandboxHeader(path) {
    if (SANDBOX_DENYLIST.some(pathName => path.startsWith(pathName))) {
      return false
    }

    return this.sandboxMode
  }

  get(path, params) {
    const query = params ? `?${qs.stringify(params)}` : ''

    return this.request(`${path}${query}`)
  }

  post(path, body) {
    return this.request(path, { method: 'POST', body })
  }

  put(path, body) {
    return this.request(path, { method: 'PUT', body })
  }

  patch(path, body) {
    return this.request(path, { method: 'PATCH', body })
  }

  delete(path, body) {
    return this.request(path, { method: 'DELETE', body })
  }

  request(path, { method, body } = {}) {
    const options = {
      method: method || 'GET',
      headers: this.headers(path)
    }

    if (body) {
      if (body instanceof FormData) {
        options.body = body
        options.headers.delete('Content-Type')
      } else {
        options.body = JSON.stringify(body)
      }
    }

    return fetch(`${this.constructor.URL}${path}`, options).then(res => {
      return res.ok ? this.onSuccess(res) : this.onError(res)
    })
  }

  onSuccess(res) {
    if (res.status === 204) {
      return null
    }

    let contentType

    for (const header of res.headers.entries()) {
      if (header[0] === 'content-type') {
        contentType = header[1]
        break
      }
    }

    return this.BLOB_CONTENT_TYPES.includes(contentType)
      ? res.blob()
      : res.json()
  }

  onError(res) {
    if (!res.status || res.status === 401) {
      router.navigate('/signout')

      return res
    }

    const error = new APIError(res)

    return res
      .json()
      .then(json => {
        error.setJSON(json)

        return Promise.reject(error)
      })
      .catch(() => Promise.reject(error))
  }

  static get URL() {
    return Env.REACT_APP_API_HOST
  }
}

export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'

export const confirmEmail = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api.post('/users/confirm', values).then(json => {
      if (!json) {
        return Promise.reject(new APIError(json))
      }

      dispatch({ type: CONFIRM_EMAIL_SUCCESS, payload: json })

      return Promise.resolve(json)
    })
  }
}

export const setPassword = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())
    const { email, password } = values

    return api
      .post('/password', values)
      .then(json => {
        if (!json) {
          return Promise.reject(new APIError(json))
        }

        return dispatch(authenticate('credentials', { email, password }))
      })
      .catch(() => {
        return dispatch({ type: SET_PASSWORD_FAILURE })
      })
  }
}

export const search = value => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api
      .get(`/autocomplete/search?q=${encodeURIComponent(value)}`)
      .catch(() => {
        return dispatch({ type: AUTOCOMPLETE_FAILURE })
      })
  }
}

export const createReport = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_REPORT_REQUEST })

    return api
      .post('/v1/reports', values)
      .then(data => {
        const state = getState()
        const business = state.business && state.business.data

        if (business && business.id === values.business_id) {
          dispatch(fetchBusiness(business.id))
        }

        return dispatch({ type: CREATE_REPORT_SUCCESS, data })
      })
      .catch(({ data }) => {
        return dispatch({ type: CREATE_REPORT_FAILURE, data })
      })
  }
}

export const createInquiry = ({ business_id, ...values }) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_INQUIRY_REQUEST })

    return api
      .post(`/v1/businesses/${business_id}/inquiries`, values)
      .then(data => {
        return dispatch({ type: CREATE_INQUIRY_SUCCESS, data })
      })
      .catch(({ data }) => {
        return dispatch({ type: CREATE_INQUIRY_FAILURE, data })
      })
  }
}

export const createBusiness = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_BUSINESS_REQUEST })

    return api
      .post('/v1/businesses', values)
      .then(data => {
        return dispatch({ type: CREATE_BUSINESS_SUCCESS, data })
      })
      .catch(data => {
        return dispatch({ type: CREATE_BUSINESS_FAILURE, data })
      })
  }
}

export const createDocument = ({ business_id, ...values }) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())
    const data = new FormData()

    dispatch({ type: CREATE_DOCUMENT_REQUEST })

    for (const value in values) {
      data.append(`file[${value}]`, values[value])
    }

    const request = api.post(`/v1/businesses/${business_id}/documents`, data)

    return request
      .then(data => {
        return dispatch({ type: CREATE_DOCUMENT_SUCCESS, data })
      })
      .catch(({ data }) => {
        return dispatch({ type: CREATE_DOCUMENT_FAILURE, data })
      })
  }
}

export const createContact = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_CONTACT_REQUEST })

    return api
      .post(
        '/v1/contacts',
        Object.assign(
          {},
          {
            first_name: values.first_name,
            last_name: values.last_name,
            business_ids: values.business_id ? [values.business_id] : [],
            email_addresses: [
              {
                value: values.email_address,
                primary: true
              }
            ]
          }
        )
      )
      .then(data => {
        const state = getState()
        const business = state.business && state.business.data

        if (business && business.id) {
          data.businesses.forEach(item => {
            if (item.id === business.id) {
              dispatch(fetchBusiness(business.id))
            }
          })
        }

        return dispatch({ type: CREATE_CONTACT_SUCCESS, data })
      })
      .catch(({ data }) => {
        return dispatch({ type: CREATE_CONTACT_FAILURE, data })
      })
  }
}

export const fetchResults = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_RESULTS_REQUEST, id })

    return api
      .get(`/v1/businesses/${id}/search_results`)
      .then(({ data }) => {
        dispatch({ type: FETCH_RESULTS_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_RESULTS_FAILURE, data })
      })
  }
}

export const FETCH_BUSINESS_REQUEST = 'FETCH_BUSINESS_REQUEST'
export const FETCH_BUSINESS_SUCCESS = 'FETCH_BUSINESS_SUCCESS'
export const FETCH_BUSINESS_FAILURE = 'FETCH_BUSINESS_FAILURE'
export const REFRESH_BUSINESS_REQUEST = 'REFRESH_BUSINESS_REQUEST'
export const REFRESH_BUSINESS_SUCCESS = 'REFRESH_BUSINESS_SUCCESS'
export const REFRESH_BUSINESS_FAILURE = 'REFRESH_BUSINESS_FAILURE'

export const fetchBusiness = (id, background = false, orderId) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())
    const [request, success, failure] = background
      ? [
          REFRESH_BUSINESS_REQUEST,
          REFRESH_BUSINESS_SUCCESS,
          REFRESH_BUSINESS_FAILURE
        ]
      : [FETCH_BUSINESS_REQUEST, FETCH_BUSINESS_SUCCESS, FETCH_BUSINESS_FAILURE]

    dispatch({ type: request, id })

    const queryParams = orderId
      ? `order_id=${orderId}`
      : 'include=review,documents,events,subscription_availability,settings'

    return api
      .get(`/v1/businesses/${id}?${queryParams}`)
      .then(data => {
        dispatch({
          type: FETCH_TASKS,
          data: {
            tasks: splitTasks(
              data.review ? data.review.tasks : null,
              data.orders
            ),
            business: { id: data.id, status: data.status }
          }
        })

        return dispatch({ type: success, data, isSnapshot: Boolean(orderId) })
      })
      .catch(({ data, errors, status }) => {
        return dispatch({ type: failure, data, errors, status })
      })
  }
}

export const FETCH_BUSINESS_CLEAR = 'FETCH_BUSINESS_CLEAR'

export const clearBusiness = () => {
  return dispatch => {
    dispatch({ type: FETCH_BUSINESS_CLEAR })
  }
}

export const FETCH_COMPANY_CLEAR = 'FETCH_COMPANY_CLEAR'

export const clearCompany = () => {
  return dispatch => {
    dispatch({ type: FETCH_COMPANY_CLEAR })
  }
}

export const FETCH_API_LOGS_REQUEST = 'FETCH_API_LOGS_REQUEST'
export const FETCH_API_LOGS_SUCCESS = 'FETCH_API_LOGS_SUCCESS'
export const FETCH_API_LOGS_FAILURE = 'FETCH_API_LOGS_FAILURE'

export const fetchAPILogs = (options = {}, connectedAccounts = false) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())
    const params = Object.assign(
      {
        page: 1,
        per_page: 10
      },
      options
    )

    const query = qs.stringify(params, { addQueryPrefix: true })
    const route = connectedAccounts
      ? `/ajax/connected_resources/api_logs${query}`
      : `/v1/api_logs${query}`

    dispatch({ type: FETCH_API_LOGS_REQUEST })

    return api
      .get(route)
      .then(({ data, total_count }) => {
        dispatch({
          type: FETCH_API_LOGS_SUCCESS,
          data,
          total: total_count,
          perPage: parseInt(params.per_page, 10),
          page: parseInt(params.page, 10)
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_API_LOGS_FAILURE, data })
      })
  }
}

export const FETCH_API_LOG_REQUEST = 'FETCH_API_LOG_REQUEST'
export const FETCH_API_LOG_SUCCESS = 'FETCH_API_LOG_SUCCESS'
export const FETCH_API_LOG_FAILURE = 'FETCH_API_LOG_FAILURE'

export const fetchAPILog = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_API_LOG_REQUEST })

    return api
      .get(`/v1/api_logs/${id}`)
      .then(data => {
        dispatch({ type: FETCH_API_LOG_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_API_LOG_FAILURE, data })
      })
  }
}

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST'
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS'
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE'

export const fetchEvent = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_EVENT_REQUEST })

    return api
      .get(`/v1/events/${id}`)
      .then(data =>
        dispatch({
          type: FETCH_EVENT_SUCCESS,
          data
        })
      )
      .catch(({ data }) =>
        dispatch({
          type: FETCH_EVENTS_FAILURE,
          data
        })
      )
  }
}

export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE'

export const fetchEvents = (options = {}, connectedAccounts = false) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())
    const params = Object.assign(
      {
        page: 1,
        per_page: 10
      },
      options
    )

    const query = qs.stringify(params, { addQueryPrefix: true })
    const route = connectedAccounts
      ? `/ajax/connected_resources/events${query}`
      : `/v1/events${query}`

    dispatch({ type: FETCH_EVENTS_REQUEST })

    return api
      .get(route)
      .then(({ data, total_count }) => {
        dispatch({
          type: FETCH_EVENTS_SUCCESS,
          data,
          total: total_count,
          perPage: parseInt(params.per_page, 10),
          page: parseInt(params.page, 10)
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_EVENTS_FAILURE, data })
      })
  }
}

export const FETCH_MONITORING_EVENTS_REQUEST = 'FETCH_MONITORING_EVENTS_REQUEST'
export const FETCH_MONITORING_EVENTS_SUCCESS = 'FETCH_MONITORING_EVENTS_SUCCESS'
export const FETCH_MONITORING_EVENTS_FAILURE = 'FETCH_MONITORING_EVENTS_FAILURE'

export const fetchMonitoringEvents = (options = {}) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    const params = Object.assign(options)

    const query = qs.stringify(params, { addQueryPrefix: true })
    const route = `/ajax/monitoring/events${query}`

    dispatch({ type: FETCH_MONITORING_EVENTS_REQUEST })

    return api
      .get(route)
      .then(({ data, total_count }) => {
        dispatch({
          type: FETCH_MONITORING_EVENTS_SUCCESS,
          data,
          total: total_count,
          perPage: parseInt(params.per_page, 10),
          page: parseInt(params.page, 10)
        })
      })

      .catch(({ data }) => {
        dispatch({ type: FETCH_MONITORING_EVENTS_FAILURE, data })
      })
  }
}

export const FETCH_WEBHOOK_REQUEST = 'FETCH_WEBHOOK_REQUEST'
export const FETCH_WEBHOOK_SUCCESS = 'FETCH_WEBHOOK_SUCCESS'
export const FETCH_WEBHOOK_FAILURE = 'FETCH_WEBHOOK_FAILURE'

export const fetchWebhook = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_WEBHOOK_REQUEST })

    return api
      .get(`/v1/webhooks/${id}`)
      .then(data => {
        dispatch({
          type: FETCH_WEBHOOK_SUCCESS,
          data
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_WEBHOOK_FAILURE, data })
      })
  }
}

export const FETCH_WEBHOOKS_REQUEST = 'FETCH_WEBHOOKS_REQUEST'
export const FETCH_WEBHOOKS_SUCCESS = 'FETCH_WEBHOOKS_SUCCESS'
export const FETCH_WEBHOOKS_FAILURE = 'FETCH_WEBHOOKS_FAILURE'

export const fetchWebhooks = (options = {}) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())
    const params = Object.assign(
      {
        page: 1,
        per_page: 10
      },
      options
    )

    const query = qs.stringify(params, { addQueryPrefix: true })

    dispatch({ type: FETCH_WEBHOOKS_REQUEST })

    return api
      .get(`/v1/webhooks${query}`)
      .then(({ data, total_count }) => {
        dispatch({
          type: FETCH_WEBHOOKS_SUCCESS,
          data,
          total: total_count,
          perPage: parseInt(params.per_page, 10),
          page: parseInt(params.page, 10)
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_WEBHOOKS_FAILURE, data })
      })
  }
}

export const CREATE_WEBHOOK_REQUEST = 'CREATE_WEBHOOK_REQUEST'
export const CREATE_WEBHOOK_SUCCESS = 'CREATE_WEBHOOK_SUCCESS'
export const CREATE_WEBHOOK_FAILURE = 'CREATE_WEBHOOK_FAILURE'

export const createWebhook = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_WEBHOOK_REQUEST })

    return api
      .post('/v1/webhooks', params)
      .then(data => {
        return dispatch({ type: CREATE_WEBHOOK_SUCCESS, data })
      })
      .catch(() => {
        return dispatch({ type: CREATE_WEBHOOK_FAILURE })
      })
  }
}

export const FETCH_WEBHOOK_LOGS_REQUEST = 'FETCH_WEBHOOK_LOGS_REQUEST'
export const FETCH_WEBHOOK_LOGS_SUCCESS = 'FETCH_WEBHOOK_LOGS_SUCCESS'
export const FETCH_WEBHOOK_LOGS_FAILURE = 'FETCH_WEBHOOK_LOGS_FAILURE'

export const fetchWebhookLogs = ({ id, queryString }) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_WEBHOOK_LOGS_REQUEST })

    return api
      .get(`/ajax/webhooks/${id}/logs?${queryString}`)
      .then(data =>
        dispatch({
          type: FETCH_WEBHOOK_LOGS_SUCCESS,
          data
        })
      )
      .catch(({ data }) =>
        dispatch({
          type: FETCH_WEBHOOK_LOGS_FAILURE,
          data
        })
      )
  }
}

export const FETCH_WEBHOOK_LOG_REQUEST = 'FETCH_WEBHOOK_LOG_REQUEST'
export const FETCH_WEBHOOK_LOG_SUCCESS = 'FETCH_WEBHOOK_LOG_SUCCESS'
export const FETCH_WEBHOOK_LOG_FAILURE = 'FETCH_WEBHOOK_LOG_FAILURE'

export const fetchWebhookLog = (webhook_id, log_id) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_WEBHOOK_LOG_REQUEST })

    return api
      .get(`/ajax/webhooks/${webhook_id}/logs/${log_id}`)
      .then(data => {
        dispatch({
          type: FETCH_WEBHOOK_LOG_SUCCESS,
          data
        })
      })
      .catch(({ data }) =>
        dispatch({
          type: FETCH_WEBHOOK_LOG_FAILURE,
          data
        })
      )
  }
}

export const TRIGGER_EVENT_REQUEST = 'TRIGGER_EVENT_REQUEST'
export const TRIGGER_EVENT_SUCCESS = 'TRIGGER_EVENT_SUCCESS'
export const TRIGGER_EVENT_FAILURE = 'TRIGGER_EVENT_FAILURE'

export const triggerEvent = (event_id, webhook_id) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: TRIGGER_EVENT_REQUEST })

    return api
      .post(`/ajax/webhooks/${webhook_id}/trigger_event`, { event_id })
      .then(data => {
        dispatch({
          type: TRIGGER_EVENT_SUCCESS,
          data
        })
      })
      .catch(({ data }) =>
        dispatch({
          type: TRIGGER_EVENT_FAILURE,
          data
        })
      )
  }
}

export const fetchWebsite = (id, includeParams) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())
    const includeParamsURL = includeParams
      ? `?include=${includeParams.join(',')}`
      : ''

    dispatch({ type: FETCH_WEBSITE_REQUEST, id })

    return api
      .get(`/v1/businesses/${id}/website${includeParamsURL}`)
      .then(data => {
        dispatch({ type: FETCH_WEBSITE_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_WEBSITE_FAILURE, data })
      })
  }
}

export const fetchContact = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_CONTACT_REQUEST, id })

    return api
      .get(`/v1/contacts/${id}`)
      .then(data => {
        dispatch({ type: FETCH_CONTACT_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_CONTACT_FAILURE, data })
      })
  }
}

export const updateTask = (id, values) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_TASK_REQUEST })

    return api
      .patch(`/v1/tasks/${id}`, values)
      .then(data => {
        const state = getState()
        const business = state.business && state.business.data

        return Promise.all([
          business ? dispatch(fetchBusiness(business.id)) : null,
          dispatch({ type: UPDATE_TASK_SUCCESS, data })
        ])
      })
      .catch(({ data }) => {
        return dispatch({ type: UPDATE_TASK_FAILURE, data })
      })
  }
}

/* BEGIN TO DEPRECATE */
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE'
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST'

export const fetchCompanies = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    params = {
      page: 1,
      per_page: 30,
      ...params
    }

    const query = qs.stringify(params, { addQueryPrefix: true })

    dispatch({ type: FETCH_COMPANIES_REQUEST })

    return api
      .get(`/ajax/connected_resources/agent/companies${query}`)
      .then(({ data, total_count }) => {
        dispatch({
          type: FETCH_COMPANIES_SUCCESS,
          data,
          total: total_count,
          perPage: parseInt(params.per_page),
          page: parseInt(params.page)
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_COMPANIES_FAILURE, data })
      })
  }
}
/* END TO DEPRECATE */

export const FETCH_PARTNER_INSIGHTS_SUCCESS = 'FETCH_PARTNER_INSIGHTS_SUCCESS'
export const FETCH_PARTNER_INSIGHTS_FAILURE = 'FETCH_PARTNER_INSIGHTS_FAILURE'
export const FETCH_PARTNER_INSIGHTS_REQUEST = 'FETCH_PARTNER_INSIGHTS_REQUEST'

export const fetchPartnerInsights = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_PARTNER_INSIGHTS_REQUEST })

    return api
      .get('/v1/partner/insights', params)
      .then(data => {
        dispatch({
          type: FETCH_PARTNER_INSIGHTS_SUCCESS,
          data
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_PARTNER_INSIGHTS_FAILURE, data })
      })
  }
}

export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE'
export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST'

export const createCompany = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_COMPANY_REQUEST })

    return api
      .post('/ajax/connected_resources/agent/accounts', values)
      .then(data => {
        return dispatch({ type: CREATE_COMPANY_SUCCESS, data })
      })
      .catch(({ errors }) => {
        return dispatch({ type: CREATE_COMPANY_FAILURE, errors })
      })
  }
}

export const UPDATE_AGENT_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_AGENT_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE'
export const UPDATE_AGENT_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST'

export const updateAgentCompany = (id, values) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_AGENT_COMPANY_REQUEST })

    return api
      .put(`/ajax/connected_resources/agent/companies/${id}`, values)
      .then(data => {
        return dispatch({ type: UPDATE_AGENT_COMPANY_SUCCESS, data })
      })
      .catch(({ data }) => {
        return dispatch({ type: UPDATE_AGENT_COMPANY_FAILURE, data })
      })
  }
}

export const BULK_COMPANY_IMPORT_SUCCESS = 'BULK_COMPANY_IMPORT_SUCCESS'
export const BULK_COMPANY_IMPORT_FAILURE = 'BULK_COMPANY_IMPORT_FAILURE'

export const bulkCompanyImport = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api
      .post('/ajax/connected_resources/agent/accounts/bulk_import', params)
      .then(data => {
        return dispatch({ type: BULK_COMPANY_IMPORT_SUCCESS, data })
      })
      .catch(({ json }) => {
        const error_message =
          (json && json.error_message) || 'Failed upload - please refresh page.'

        return dispatch({ type: BULK_COMPANY_IMPORT_FAILURE, error_message })
      })
  }
}

export const FETCH_BULK_ORDER_EXAMPLE_FILE_REQUEST =
  'FETCH_BULK_ORDER_EXAMPLE_FILE_REQUEST'
export const FETCH_BULK_ORDER_EXAMPLE_FILE_SUCCESS =
  'FETCH_BULK_ORDER_EXAMPLE_FILE_SUCCESS'
export const FETCH_BULK_ORDER_EXAMPLE_FILE_FAILURE =
  'FETCH_BULK_ORDER_EXAMPLE_FILE_FAILURE'

export const downloadBulkOrderExampleFile = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_BULK_ORDER_EXAMPLE_FILE_REQUEST })

    return api
      .get('/ajax/connected_resources/agent/accounts/bulk_import_example')
      .then(blob => {
        return dispatch({ type: FETCH_BULK_ORDER_EXAMPLE_FILE_SUCCESS, blob })
      })
      .catch(() => {
        return dispatch({ type: FETCH_BULK_ORDER_EXAMPLE_FILE_FAILURE })
      })
  }
}

export const FETCH_TAX_REGISTRATIONS_SUCCESS = 'FETCH_TAX_REGISTRATIONS_SUCCESS'
export const FETCH_TAX_REGISTRATIONS_FAILURE = 'FETCH_TAX_REGISTRATIONS_FAILURE'
export const FETCH_TAX_REGISTRATIONS_REQUEST = 'FETCH_TAX_REGISTRATIONS_REQUEST'

export const fetchTaxRegistrations = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    params = {
      page: 1,
      per_page: 30,
      ...params
    }

    const query = qs.stringify(params, { addQueryPrefix: true })

    dispatch({ type: FETCH_TAX_REGISTRATIONS_REQUEST })

    return api
      .get(`/ajax/connected_resources/agent/tax_registrations${query}`)
      .then(({ data, total_count }) => {
        dispatch({
          type: FETCH_TAX_REGISTRATIONS_SUCCESS,
          data,
          total: total_count,
          perPage: parseInt(params.per_page, 10),
          page: parseInt(params.page, 10)
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_TAX_REGISTRATIONS_FAILURE, data })
      })
  }
}

export const FETCH_TAX_REGISTRATION_SUCCESS = 'FETCH_TAX_REGISTRATION_SUCCESS'
export const FETCH_TAX_REGISTRATION_FAILURE = 'FETCH_TAX_REGISTRATION_FAILURE'
export const FETCH_TAX_REGISTRATION_REQUEST = 'FETCH_TAX_REGISTRATION_REQUEST'

export const fetchTaxRegistration = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_TAX_REGISTRATION_REQUEST, id })

    return api
      .get(`/v1/agent/tax_registrations/${id}`)
      .then(data => {
        dispatch({ type: FETCH_TAX_REGISTRATION_SUCCESS, data })
      })
      .then(() => {
        const state = getState()
        const companyId = state.taxRegistration.data.company_id

        return dispatch(fetchCompany(companyId))
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_TAX_REGISTRATION_FAILURE, data })
      })
  }
}

export const FETCH_AGENCIES_SUCCESS = 'FETCH_AGENCIES_SUCCESS'
export const FETCH_AGENCIES_FAILURE = 'FETCH_AGENCIES_FAILURE'
export const FETCH_AGENCIES_REQUEST = 'FETCH_AGENCIES_REQUEST'

export const fetchAgencies = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AGENCIES_REQUEST })

    return api
      .get('/ajax/agent/agencies/')
      .then(data => {
        dispatch({ type: FETCH_AGENCIES_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_AGENCIES_FAILURE, data })
      })
  }
}

export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE'

const fetchCompany = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api
      .get(`/v1/agent/companies/${id}`)
      .then(data => {
        dispatch({ type: FETCH_COMPANY_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_COMPANY_FAILURE, data })
      })
  }
}

export const FETCH_AGENT_COMPANY_REQUEST = 'FETCH_AGENT_COMPANY_REQUEST'
export const FETCH_AGENT_COMPANY_SUCCESS = 'FETCH_AGENT_COMPANY_SUCCESS'
export const FETCH_AGENT_COMPANY_FAILURE = 'FETCH_AGENT_COMPANY_FAILURE'

export const fetchAgentCompany = (id, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AGENT_COMPANY_REQUEST })

    return api
      .get(`/ajax/connected_resources/agent/companies/${id}`, params)
      .then(data => {
        dispatch({ type: FETCH_AGENT_COMPANY_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_AGENT_COMPANY_FAILURE, data })
      })
  }
}

export const FETCH_AGENT_TAR_EXPORT_REQUEST = 'FETCH_AGENT_TAR_EXPORT_REQUEST'
export const FETCH_AGENT_TAR_EXPORT_SUCCESS = 'FETCH_AGENT_TAR_EXPORT_SUCCESS'
export const FETCH_AGENT_TAR_EXPORT_FAILURE = 'FETCH_AGENT_TAR_EXPORT_FAILURE'

export const agentTarExport = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AGENT_TAR_EXPORT_REQUEST })

    return api
      .get('/v1/partner/tax_agency_registrations.csv', params)
      .then(data => {
        dispatch({
          type: FETCH_AGENT_TAR_EXPORT_SUCCESS,
          data
        })
      })
      .catch(({ data }) => {
        dispatch({
          type: FETCH_AGENT_TAR_EXPORT_FAILURE,
          data
        })
      })
  }
}

const businessesQueryParams = options => {
  if (options.status === 'pending') {
    options.status = 'pending,in_audit,open'
  }

  const params = {
    ...options
  }

  return params
}

export const FETCH_MONITORING_MONITOR_REQUEST =
  'FETCH_MONITORING_MONITOR_REQUEST'
export const FETCH_MONITORING_MONITOR_SUCCESS =
  'FETCH_MONITORING_MONITOR_SUCCESS'
export const FETCH_MONITORING_MONITOR_FAILURE =
  'FETCH_MONITORING_MONITOR_FAILURE'

export const fetchMonitors = (options = {}) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    const params = Object.assign(options)

    const query = qs.stringify(params, { addQueryPrefix: true })
    const route = `/ajax/monitoring/monitors${query}`

    dispatch({ type: FETCH_MONITORING_MONITOR_REQUEST })

    return api
      .get(route)
      .then(({ data, total_count }) => {
        dispatch({
          type: FETCH_MONITORING_MONITOR_SUCCESS,
          data,
          total: total_count,
          perPage: parseInt(params.per_page, 10),
          page: parseInt(params.page, 10)
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_MONITORING_MONITOR_FAILURE, data })
      })
  }
}

export const fetchBusinesses = (options = {}) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    const params = businessesQueryParams({
      page: 1,
      per_page: options.per_page || 30,
      include: ['review', 'assignee', 'orders'].join(','),
      ...options
    })

    const query = qs.stringify(params, { addQueryPrefix: true })

    dispatch({ type: FETCH_BUSINESSES_REQUEST })

    return api
      .get(`/v1/businesses${query}`)
      .then(({ data, total_count }) => {
        dispatch({
          type: FETCH_BUSINESSES_SUCCESS,
          data: data.map(d => {
            return {
              ...d,
              review: {
                ...(d.review || {}),
                tasks: splitTasks(d.review ? d.review.tasks : null, d.orders)
              }
            }
          }),
          total: total_count,
          perPage: parseInt(params.per_page, 10),
          page: parseInt(params.page, 10)
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_BUSINESSES_FAILURE, data })
      })
  }
}

export const fetchContacts = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_CONTACTS_REQUEST })

    return api
      .get('/v1/contacts')
      .then(({ data }) => {
        dispatch({ type: FETCH_CONTACTS_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_CONTACTS_FAILURE, data })
      })
  }
}

export const fetchDocuments = ({ business_id }) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_DOCUMENTS_REQUEST })

    return api
      .get(`/v1/businesses/${business_id}/documents`)
      .then(({ data }) => {
        dispatch({
          type: FETCH_DOCUMENTS_SUCCESS,
          data: { business_id, data }
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_DOCUMENTS_FAILURE, data })
      })
  }
}

export const fetchRegistrationDocuments = registrationId => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_REGISTRATION_DOCUMENTS_REQUEST })

    return api
      .get(`/v1/registrations/${registrationId}/documents`)
      .then(({ data }) => {
        return dispatch({
          type: FETCH_REGISTRATION_DOCUMENTS_SUCCESS,
          data: data
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_REGISTRATION_DOCUMENTS_FAILURE, data })
      })
  }
}

export const fetchForm = token => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    api.token = token

    dispatch({ type: FETCH_FORM_REQUEST })

    return api
      .get('/x1/form')
      .then(data => {
        dispatch({ type: FETCH_FORM_SUCCESS, data })
      })
      .catch(data => {
        dispatch({ type: FETCH_FORM_FAILURE, data })
      })
  }
}

export const submitForm = token => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    api.token = token

    dispatch({ type: SUBMIT_FORM_REQUEST })

    return api
      .post('/x1/form')
      .then(data => {
        dispatch({ type: SUBMIT_FORM_SUCCESS, data })
      })
      .catch(data => {
        dispatch({ type: SUBMIT_FORM_FAILURE, data })
      })
  }
}

export const enterStage = payload => ({ type: ENTER_FORM_STAGE, payload })

export const openModal = (modal, args = {}) => ({
  type: OPEN_MODAL,
  modal,
  ...args
})
export const closeModal = (modal, args = {}) => ({
  type: CLOSE_MODAL,
  modal,
  ...args
})

export const updateBusiness = (id, data) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_BUSINESS })

    return api
      .patch(`/v1/businesses/${id}`, data)
      .then(data => {
        return dispatch({ type: UPDATE_BUSINESS_SUCCESS, data })
      })
      .catch(data => {
        return dispatch({ type: UPDATE_BUSINESS_FAILURE, data })
      })
  }
}

export const updateOrders = (id, orders) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api
      .post(`/ajax/businesses/${id}/orders/bulk_create`, { orders })
      .then(data => {
        return dispatch({ type: UPDATE_ORDERS_SUCCESS, data })
      })
      .catch(data => {
        return dispatch({ type: UPDATE_ORDERS_FAILURE, data })
      })
  }
}

export const updateBusinessOrders = (id, payload) => {
  return dispatch => {
    const { orders } = payload

    return dispatch(updateBusiness(id, payload))
      .then(() => {
        return dispatch(updateOrders(id, orders))
      })
      .catch(data => {
        return dispatch({ type: UPDATE_ORDERS_FAILURE, data })
      })
  }
}

export const SORT_REGISTRATIONS = 'SORT_REGISTRATIONS'
export const sortRegistrationsBy = (value, order = 'desc') => ({
  type: SORT_REGISTRATIONS,
  value,
  order
})

export const FETCH_API_KEYS_REQUEST = 'FETCH_API_KEYS_REQUEST'
export const FETCH_API_KEYS_SUCCESS = 'FETCH_API_KEYS_SUCCESS'
export const FETCH_API_KEYS_FAILURE = 'FETCH_API_KEYS_FAILURE'

export const fetchAPIKeys = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_API_KEYS_REQUEST })

    return api
      .get('/v1/api_keys')
      .then(data => {
        dispatch({ type: FETCH_API_KEYS_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_API_KEYS_FAILURE, data })
      })
  }
}

export const FETCH_REFERRAL_LINK_REQUEST = 'FETCH_REFERRAL_LINK_REQUEST'
export const FETCH_REFERRAL_LINK_SUCCESS = 'FETCH_REFERRAL_LINK_SUCCESS'
export const FETCH_REFERRAL_LINK_FAILURE = 'FETCH_REFERRAL_LINK_FAILURE'

export const fetchReferralLink = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_REFERRAL_LINK_REQUEST })

    return api
      .get('/v1/partner/partner_referral_links')
      .then(({ data }) => {
        dispatch({ type: FETCH_REFERRAL_LINK_SUCCESS, data: data[0] })
      })
      .catch(error => {
        dispatch({ type: FETCH_REFERRAL_LINK_FAILURE, error })
      })
  }
}

export const CREATE_REFERRAL_LINK_REQUEST = 'CREATE_REFERRAL_LINK_REQUEST'
export const CREATE_REFERRAL_LINK_SUCCESS = 'CREATE_REFERRAL_LINK_SUCCESS'
export const CREATE_REFERRAL_LINK_FAILURE = 'CREATE_REFERRAL_LINK_FAILURE'

export const createReferralLink = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_REFERRAL_LINK_REQUEST })

    return api
      .post('/v1/partner/partner_referral_links')
      .then(data => {
        dispatch({ type: CREATE_REFERRAL_LINK_SUCCESS, data })
      })
      .catch(error => {
        dispatch({ type: CREATE_REFERRAL_LINK_FAILURE, error })
      })
  }
}

export const SETUP_INTENT_REQUEST = 'SETUP_INTENT_REQUEST'
export const SETUP_INTENT_SUCCESS = 'SETUP_INTENT_SUCCESS'
export const SETUP_INTENT_FAILURE = 'SETUP_INTENT_FAILURE'

export const fetchSetupIntent = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: SETUP_INTENT_REQUEST })

    return api
      .get('/ajax/setup_card')
      .then(data => {
        dispatch({ type: SETUP_INTENT_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: SETUP_INTENT_FAILURE, data })
      })
  }
}

export const UPGRADE_ACCOUNT_REQUEST = 'UPGRADE_ACCOUNT_REQUEST'
export const UPGRADE_ACCOUNT_SUCCESS = 'UPGRADE_ACCOUNT_SUCCESS'
export const UPGRADE_ACCOUNT_FAILURE = 'UPGRADE_ACCOUNT_FAILURE'

export const upgradeAccount = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPGRADE_ACCOUNT_REQUEST })
    const {
      currentUser: { account }
    } = getState()

    return api
      .post(`/ajax/accounts/${account.id}/upgrade`, params)
      .then(() => {
        dispatch({ type: UPGRADE_ACCOUNT_SUCCESS })
      })
      .catch(() => {
        dispatch({ type: UPGRADE_ACCOUNT_FAILURE })
      })
  }
}

export const INVITE_USERS_REQUEST = 'INVITE_USERS_REQUEST'
export const INVITE_USERS_SUCCESS = 'INVITE_USERS_SUCCESS'
export const INVITE_USERS_FAILURE = 'INVITE_USERS_FAILURE'
export const INVITE_USERS_CLEAR = 'INVITE_USERS_CLEAR'

export const clearInviteUsers = () => {
  return dispatch => {
    dispatch({ type: INVITE_USERS_CLEAR })
  }
}

export const inviteUsers = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: INVITE_USERS_REQUEST })

    return api
      .post('/users/invite', params)
      .then(({ data }) => {
        return dispatch({ type: INVITE_USERS_SUCCESS, data })
      })
      .catch(({ errors }) => {
        return dispatch({ type: INVITE_USERS_FAILURE, errors })
      })
  }
}

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const fetchUsers = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_USERS_REQUEST })

    return api
      .get('/v1/users', params)
      .then(({ data, total_count: total }) => {
        dispatch({ type: FETCH_USERS_SUCCESS, data, total })

        return { data, total }
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_USERS_FAILURE, data })
      })
  }
}

export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE'

export const removeUser = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: REMOVE_USER_REQUEST })

    return api
      .delete(`/users/${id}`)
      .then(({ data }) => {
        dispatch({ type: REMOVE_USER_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: REMOVE_USER_FAILURE, data })
      })
  }
}

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const updateUser = (userId, values) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_USER_REQUEST })

    return api
      .patch(`/users/${userId}`, values)
      .then(({ data }) => {
        dispatch({ type: UPDATE_USER_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: UPDATE_USER_FAILURE, data })
      })
  }
}
export const FETCH_PARTNER_ACCOUNT_USERS_REQUEST =
  'FETCH_PARTNER_ACCOUNT_USERS_REQUEST'
export const FETCH_PARTNER_ACCOUNT_USERS_SUCCESS =
  'FETCH_PARTNER_ACCOUNT_USERS_SUCCESS'
export const FETCH_PARTNER_ACCOUNT_USERS_FAILURE =
  'FETCH_PARTNER_ACCOUNT_USERS_FAILURE'
export const fetchPartnerAccountUsers = (accountId, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_PARTNER_ACCOUNT_USERS_REQUEST })

    const url = [
      `/v1/partner/accounts/${accountId}/users`,
      new URLSearchParams(params).toString()
    ].join('?')

    return api
      .get(url)
      .then(({ data, has_more, total_count }) => {
        dispatch({
          type: FETCH_PARTNER_ACCOUNT_USERS_SUCCESS,
          data,
          has_more,
          total_count
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_PARTNER_ACCOUNT_USERS_FAILURE, data })
      })
  }
}

export const CREATE_PARTNER_ACCOUNT_USER_REQUEST =
  'CREATE_PARTNER_ACCOUNT_USER_REQUEST'
export const CREATE_PARTNER_ACCOUNT_USER_SUCCESS =
  'CREATE_PARTNER_ACCOUNT_USER_SUCCESS'
export const CREATE_PARTNER_ACCOUNT_USER_FAILURE =
  'CREATE_PARTNER_ACCOUNT_USER_FAILURE'

export const createPartnerAccountUser = (accountId, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_PARTNER_ACCOUNT_USER_REQUEST })

    return api
      .post(`/v1/partner/accounts/${accountId}/users`, params)
      .then(response =>
        dispatch({
          type: CREATE_PARTNER_ACCOUNT_USER_SUCCESS,
          ...response
        })
      )
      .catch(response =>
        dispatch({ type: CREATE_PARTNER_ACCOUNT_USER_FAILURE, ...response })
      )
  }
}

export const REINVITE_PARTNER_ACCOUNT_USER_REQUEST =
  'REINVITE_PARTNER_ACCOUNT_USER_REQUEST'
export const REINVITE_PARTNER_ACCOUNT_USER_SUCCESS =
  'REINVITE_PARTNER_ACCOUNT_USER_SUCCESS'
export const REINVITE_PARTNER_ACCOUNT_USER_FAILURE =
  'REINVITE_PARTNER_ACCOUNT_USER_FAILURE'

export const reinvitePartnerAccountUser = user => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: REINVITE_PARTNER_ACCOUNT_USER_REQUEST, ...user })

    return api
      .post(`/v1/partner/users/${user.id}/reinvite`)
      .then(response =>
        dispatch({
          type: REINVITE_PARTNER_ACCOUNT_USER_SUCCESS,
          ...response,
          ...user
        })
      )
      .catch(response =>
        dispatch({
          type: REINVITE_PARTNER_ACCOUNT_USER_FAILURE,
          ...response,
          ...user
        })
      )
  }
}

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS'
export const UPDATE_CURRENT_USER_FAILURE = 'UPDATE_CURRENT_USER_FAILURE'

export const updateCurrentUser = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_USER_REQUEST })

    return api
      .patch('/me/profile', values)
      .then(({ data }) => {
        dispatch({ type: UPDATE_CURRENT_USER_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: UPDATE_CURRENT_USER_FAILURE, data })
      })
  }
}

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS'
export const CURRENT_USER_FAILURE = 'CURRENT_USER_FAILURE'

export const fetchCurrentUser = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CURRENT_USER_REQUEST })

    return api
      .get('/me')
      .then(user => {
        const session = {
          ...getSessionData(getState()),
          user
        }

        dispatch(updateSession(session))
        dispatch({ type: CURRENT_USER_SUCCESS, data: user })
      })
      .catch(() => {
        dispatch({ type: CURRENT_USER_FAILURE, data: {} })
      })
  }
}

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

export const updateUserSettings = settings => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api.patch('/me/settings', settings).then(() => {
      const session = {
        ...getSessionData(getState())
      }

      session.user.settings = {
        ...session.user.settings,
        ...settings
      }

      dispatch(updateSession(session))
      dispatch({ type: CURRENT_USER_SUCCESS, data: session.user })
      dispatch({ type: UPDATE_SETTINGS, payload: session.user.settings })
    })
  }
}

export const CLASSIFY_REQUEST = 'CLASSIFY_REQUEST'
export const CLASSIFY_SUCCESS = 'CLASSIFY_SUCCESS'
export const CLASSIFY_FAILURE = 'CLASSIFY_FAILURE'

export const classify = ({ website }) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CLASSIFY_REQUEST })

    return api
      .post('/v1/industry/classifications', { website })
      .then(data => {
        dispatch({ type: CLASSIFY_SUCCESS, data })
      })
      .catch(() => {
        dispatch({ type: CLASSIFY_FAILURE })
      })
  }
}

export const FETCH_BUSINESS_REVIEW_REQUEST = 'FETCH_BUSINESS_REVIEW_REQUEST'
export const FETCH_BUSINESS_REVIEW_SUCCESS = 'FETCH_BUSINESS_REVIEW_SUCCESS'
export const FETCH_BUSINESS_REVIEW_FAILURE = 'FETCH_BUSINESS_REVIEW_FAILURE'

export const fetchBusinessReview = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_BUSINESS_REVIEW_REQUEST })

    return api
      .get(`/v1/businesses/${id}/review`)
      .then(data => {
        return dispatch({ data, type: FETCH_BUSINESS_REVIEW_SUCCESS })
      })
      .catch(() => {
        return dispatch({ type: FETCH_BUSINESS_REVIEW_FAILURE })
      })
  }
}

export const UPDATE_BUSINESS_REVIEW_REQUEST = 'UPDATE_BUSINESS_REVIEW_REQUEST'
export const UPDATE_BUSINESS_REVIEW_SUCCESS = 'UPDATE_BUSINESS_REVIEW_SUCCESS'
export const UPDATE_BUSINESS_REVIEW_FAILURE = 'UPDATE_BUSINESS_REVIEW_FAILURE'

export const updateBusinessReview = (id, values) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_BUSINESS_REVIEW_REQUEST })

    return api
      .patch(`/v1/businesses/${id}/review`, values)
      .then(data => {
        const state = getState()
        const business = state.business && state.business.data

        return Promise.all([
          business ? dispatch(fetchBusiness(business.id)) : null,
          dispatch({ type: UPDATE_BUSINESS_REVIEW_SUCCESS, data })
        ])
      })
      .catch(() => {
        return dispatch({ type: UPDATE_BUSINESS_REVIEW_FAILURE })
      })
  }
}

export const CREATE_SUPPORT_REQUEST = 'CREATE_SUPPORT_REQUEST'
export const CREATE_SUPPORT_SUCCESS = 'CREATE_SUPPORT_SUCCESS'
export const CREATE_SUPPORT_FAILURE = 'CREATE_SUPPORT_FAILURE'

export const createSupportRequest = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_SUPPORT_REQUEST })

    return api.post('/support', values).then(() => {
      return dispatch({ type: CREATE_SUPPORT_SUCCESS })
    })
  }
}

export const FETCH_PDF_REQUEST = 'FETCH_PDF_REQUEST'
export const FETCH_PDF_SUCCESS = 'FETCH_PDF_SUCCESS'
export const FETCH_PDF_FAILURE = 'FETCH_PDF_FAILURE'

export const downloadPDF = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_PDF_REQUEST })

    return api
      .get(`/v1/businesses/${id}/pdf`)
      .then(blob => {
        return dispatch({ type: FETCH_PDF_SUCCESS, id, blob })
      })
      .catch(() => {
        return dispatch({ type: FETCH_PDF_FAILURE })
      })
  }
}

export const CONTACT_SALES = 'CONTACT_SALES'
export const CONTACT_SALES_SUCCESS = 'CONTACT_SALES_SUCCESS'
export const CONTACT_SALES_FAILURE = 'CONTACT_SALES_FAILURE'

export const contactSales = values => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CONTACT_SALES })

    return api
      .post('/v1/activities', values)
      .then(() => dispatch({ type: CONTACT_SALES_SUCCESS }))
      .catch(() => dispatch({ type: CONTACT_SALES_FAILURE }))
  }
}

export const FETCH_BATCH_REQUEST = 'FETCH_BATCH_REQUEST'
export const FETCH_BATCH_SUCCESS = 'FETCH_BATCH_SUCCESS'
export const FETCH_BATCH_FAILURE = 'FETCH_BATCH_FAILURE'
export const REFRESH_BUSINESS_BATCH_REQUEST = 'REFRESH_BUSINESS_BATCH_REQUEST'
export const REFRESH_BUSINESS_BATCH_SUCCESS = 'REFRESH_BUSINESS_BATCH_SUCCESS'
export const REFRESH_BUSINESS_BATCH_FAILURE = 'REFRESH_BUSINESS_BATCH_FAILURE'

export const fetchBatches = (params, inBackground = false) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    const [request, success, failure] = inBackground
      ? [
          REFRESH_BUSINESS_BATCH_REQUEST,
          REFRESH_BUSINESS_BATCH_SUCCESS,
          REFRESH_BUSINESS_BATCH_FAILURE
        ]
      : [FETCH_BATCH_REQUEST, FETCH_BATCH_SUCCESS, FETCH_BATCH_FAILURE]

    dispatch({ type: request })

    return api
      .get('/v1/business_batches', params)
      .then(({ data, total_count: total }) => {
        dispatch({ type: success, data, total })

        return { data, total }
      })
      .catch(({ data }) => {
        dispatch({ type: failure, data })
      })
  }
}

export const CREATE_BATCH_REQUEST = 'CREATE_BATCH_REQUEST'
export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS'
export const CREATE_BATCH_FAILURE = 'CREATE_BATCH_FAILURE'

export const createBatches = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_BATCH_REQUEST })

    return api
      .post('/v1/business_batches', params)
      .then(data => {
        return dispatch({ type: CREATE_BATCH_SUCCESS, data })
      })
      .catch(data => {
        return dispatch({ type: CREATE_BATCH_FAILURE, data })
      })
  }
}

export const UPDATE_WATCHLIST_SOURCES_REQUEST =
  'UPDATE_WATCHLIST_SOURCES_REQUEST'
export const UPDATE_WATCHLIST_SOURCES_SUCCESS =
  'UPDATE_WATCHLIST_SOURCES_SUCCESS'
export const UPDATE_WATCHLIST_SOURCES_FAILURE =
  'UPDATE_WATCHLIST_SOURCES_FAILURE'

export const updateWatchlistSources = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_WATCHLIST_SOURCES_REQUEST })

    return api
      .post('/ajax/watchlist_sources', params)
      .then(data => {
        return dispatch({ type: UPDATE_WATCHLIST_SOURCES_SUCCESS, data })
      })
      .catch(({ errors }) => {
        const errorsObj = errors || [
          { message: 'There was an issue processing your request' }
        ]

        return dispatch({
          type: UPDATE_WATCHLIST_SOURCES_FAILURE,
          errors: errorsObj
        })
      })
  }
}

export const FETCH_BATCH_EXAMPLE_FILE_REQUEST =
  'FETCH_BATCH_EXAMPLE_FILE_REQUEST'
export const FETCH_BATCH_EXAMPLE_FILE_SUCCESS =
  'FETCH_BATCH_EXAMPLE_FILE_SUCCESS'
export const FETCH_BATCH_EXAMPLE_FILE_FAILURE =
  'FETCH_BATCH_EXAMPLE_FILE_FAILURE'

export const downloadBatchExampleFile = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_BATCH_EXAMPLE_FILE_REQUEST })

    return api
      .get('/v1/business_batches/example')
      .then(blob => {
        return dispatch({ type: FETCH_BATCH_EXAMPLE_FILE_SUCCESS, blob })
      })
      .catch(() => {
        return dispatch({ type: FETCH_BATCH_EXAMPLE_FILE_FAILURE })
      })
  }
}

export const FETCH_CUSTOMER_PORTAL_SESSION_REQUEST =
  'FETCH_CUSTOMER_PORTAL_SESSION_REQUEST'

export const fetchCustomerPortalSession = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_CUSTOMER_PORTAL_SESSION_REQUEST })

    return api
      .get('/ajax/customer_portal', { return_url: window.location.href })
      .then(data => {
        window.location = data.url
      })
  }
}

export const MONITOR_REQUEST = 'MONITOR_REQUEST'
export const MONITOR_SUCCESS = 'MONITOR_SUCCESS'
export const MONITOR_FAILURE = 'MONITOR_FAILURE'

export const setMonitor = (id, enable, eventTypes = ['*']) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: MONITOR_REQUEST })

    const request = enable
      ? api.post(`/v1/businesses/${id}/monitor`, {
          event_types: eventTypes
        })
      : api.delete(`/v1/businesses/${id}/monitor`)

    return request
      .then(data => {
        return dispatch({
          type: MONITOR_SUCCESS,
          monitor: enable ? data : []
        })
      })
      .catch(({ errors }) => {
        return dispatch({ type: MONITOR_FAILURE, errors })
      })
  }
}

export const updateMonitor = (id, eventTypes) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: MONITOR_REQUEST })

    return api
      .patch(`/v1/businesses/${id}/monitor`, {
        event_types: eventTypes
      })
      .then(data => {
        return dispatch({ type: MONITOR_SUCCESS, monitor: data })
      })
      .catch(({ errors }) => {
        return dispatch({ type: MONITOR_FAILURE, errors })
      })
  }
}

export const FETCH_AUDITS_REQUEST = 'FETCH_AUDITS_REQUEST'
export const FETCH_AUDITS_SUCCESS = 'FETCH_AUDITS_SUCCESS'
export const FETCH_AUDITS_FAILURE = 'FETCH_AUDITS_FAILURE'

export const fetchAudits = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AUDITS_REQUEST })

    const request = api.get(`/ajax/businesses/${id}/audits`)

    return request
      .then(data => {
        return dispatch({
          type: FETCH_AUDITS_SUCCESS,
          data: data.data
        })
      })
      .catch(({ errors }) => {
        return dispatch({ type: FETCH_AUDITS_FAILURE, errors })
      })
  }
}

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE'

export const createOrder = (id, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_ORDER_REQUEST })

    const request = api.post(`/v1/businesses/${id}/orders`, params)

    return request
      .then(order => {
        dispatch(fetchBusiness(id, true))

        return dispatch({
          type: CREATE_ORDER_SUCCESS,
          order
        })
      })
      .catch(({ errors }) => {
        return dispatch({ type: CREATE_ORDER_FAILURE, errors })
      })
  }
}

export const EDIT_BUSINESSES_REQUEST = 'EDIT_BUSINESSES_REQUEST'
export const EDIT_BUSINESSES_SUCCESS = 'EDIT_BUSINESSES_SUCCESS'
export const EDIT_BUSINESSES_FAILURE = 'EDIT_BUSINESSES_FAILURE'

export const editBusinesses = (values, pageParams = {}) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: EDIT_BUSINESSES_REQUEST })

    const request = api.post('/ajax/businesses/update_many', values)

    return request
      .then(() => {
        dispatch(fetchBusinesses(pageParams))

        return dispatch({
          type: EDIT_BUSINESSES_SUCCESS
        })
      })
      .catch(({ errors }) => {
        return dispatch({ type: EDIT_BUSINESSES_FAILURE, errors })
      })
  }
}

export const TOGGLE_SANDBOX_MODE = 'UPDATE_SANDBOX_MODE'

export const toggleSandboxMode = ({ sandboxMode = false }) => {
  return dispatch =>
    dispatch({
      type: TOGGLE_SANDBOX_MODE,
      sandboxMode
    })
}

export const FETCH_WEBHOOK_OBSERVABLE_EVENTS_REQUEST =
  'FETCH_WEBHOOK_OBSERVABLE_EVENTS_REQUEST'
export const FETCH_WEBHOOK_OBSERVABLE_EVENTS_SUCCESS =
  'FETCH_WEBHOOK_OBSERVABLE_EVENTS_SUCCESS'
export const FETCH_WEBHOOK_OBSERVABLE_EVENTS_FAILURE =
  'FETCH_WEBHOOK_OBSERVABLE_EVENTS_FAILURE'

export const fetchWebhookObservableEvents = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_WEBHOOK_OBSERVABLE_EVENTS_REQUEST })

    return api
      .get('/ajax/webhooks/observable_events')
      .then(data =>
        dispatch({
          type: FETCH_WEBHOOK_OBSERVABLE_EVENTS_SUCCESS,
          data
        })
      )
      .catch(({ data }) =>
        dispatch({
          type: FETCH_WEBHOOK_OBSERVABLE_EVENTS_FAILURE,
          data
        })
      )
  }
}

export const FETCH_BATCH_CSV_REQUEST = 'FETCH_BATCH_CSV_REQUEST'
export const FETCH_BATCH_CSV_SUCCESS = 'FETCH_BATCH_CSV_SUCCESS'
export const FETCH_BATCH_CSV_ENQUEUED_SUCCESS =
  'FETCH_BATCH_CSV_ENQUEUED_SUCCESS'
export const FETCH_BATCH_CSV_FAILURE = 'FETCH_BATCH_CSV_FAILURE'

export const downloadBatchCSV = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_BATCH_CSV_REQUEST })

    return api
      .get(`/v1/business_batches/${id}/csv`)
      .then(blob => {
        if (blob?.size) {
          return dispatch({
            type: FETCH_BATCH_CSV_SUCCESS,
            id,
            blob
          })
        }

        return dispatch({
          type: FETCH_BATCH_CSV_ENQUEUED_SUCCESS,
          id,
          enqueued: true
        })
      })
      .catch(({ errors }) => {
        return dispatch({ type: FETCH_BATCH_CSV_FAILURE, errors })
      })
  }
}

export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST'
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS'
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE'

export const fetchSettings = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_SETTINGS_REQUEST })

    return api
      .get('/ajax/settings', params)
      .then(data => {
        return dispatch({
          type: FETCH_SETTINGS_SUCCESS,
          data
        })
      })
      .catch(({ data }) =>
        dispatch({
          type: FETCH_SETTINGS_FAILURE,
          data
        })
      )
  }
}

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE'

export const updateSettings = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_SETTINGS_REQUEST })

    return api
      .patch('/ajax/settings', params)
      .then(data => {
        return dispatch({
          type: UPDATE_SETTINGS_SUCCESS,
          data
        })
      })
      .catch(({ data }) =>
        dispatch({
          type: UPDATE_SETTINGS_FAILURE,
          data
        })
      )
  }
}

export const UPDATE_SECURED_PARTY_DETAILS_REQUEST =
  'UPDATE_SECURED_PARTY_DETAILS_REQUEST'
export const UPDATE_SECURED_PARTY_DETAILS_SUCCESS =
  'UPDATE_SECURED_PARTY_DETAILS_SUCCESS'
export const UPDATE_SECURED_PARTY_DETAILS_FAILURE =
  'UPDATE_SECURED_PARTY_DETAILS_FAILURE'

export const updateSecuredPartyDetails = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_SECURED_PARTY_DETAILS_REQUEST })

    return api
      .put('/ajax/secured_party_details', params.securedPartyDetails)
      .then(data => {
        return dispatch({
          type: UPDATE_SETTINGS_SUCCESS,
          data
        })
      })
      .catch(({ data }) =>
        dispatch({
          type: UPDATE_SETTINGS_FAILURE,
          data
        })
      )
  }
}

export const FETCH_KYC_REASON_CODES_REQUEST = 'FETCH_KYC_REASON_CODES_REQUEST'
export const FETCH_KYC_REASON_CODES_SUCCESS = 'FETCH_KYC_REASON_CODES_SUCCESS'
export const FETCH_KYC_REASON_CODES_FAILURE = 'FETCH_KYC_REASON_CODES_FAILURE'

export const fetchKYCReasonCodes = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_KYC_REASON_CODES_REQUEST })

    return api
      .get('/v1/kyc/reason_codes')
      .then(data => {
        dispatch({ type: FETCH_KYC_REASON_CODES_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_KYC_REASON_CODES_FAILURE, data })
      })
  }
}

export const FETCH_INSIGHT_KEYS_REQUEST = 'FETCH_INSIGHT_KEYS_REQUEST'
export const FETCH_INSIGHT_KEYS_SUCCESS = 'FETCH_INSIGHT_KEYS_SUCCESS'
export const FETCH_INSIGHT_KEYS_FAILURE = 'FETCH_INSIGHT_KEYS_FAILURE'

export const fetchInsightKeys = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_INSIGHT_KEYS_REQUEST })

    return api
      .get('/ajax/insight_keys')
      .then(data => {
        dispatch({ type: FETCH_INSIGHT_KEYS_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_INSIGHT_KEYS_FAILURE, data })
      })
  }
}

export const FETCH_INSIGHT_KEY_STATUSES_REQUEST =
  'FETCH_INSIGHT_KEY_STATUSES_REQUEST'
export const FETCH_INSIGHT_KEY_STATUSES_SUCCESS =
  'FETCH_INSIGHT_KEY_STATUSES_SUCCESS'
export const FETCH_INSIGHT_KEY_STATUSES_FAILURE =
  'FETCH_INSIGHT_KEY_STATUSES_FAILURE'

export const fetchInsightKeyStatuses = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_INSIGHT_KEY_STATUSES_REQUEST })

    return api
      .get('/ajax/insight_key_statuses')
      .then(data => {
        dispatch({ type: FETCH_INSIGHT_KEY_STATUSES_SUCCESS, data })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_INSIGHT_KEY_STATUSES_FAILURE, data })
      })
  }
}

export const CLEAR_FILTERED_BUSINESS_CSV = 'CLEAR_FILTERED_BUSINESS_CSV'
export const FETCH_FILTERED_BUSINESSES_REQUEST =
  'FETCH_FILTERED_BUSINESSES_REQUEST'
export const FETCH_FILTERED_BUSINESSES_SUCCESS =
  'FETCH_FILTERED_BUSINESSES_SUCCESS'
export const FETCH_FILTERED_BUSINESSES_ENQUEUED_SUCCESS =
  'FETCH_FILTERED_BUSINESSES_ENQUEUED_SUCCESS'
export const FETCH_FILTERED_BUSINESSES_FAILURE =
  'FETCH_FILTERED_BUSINESSES_FAILURE'

export const clearFilteredBusinessCSV = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTERED_BUSINESS_CSV })
  }
}

export const downloadBusinessesCSV = options => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    const params = businessesQueryParams(options)

    const query = qs.stringify(params, { addQueryPrefix: true })

    dispatch({ type: FETCH_FILTERED_BUSINESSES_REQUEST })

    return api
      .get(`/v1/businesses.csv${query}`)
      .then(blob => {
        dispatch(RTKAPI.util.invalidateTags([APITags.BUSINESS_EXPORT]))
        if (blob?.size) {
          return dispatch({
            type: FETCH_FILTERED_BUSINESSES_SUCCESS,
            query,
            blob
          })
        }

        return dispatch({
          type: FETCH_FILTERED_BUSINESSES_ENQUEUED_SUCCESS,
          query
        })
      })
      .catch(({ errors }) => {
        return dispatch({ type: FETCH_FILTERED_BUSINESSES_FAILURE, errors })
      })
  }
}

export const fileLien = (business_id, values) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api
      .post(`/v1/businesses/${business_id}/liens`, values)
      .then(() => {
        return dispatch({ type: FILE_LIEN_SUCCESS })
      })
      .catch(() => {
        return dispatch({ type: FILE_LIEN_FAILURE })
      })
  }
}

export const FETCH_POLICY_RESULTS_REQUEST = 'FETCH_POLICY_RESULTS_REQUEST'
export const FETCH_POLICY_RESULTS_SUCCESS = 'FETCH_POLICY_RESULTS_SUCCESS'
export const FETCH_POLICY_RESULTS_FAILURE = 'FETCH_POLICY_RESULTS_FAILURE'

export const fetchPolicyResult = businessId => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_POLICY_RESULTS_REQUEST })

    return api
      .get(`/ajax/businesses/${businessId}/policy_results`)
      .then(data => {
        return dispatch({
          type: FETCH_POLICY_RESULTS_SUCCESS,
          data: data.data
        })
      })
      .catch(({ data }) =>
        dispatch({
          type: FETCH_POLICY_RESULTS_FAILURE,
          data
        })
      )
  }
}

export const FETCH_EMPLOYER_COMMS_REQUEST = 'FETCH_EMPLOYER_COMMS_REQUEST'
export const FETCH_EMPLOYER_COMMS_SUCCESS = 'FETCH_EMPLOYER_COMMS_SUCCESS'
export const FETCH_EMPLOYER_COMMS_FAILURE = 'FETCH_EMPLOYER_COMMS_FAILURE'
export const fetchEmployerCommsList = (companyId, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_EMPLOYER_COMMS_REQUEST })

    const url = [
      `/v1/partner/companies/${companyId}/communications`,
      new URLSearchParams(params).toString()
    ].join('?')

    return api
      .get(url)
      .then(({ data, has_more, total_count }) => {
        dispatch({
          type: FETCH_EMPLOYER_COMMS_SUCCESS,
          data,
          has_more,
          total_count
        })
      })
      .catch(({ data }) => {
        dispatch({ type: FETCH_EMPLOYER_COMMS_FAILURE, data })
      })
  }
}

export const UPDATE_EMPLOYER_MAIL_REQUEST = 'UPDATE_EMPLOYER_MAIL_REQUEST'
export const UPDATE_EMPLOYER_MAIL_SUCCESS = 'UPDATE_EMPLOYER_MAIL_SUCCESS'
export const UPDATE_EMPLOYER_MAIL_FAILURE = 'UPDATE_EMPLOYER_MAIL_FAILURE'
export const updateEmployerMail = (id, body) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_EMPLOYER_MAIL_REQUEST })

    return api
      .patch(`/v1/partner/mail/${id}`, body)
      .then(mail => {
        return dispatch({
          type: UPDATE_EMPLOYER_MAIL_SUCCESS,
          mail
        })
      })
      .catch(response => {
        return dispatch({ type: UPDATE_EMPLOYER_MAIL_FAILURE, ...response })
      })
  }
}

export const FORWARD_EMPLOYER_EMAIL_REQUEST = 'FORWARD_EMPLOYER_EMAIL_REQUEST'
export const FORWARD_EMPLOYER_EMAIL_SUCCESS = 'FORWARD_EMPLOYER_EMAIL_SUCCESS'
export const FORWARD_EMPLOYER_EMAIL_FAILURE = 'FORWARD_EMPLOYER_EMAIL_FAILURE'
export const forwardEmployerEmail = ({ company_id, id }) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FORWARD_EMPLOYER_EMAIL_REQUEST })

    return api
      .post(
        `/v1/partner/companies/${company_id}/inbound_emails/${id}/forward`,
        {}
      )
      .then(() => {
        dispatch({
          type: FORWARD_EMPLOYER_EMAIL_SUCCESS
        })
      })
      .catch(response => {
        dispatch({ type: FORWARD_EMPLOYER_EMAIL_FAILURE, ...response })
      })
  }
}

export const CLEAR_FORWARD_EMPLOYER_EMAIL_REQUEST =
  'CLEAR_FORWARD_EMPLOYER_EMAIL_REQUEST'

export const clearEmployerEmailForward = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FORWARD_EMPLOYER_EMAIL_REQUEST })
  }
}

export const FETCH_CURRENT_POLICY_REQUEST = 'FETCH_CURRENT_POLICY_REQUEST'
export const FETCH_CURRENT_POLICY_SUCCESS = 'FETCH_CURRENT_POLICY_SUCCESS'
export const FETCH_CURRENT_POLICY_FAILURE = 'FETCH_CURRENT_POLICY_FAILURE'

export const fetchCurrentPolicy = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_CURRENT_POLICY_REQUEST })

    return api
      .get('/ajax/policies')
      .then(data => {
        return dispatch({
          type: FETCH_CURRENT_POLICY_SUCCESS,
          data: data.data[0]
        })
      })
      .catch(({ errors }) =>
        dispatch({
          type: FETCH_CURRENT_POLICY_FAILURE,
          errors
        })
      )
  }
}

export const FETCH_POLICIES_REQUEST = 'FETCH_POLICIES_REQUEST'
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS'
export const FETCH_POLICIES_FAILURE = 'FETCH_POLICIES_FAILURE'

export const fetchPolicies = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_POLICIES_REQUEST })

    return api
      .get('/ajax/policies')
      .then(data => {
        return dispatch({
          type: FETCH_POLICIES_SUCCESS,
          data: data.data
        })
      })
      .catch(({ errors }) =>
        dispatch({
          type: FETCH_POLICIES_FAILURE,
          errors
        })
      )
  }
}

export const UPDATE_POLICY_REQUEST = 'UPDATE_POLICY_REQUEST'
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS'
export const UPDATE_POLICY_FAILURE = 'UPDATE_POLICY_FAILURE'

export const updatePolicy = (id, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_POLICY_REQUEST })

    return api
      .patch(`/ajax/policies/${id}`, params)
      .then(data => {
        return dispatch({
          type: UPDATE_POLICY_SUCCESS,
          data: data.data
        })
      })
      .catch(({ errors }) =>
        dispatch({
          type: UPDATE_POLICY_FAILURE,
          errors
        })
      )
  }
}

export const deletePolicy = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_POLICY_REQUEST })

    return api
      .delete(`/ajax/policies/${id}`)
      .then(data => {
        return dispatch({
          type: UPDATE_POLICY_SUCCESS,
          data: data.data
        })
      })
      .catch(({ errors }) =>
        dispatch({
          type: UPDATE_POLICY_FAILURE,
          errors
        })
      )
  }
}

export const FETCH_POLICY_REQUEST = 'FETCH_POLICY_REQUEST'
export const FETCH_POLICY_SUCCESS = 'FETCH_POLICY_SUCCESS'
export const FETCH_POLICY_FAILURE = 'FETCH_POLICY_FAILURE'

export const fetchPolicy = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_POLICY_REQUEST })

    return api
      .get(`/ajax/policies/${id}`)
      .then(data => {
        return dispatch({
          type: FETCH_POLICY_SUCCESS,
          data: data
        })
      })
      .catch(({ errors }) =>
        dispatch({
          type: FETCH_POLICY_FAILURE,
          errors
        })
      )
  }
}

export const CREATE_POLICY_REQUEST = 'CREATE_POLICY_REQUEST'
export const CREATE_POLICY_SUCCESS = 'CREATE_POLICY_SUCCESS'
export const CREATE_POLICY_FAILURE = 'CREATE_POLICY_FAILURE'

export const createPolicy = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: CREATE_POLICY_REQUEST })

    return api
      .post('/ajax/policies', params)
      .then(data => {
        return dispatch({
          type: CREATE_POLICY_SUCCESS,
          data: data.data
        })
      })
      .catch(({ errors }) =>
        dispatch({
          type: CREATE_POLICY_FAILURE,
          errors
        })
      )
  }
}

export const FLAG_INDUSTRY_CLASSIFICATION_REQUEST =
  'FLAG_INDUSTRY_CLASSIFICATION_REQUEST'
export const FLAG_INDUSTRY_CLASSIFICATION_SUCCESS =
  'FLAG_INDUSTRY_CLASSIFICATION_SUCCESS'
export const FLAG_INDUSTRY_CLASSIFICATION_FAILURE =
  'FLAG_INDUSTRY_CLASSIFICATION_FAILURE'

export const flagIndustryClassification = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FLAG_INDUSTRY_CLASSIFICATION_REQUEST })

    return api
      .post('/v1/industry/feedback', params)
      .then(data => {
        return dispatch({
          type: FLAG_INDUSTRY_CLASSIFICATION_SUCCESS,
          data: data.data
        })
      })
      .catch(({ errors }) =>
        dispatch({
          type: FLAG_INDUSTRY_CLASSIFICATION_FAILURE,
          errors
        })
      )
  }
}

export const UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_REQUEST =
  'UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_REQUEST'
export const UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_SUCCESS =
  'UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_SUCCESS'
export const UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_FAILURE =
  'UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_FAILURE'

export const updatePartnerAccountChildCompanyApplicationInvitation = (
  companyId,
  params
) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_REQUEST })

    return api
      .post(
        `/v1/partner/companies/${companyId}/application_invitations`,
        params
      )
      .then(data => {
        return dispatch({
          type: UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_SUCCESS,
          data
        })
      })
      .catch(error => {
        return dispatch({
          type: UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_FAILURE,
          errors: error.message
        })
      })
  }
}

export const SEND_APPLICATION_INVITATION_EMAIL_REQUEST =
  'SEND_APPLICATION_INVITATION_EMAIL_REQUEST'
export const SEND_APPLICATION_INVITATION_EMAIL_REQUEST_SUCCESS =
  'SEND_APPLICATION_INVITATION_REQUEST_EMAIL_SUCCESS'
export const SEND_APPLICATION_INVITATION_EMAIL_REQUEST_FAILURE =
  'SEND_APPLICATION_INVITATION_REQUEST_EMAIL_FAILURE'

export const sendApplicationInvitationEmail = (companyId, email, addUser) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: SEND_APPLICATION_INVITATION_EMAIL_REQUEST })

    return api
      .post(`/v1/partner/companies/${companyId}/passcode/invite`, {
        email,
        add_user: addUser
      })
      .then(data => {
        return dispatch({
          type: SEND_APPLICATION_INVITATION_EMAIL_REQUEST_SUCCESS,
          data
        })
      })
      .catch(({ errors }) => {
        return dispatch({
          type: SEND_APPLICATION_INVITATION_EMAIL_REQUEST_FAILURE,
          errors: errors || []
        })
      })
  }
}

export const FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_REQUEST =
  'FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_REQUEST'
export const FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_SUCCESS =
  'FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_SUCCESS'
export const FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_FAILURE =
  'FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_FAILURE'

export const fetchPartnerAccountChildCompanyApplicationInvitations = (
  companyId,
  params
) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_REQUEST })

    return api
      .get(`/v1/partner/companies/${companyId}/application_invitations`, params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_SUCCESS,
          ...response
        })
      })
      .catch(({ errors }) => {
        return dispatch({
          type: FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_PARTNER_COMPANY_PASSCODE_REQUEST =
  'FETCH_PARTNER_COMPANY_PASSCODE_REQUEST'
export const FETCH_PARTNER_COMPANY_PASSCODE_SUCCESS =
  'FETCH_PARTNER_COMPANY_PASSCODE_SUCCESS'
export const FETCH_PARTNER_COMPANY_PASSCODE_FAILURE =
  'FETCH_PARTNER_COMPANY_PASSCODE_FAILURE'

export const fetchPartnerCompanyPasscode = companyId => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_COMPANY_PASSCODE_REQUEST
    })

    return api
      .get(`/v1/partner/companies/${companyId}/passcode`)
      .then(data => {
        return dispatch({
          type: FETCH_PARTNER_COMPANY_PASSCODE_SUCCESS,
          data
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_COMPANY_PASSCODE_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_PARTNER_EXCEPTIONS_REQUEST =
  'FETCH_PARTNER_EXCEPTIONS_REQUEST'
export const FETCH_PARTNER_EXCEPTIONS_REQUEST_SUCCESS =
  'FETCH_PARTNER_EXCEPTIONS_REQUEST_SUCCESS'
export const FETCH_PARTNER_EXCEPTIONS_REQUEST_FAILURE =
  'FETCH_PARTNER_EXCEPTIONS_REQUEST_FAILURE'

export const fetchPartnerExceptions = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_EXCEPTIONS_REQUEST
    })

    return api
      .get('/v1/partner/exceptions', params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_EXCEPTIONS_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_EXCEPTIONS_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_PARTNER_COMPANIES_REQUEST = 'FETCH_PARTNER_COMPANIES_REQUEST'
export const FETCH_PARTNER_COMPANIES_REQUEST_SUCCESS =
  'FETCH_PARTNER_COMPANIES_REQUEST_SUCCESS'
export const FETCH_PARTNER_COMPANIES_REQUEST_FAILURE =
  'FETCH_PARTNER_COMPANIES_REQUEST_FAILURE'

export const fetchPartnerCompanies = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_COMPANIES_REQUEST
    })

    return api
      .get('/v1/partner/companies', params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_COMPANIES_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_COMPANIES_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_PARTNER_COMMS_REQUEST = 'FETCH_PARTNER_COMMS_REQUEST'
export const FETCH_PARTNER_COMMS_REQUEST_SUCCESS =
  'FETCH_PARTNER_COMMS_REQUEST_SUCCESS'
export const FETCH_PARTNER_COMMS_REQUEST_FAILURE =
  'FETCH_PARTNER_COMMS_REQUEST_FAILURE'

export const fetchPartnerComms = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_COMMS_REQUEST
    })

    return api
      .get('/v1/partner/communications', params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_COMMS_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_COMMS_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const MARK_TAR_COPIED_REQUEST = 'MARK_TAR_COPIED_REQUEST'
export const MARK_TAR_COPIED_SUCCESS = 'MARK_TAR_COPIED_SUCCESS'
export const MARK_TAR_COPIED_FAILURE = 'MARK_TAR_COPIED_FAILURE'

export const markTarCopied = id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: MARK_TAR_COPIED_REQUEST })

    return api
      .post(`/v1/partner/tax_agency_registrations/${id}/mark_copied`)
      .then(data => {
        dispatch({ type: MARK_TAR_COPIED_SUCCESS, data })
      })
      .catch(data => {
        dispatch({ type: MARK_TAR_COPIED_FAILURE, data })
      })
  }
}

export const FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST =
  'FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST'
export const FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS =
  'FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS'
export const FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE =
  'FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE'

export const fetchPartnerTaxAgencyRegistrations = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST
    })

    return api
      .get('/v1/partner/tax_agency_registrations', params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST =
  'FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST'
export const FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS =
  'FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS'
export const FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE =
  'FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE'

export const fetchPartnerChildCompanyTaxAgencyRegistrations = (
  companyId,
  params
) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST
    })

    return api
      .get(
        `/v1/partner/companies/${companyId}/tax_agency_registrations`,
        params
      )
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST =
  'FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST'
export const FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_SUCCESS =
  'FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_SUCCESS'
export const FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_FAILURE =
  'FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_FAILURE'

export const fetchPartnerApplicationInvitations = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST
    })

    return api
      .get('/v1/partner/application_invitations', params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST =
  'FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST'
export const FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_SUCCESS =
  'FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_SUCCESS'
export const FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_FAILURE =
  'FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_FAILURE'

export const fetchPartnerChildCompanyInsights = (companyId, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST
    })

    return api
      .get(`/v1/partner/companies/${companyId}/insights`, params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST =
  'FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST'
export const FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_SUCCESS =
  'FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_SUCCESS'
export const FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_FAILURE =
  'FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_FAILURE'

export const fetchPartnerChildCompanyStateStatuses = (companyId, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST
    })

    return api
      .get(`/v1/partner/companies/${companyId}/state_statuses`, params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const REMOVE_PARTNER_COMPANY_SUCCESS = 'REMOVE_PARTNER_COMPANY_SUCCESS'
export const REMOVE_PARTNER_COMPANY_FAILURE = 'REMOVE_PARTNER_COMPANY_FAILURE'

export const removePartnerCompany = company_id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api
      .delete(`/v1/partner/companies/${company_id}`)
      .then(response => {
        return dispatch({
          type: REMOVE_PARTNER_COMPANY_SUCCESS,
          ...response
        })
      })
      .catch(({ json }) => {
        return dispatch({
          type: REMOVE_PARTNER_COMPANY_FAILURE,
          error: json.error || 'Error deleting employer.'
        })
      })
  }
}

export const skipWebhookApiIntegrationStep = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    const data = {
      skip_webhooks_step: true
    }

    return api.patch('/v1/partner/onboarding_checklist', data)
  }
}

export const FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST =
  'FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST'
export const FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_SUCCESS =
  'FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_SUCCESS'
export const FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_FAILURE =
  'FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_FAILURE'

export const fetchPartnerAgencyRegistrations = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST
    })

    return api
      .get('/v1/partner/agency_registrations', params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_SUCCESS,
          ...response
        })
      })
      .catch(errors => {
        return dispatch({
          type: FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_FAILURE,
          errors: errors.messages
        })
      })
  }
}

export const FETCH_WATCHLIST_SOURCES_CSV_REQUEST =
  'FETCH_WATCHLIST_SOURCES_CSV_REQUEST'
export const FETCH_WATCHLIST_SOURCES_CSV_SUCCESS =
  'FETCH_WATCHLIST_SOURCES_CSV_SUCCESS'
export const FETCH_WATCHLIST_SOURCES_CSV_FAILURE =
  'FETCH_WATCHLIST_SOURCES_CSV_FAILURE'

export const downloadWatchlistSourcesCSV = account_id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_WATCHLIST_SOURCES_CSV_REQUEST })

    return api
      .get(`/ajax/watchlist_sources/${account_id}/csv`)
      .then(blob => {
        return dispatch({ type: FETCH_WATCHLIST_SOURCES_CSV_SUCCESS, blob })
      })
      .catch(() => {
        return dispatch({ type: FETCH_WATCHLIST_SOURCES_CSV_FAILURE })
      })
  }
}

export const FETCH_PARTNER_ANALYTICS_REQUEST = 'FETCH_PARTNER_ANALYTICS_REQUEST'
export const FETCH_PARTNER_ANALYTICS_REQUEST_SUCCESS =
  'FETCH_PARTNER_ANALYTICS_REQUEST_SUCCESS'
export const FETCH_PARTNER_ANALYTICS_REQUEST_FAILURE =
  'FETCH_PARTNER_ANALYTICS_REQUEST_FAILURE'

export const fetchPartnerAnalytics = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({
      type: FETCH_PARTNER_ANALYTICS_REQUEST
    })

    return api
      .get('/v1/partner/analytics', params)
      .then(response => {
        return dispatch({
          type: FETCH_PARTNER_ANALYTICS_REQUEST_SUCCESS,
          response
        })
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PARTNER_ANALYTICS_REQUEST_FAILURE,
          errors: [error.message]
        })
      })
  }
}

export const FETCH_FILE_EXPORT_FILE_REQUEST = 'FETCH_FILE_EXPORT_FILE_REQUEST'
export const FETCH_FILE_EXPORT_FILE_SUCCESS = 'FETCH_FILE_EXPORT_FILE_SUCCESS'
export const FETCH_FILE_EXPORT_FILE_FAILURE = 'FETCH_FILE_EXPORT_FILE_FAILURE'

export const downloadFileExportFile = export_id => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_FILE_EXPORT_FILE_REQUEST })

    return api
      .get(`/ajax/file_exports/${export_id}/file`)
      .then(blob => {
        return dispatch({
          type: FETCH_FILE_EXPORT_FILE_SUCCESS,
          blob
        })
      })
      .catch(error => {
        return dispatch({
          type: FETCH_FILE_EXPORT_FILE_FAILURE,
          errors: [error.message]
        })
      })
  }
}

export const FETCH_AFFILIATE_REFERRAL_REQUEST =
  'FETCH_AFFILIATE_REFERRAL_REQUEST'
export const FETCH_AFFILIATE_REFERRAL_SUCCESS =
  'FETCH_AFFILIATE_REFERRAL_SUCCESS'
export const FETCH_AFFILIATE_REFERRAL_FAILURE =
  'FETCH_AFFILIATE_REFERRAL_FAILURE'

export const fetchAffiliateReferral = code => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AFFILIATE_REFERRAL_REQUEST })

    return api
      .get(`/v1/agent/affiliate_referrals/${code}`)
      .then(data => {
        dispatch({
          type: FETCH_AFFILIATE_REFERRAL_SUCCESS,
          data
        })
      })
      .catch(error =>
        dispatch({
          type: FETCH_AFFILIATE_REFERRAL_FAILURE,
          data: {},
          error: error?.message || 'Something went wrong'
        })
      )
  }
}

export const FETCH_AFFILIATE_ACCOUNTS_REQUEST =
  'FETCH_AFFILIATE_ACCOUNTS_REQUEST'
export const FETCH_AFFILIATE_ACCOUNTS_SUCCESS =
  'FETCH_AFFILIATE_ACCOUNTS_SUCCESS'
export const FETCH_AFFILIATE_ACCOUNTS_FAILURE =
  'FETCH_AFFILIATE_ACCOUNTS_FAILURE'

export const fetchAffiliateAccounts = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AFFILIATE_ACCOUNTS_REQUEST })

    return api
      .get('/v1/affiliate/accounts', params)
      .then(data => {
        dispatch({
          type: FETCH_AFFILIATE_ACCOUNTS_SUCCESS,
          data
        })
      })
      .catch(errors =>
        dispatch({
          type: FETCH_AFFILIATE_ACCOUNTS_FAILURE,
          data: {},
          error: errors?.message || 'Something went wrong'
        })
      )
  }
}

export const FETCH_AFFILIATE_USERS_REQUEST = 'FETCH_AFFILIATE_USERS_REQUEST'
export const FETCH_AFFILIATE_USERS_SUCCESS = 'FETCH_AFFILIATE_USERS_SUCCESS'
export const FETCH_AFFILIATE_USERS_FAILURE = 'FETCH_AFFILIATE_USERS_FAILURE'

export const fetchAffiliateUsers = params => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AFFILIATE_USERS_REQUEST })

    return api
      .get('/v1/affiliate/users', params)
      .then(data => {
        dispatch({
          type: FETCH_AFFILIATE_USERS_SUCCESS,
          data
        })
      })
      .catch(errors =>
        dispatch({
          type: FETCH_AFFILIATE_USERS_FAILURE,
          data: {},
          error: errors?.message || 'Something went wrong'
        })
      )
  }
}

export const FETCH_AFFILIATE_PROFILES_REQUEST =
  'FETCH_AFFILIATE_PROFILES_REQUEST'
export const FETCH_AFFILIATE_PROFILES_SUCCESS =
  'FETCH_AFFILIATE_PROFILES_SUCCESS'
export const FETCH_AFFILIATE_PROFILES_FAILURE =
  'FETCH_AFFILIATE_PROFILES_FAILURE'

export const fetchAffiliateProfiles = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AFFILIATE_PROFILES_REQUEST })

    return api
      .get('/v1/affiliate/profiles')
      .then(data => {
        dispatch({
          type: FETCH_AFFILIATE_PROFILES_SUCCESS,
          data
        })
      })
      .catch(errors =>
        dispatch({
          type: FETCH_AFFILIATE_PROFILES_FAILURE,
          data: {},
          error: errors?.message || 'Something went wrong'
        })
      )
  }
}

export const AFFILIATE_REFERRAL_SIGNUP_SUCCESS =
  'AFFILIATE_REFERRAL_SIGNUP_SUCCESS'
export const AFFILIATE_REFERRAL_SIGNUP_FAILURE =
  'AFFILIATE_REFERRAL_SIGNUP_FAILURE'

export const affiliateReferralSignup = (code, params) => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    return api
      .post(`/v1/agent/affiliate_referrals/${code}/signup`, params)
      .then(data => {
        return dispatch({ type: AFFILIATE_REFERRAL_SIGNUP_SUCCESS, data })
      })
      .catch(({ json }) => {
        const errorMessage =
          (json && json.error_message) ||
          'Something went wrong - please try again.'

        return dispatch({
          type: AFFILIATE_REFERRAL_SIGNUP_FAILURE,
          error: errorMessage
        })
      })
  }
}

export const FETCH_AVAILABLE_JURISDICTIONS_REQUEST =
  'FETCH_AVAILABLE_JURISDICTIONS_REQUEST'
export const FETCH_AVAILABLE_JURISDICTIONS_SUCCESS =
  'FETCH_AVAILABLE_JURISDICTIONS_SUCCESS'
export const FETCH_AVAILABLE_JURISDICTIONS_FAILURE =
  'FETCH_AVAILABLE_JURISDICTIONS_FAILURE'

export const fetchAvailableJurisdictions = state => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_AVAILABLE_JURISDICTIONS_REQUEST })

    return api
      .get(`/v1/agent/jurisdictions/${state}`)
      .then(data => {
        return dispatch({
          type: FETCH_AVAILABLE_JURISDICTIONS_SUCCESS,
          data
        })
      })
      .catch(error =>
        dispatch({
          type: FETCH_AVAILABLE_JURISDICTIONS_FAILURE,
          data: {},
          error: error?.message || 'Something went wrong'
        })
      )
  }
}

export const FETCH_SIGNALS_BATCH_EXAMPLE_REQUEST =
  'FETCH_SIGNALS_BATCH_EXAMPLE_REQUEST'
export const FETCH_SIGNALS_BATCH_EXAMPLE_SUCCESS =
  'FETCH_SIGNALS_BATCH_EXAMPLE_SUCCESS'
export const FETCH_SIGNALS_BATCH_EXAMPLE_FAILURE =
  'FETCH_SIGNALS_BATCH_EXAMPLE_FAILURE'

export const downloadSignalsBatchExample = () => {
  return (dispatch, getState) => {
    const api = new API(dispatch, getState())

    dispatch({ type: FETCH_SIGNALS_BATCH_EXAMPLE_REQUEST })

    return api
      .get('/v1/signals/batches/example')
      .then(blob => {
        return dispatch({ type: FETCH_SIGNALS_BATCH_EXAMPLE_SUCCESS, blob })
      })
      .catch(() => {
        return dispatch({ type: FETCH_SIGNALS_BATCH_EXAMPLE_FAILURE })
      })
  }
}
