import React from 'react'

import { Icon, theme } from '@middesk/components'
import * as Menu from '@radix-ui/react-dropdown-menu'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { useSandboxMode } from 'containers/SandboxProvider'

import { SideNavIconOption } from '.'

const { colors, spacing, typography } = theme

const Table = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.frost};
  border-radius: ${spacing.xxsmall};
  border-spacing: 0px;
  margin-left: 8px;

  > div > div.subHeading {
    &:not(:first-of-type) {
      div {
        border-top: 1px solid ${colors.frost};
      }
    }
  }
`

const TableHeaderRowCell = styled.div`
  align-items: start;
  border-bottom: 1px solid ${colors.frost};
  color: ${colors.karl};
  display: flex;
  font-size: ${typography.sizes.small};
  font-weight: 600;
  padding: 8px 12px 4px 12px;
`

const TableRowCell = styled.div<{ selected: boolean }>`
  color: ${colors.graphite};
  display: flex;
  font-size: ${typography.sizes.medium};
  font-weight: ${({ selected }) =>
    selected ? `${typography.weights.bold}` : `${typography.weights.normal}`};
  padding: ${spacing.xsmall} ${spacing.small};

  &:hover {
    background-color: ${colors.dawn};
    cursor: pointer;
    outline: none;
  }
`

const TableSubheaderRowCell = styled.div`
  border-bottom: 1px solid ${colors.frost};
  color: ${colors.karl};
  font-size: ${typography.sizes.small};
  padding: ${spacing.xsmall} ${spacing.small};

  &:hover {
    background-color: transparent;
    cursor: default;
  }
`

const NavBarIcon = styled.div<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? `${colors.dawn}` : '')};
  border-radius: 4px;
  cursor: pointer;
  height: 36px;
  padding: 8px 8px;

  &:hover {
    background-color: ${colors.dawn};
  }

  &:focus:not(:focus-visible):not(:hover) {
    background-color: transparent;
  }
`

const TriggerContainer = styled.div`
  :focus-visible {
    border-radius: 4px;
    outline: 2px solid ${colors.silasDark3};
  }
`

const StyledTrigger = styled(Menu.Trigger)`
  :focus-visible {
    outline: none !important;
  }
`

const StyledItem = styled(Menu.Item)`
  :hover {
    outline: none;
  }

  :focus-visible:not(:hover) {
    border-radius: 1px;
    outline: 2px solid ${colors.silasDark3};
  }
`

type SideNavIconTooltipProps = {
  label: string
  options: SideNavIconOption[]
  selectedMenuItem?: string
}

const SideNavTooltip = ({
  label,
  options,
  selectedMenuItem
}: SideNavIconTooltipProps) => {
  const navigate = useNavigate()
  const { sandboxMode, toggleSandboxMode } = useSandboxMode()

  return (
    <Table>
      <div>
        <div>
          <TableHeaderRowCell>{label}</TableHeaderRowCell>
        </div>
      </div>
      <div>
        {options.map((option, i) => {
          let isCorrectEnvironment = false

          if (option.sandboxMode !== undefined) {
            if (sandboxMode === undefined && option.sandboxMode === false) {
              isCorrectEnvironment = true
            } else if (sandboxMode === option.sandboxMode) {
              isCorrectEnvironment = true
            }
          }

          return (
            <StyledItem
              className={
                option.subHeading || option.externalLink ? 'subHeading' : ''
              }
              data-cy={option.label}
              key={i}
              onClick={() => {
                if (option.sandboxMode !== undefined) {
                  toggleSandboxMode(option.sandboxMode)
                }
                if (option.linkAddress) {
                  if (option.externalLink) {
                    window.open(option.linkAddress)
                  } else {
                    navigate(option.linkAddress)
                  }
                }
              }}
            >
              {option.subHeading ? (
                <TableSubheaderRowCell>{option.label}</TableSubheaderRowCell>
              ) : (
                <TableRowCell
                  selected={
                    selectedMenuItem === option.label || isCorrectEnvironment
                  }
                >
                  {option.label}
                  {isCorrectEnvironment ? (
                    <Icon
                      style={{ marginLeft: 'auto', color: `${colors.green}` }}
                      name='check'
                      size={18}
                    />
                  ) : null}
                  {option.externalLink ? (
                    <Icon
                      style={{ marginLeft: 'auto' }}
                      name='externalLink'
                      size={18}
                    />
                  ) : null}
                </TableRowCell>
              )}
            </StyledItem>
          )
        })}
      </div>
    </Table>
  )
}

type SideNavIconProps = {
  label: string
  icon: JSX.Element
  options: SideNavIconOption[]
  selected: boolean
  selectedMenuItem?: string
  linkAddress?: string
  tabIndex: number
}

const SideNavIconContainer = ({
  label,
  icon,
  options,
  selected,
  selectedMenuItem,
  linkAddress,
  tabIndex
}: SideNavIconProps) => {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const linkOptions = options.filter(
    option => option.linkAddress && !option.externalLink
  )
  let link: string | undefined = undefined

  if (linkAddress || linkOptions.length > 0) {
    link = options.length > 0 ? linkOptions[0].linkAddress : linkAddress
  }

  return (
    <Menu.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <TriggerContainer
        tabIndex={tabIndex}
        data-cy={`${label}-container`}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setIsMenuOpen(false)
            if (link) {
              navigate(link)
            }
          }
          if (e.key === 'ArrowRight' || e.key === ' ') {
            setIsMenuOpen(true)
          }
        }}
      >
        <StyledTrigger
          tabIndex={-1}
          style={{ pointerEvents: 'auto' }}
          onPointerEnter={() => setIsMenuOpen(true)}
          onPointerLeave={() => setIsMenuOpen(false)}
        >
          <NavBarIcon
            selected={selected}
            aria-label={label}
            onClick={e => {
              // prettier-ignore
              (e.currentTarget.parentElement as HTMLElement).blur()
              if (link) {
                navigate(link)
              }
            }}
          >
            {icon}
          </NavBarIcon>
        </StyledTrigger>
      </TriggerContainer>
      {options.length > 0 && (
        <Menu.Content
          onPointerEnter={() => setIsMenuOpen(true)}
          onPointerLeave={() => setIsMenuOpen(false)}
          side='right'
          sideOffset={-20}
          onKeyDown={e => {
            // Exits the menu if the tab key is hit since it will automatically
            // open if the enter key is pressed on the icon itself
            if (e.key === 'Tab') {
              setIsMenuOpen(false)
            }
          }}
        >
          <SideNavTooltip
            selectedMenuItem={
              selected && selectedMenuItem ? selectedMenuItem : undefined
            }
            label={label}
            options={options}
          />
        </Menu.Content>
      )}
    </Menu.Root>
  )
}

export default SideNavIconContainer
