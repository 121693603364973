import React, { useEffect, useState } from 'react'

import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'

import { openModal } from 'actions'
import { IDLE_TIMEOUT, IDLE_TIMEOUT_PROMPT_DURATION } from 'constants/policy'
import {
  IDLE_TIMEOUT_MODAL_KEY,
  IdleTimeoutContext
} from 'containers/IdleTimeout/consts'

type ProviderProps = {
  children?: React.ReactNode
}

const IdleTimeoutProvider = ({ children }: ProviderProps) => {
  const dispatch = useDispatch()
  const [timeUntilSignout, setTimeUntilSignout] = useState<number>(99999)
  const [active, setActive] = useState<boolean>(true)
  const { activate } = useIdleTimer({
    onActive: () => setActive(true),
    onPrompt: () => {
      setActive(false)
      setTimeUntilSignout(IDLE_TIMEOUT_PROMPT_DURATION)
      dispatch(openModal(IDLE_TIMEOUT_MODAL_KEY))
    },
    onAction: () => activate(),
    timeout: IDLE_TIMEOUT * 1000,
    promptBeforeIdle: IDLE_TIMEOUT_PROMPT_DURATION * 1000,
    crossTab: true,
    leaderElection: true
  })

  useEffect(() => {
    const timerId = setInterval(
      () =>
        !active &&
        timeUntilSignout > 0 &&
        setTimeUntilSignout(timeUntilSignout - 1),
      1000
    )

    return () => clearInterval(timerId)
  }, [timeUntilSignout, active])

  return (
    <IdleTimeoutContext.Provider value={{ timeUntilSignout, active }}>
      {children}
    </IdleTimeoutContext.Provider>
  )
}

export default IdleTimeoutProvider
