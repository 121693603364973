export class Env {
  static __REACT_APP_API_HOST__: string | undefined
  static __REACT_APP_GOOGLE_CLIENT_ID__: string | undefined

  static get REACT_APP_API_HOST() {
    if (typeof this.__REACT_APP_API_HOST__ === 'undefined') {
      if (process.env.NODE_ENV !== 'production') {
        this.__REACT_APP_API_HOST__ = ''
      } else {
        this.__REACT_APP_API_HOST__ = `https://${
          this.isStaging() ? 'api-staging' : 'api'
        }.middesk.com`
      }
    }

    return this.__REACT_APP_API_HOST__
  }

  static get REACT_APP_GOOGLE_CLIENT_ID() {
    if (typeof this.__REACT_APP_GOOGLE_CLIENT_ID__ === 'undefined') {
      if (this.isStaging()) {
        this.__REACT_APP_GOOGLE_CLIENT_ID__ =
          process.env.REACT_APP_STAGING_GOOGLE_CLIENT_ID
      } else {
        this.__REACT_APP_GOOGLE_CLIENT_ID__ =
          process.env.REACT_APP_GOOGLE_CLIENT_ID
      }
    }

    return this.__REACT_APP_GOOGLE_CLIENT_ID__
  }

  static get UTIL_URL() {
    if (this.isProduction()) {
      return 'https://util.middesk.com'
    }

    if (this.isStaging()) {
      return 'https://util-staging.middesk.com'
    }

    return 'http://localhost:3004'
  }

  static get APP_URL() {
    if (this.isProduction()) {
      return 'https://app.middesk.com'
    }

    if (this.isStaging()) {
      return 'https://app-staging-0ab8b997547b.middesk.com'
    }

    return 'http://localhost:3000'
  }

  static get AGENT_URL() {
    if (this.isProduction()) {
      return 'https://agent.middesk.com'
    }

    if (this.isStaging()) {
      return 'https://agent-staging.middesk.com'
    }

    return 'http://localhost:3005'
  }

  static get API_URL() {
    if (this.isProduction()) {
      return 'https://api.middesk.com'
    }

    if (this.isStaging()) {
      return 'https://api-staging.middesk.com'
    }

    return 'http://localhost:3002'
  }

  static get API_WS_URL() {
    if (this.isProduction()) {
      return 'wss://api.middesk.com/ws'
    }

    if (this.isStaging()) {
      return 'wss://api-staging.middesk.com/ws'
    }

    return 'ws://localhost:3002/ws'
  }

  static isStaging() {
    return /^app-staging-[^.]+\.middesk\.com/.test(window.location.hostname)
  }

  static isProduction() {
    return /^app.middesk\.com/.test(window.location.hostname)
  }
}
