import React, { lazy, Suspense } from 'react'

import Authenticated from 'components/Authenticated'

const NewSandboxBusinessModal = lazy(() => import(/* webpackChunkName: 'NewSandboxBusinessModal' */ 'components/NewSandboxBusinessModal')) // prettier-ignore
const SupportForm = lazy(() => import(/* webpackChunkName: 'SupportForm' */ 'components/SupportForm')) // prettier-ignore
const UpdateUser = lazy(() => import(/* webpackChunkName: 'UpdateUser' */ 'components/UpdateUser')) // prettier-ignore
const WelcomeModal = lazy(() => import(/* webpackChunkName: 'WelcomeModal' */ 'components/WelcomeModal')) // prettier-ignore
const ExportCSVError = lazy(() => import(/* webpackChunkName: 'ExportCSVError' */ 'containers/Businesses/Toolbar/ExportCSVError')) // prettier-ignore
const FullScreenshot = lazy(() =>import(/* webpackChunkName: 'FullScreenshot' */ 'containers/FullScreenshot')) // prettier-ignore
const LienFilingModal = lazy(() => import(/* webpackChunkName: 'LienFilingModal' */ 'containers/LienFiling/LienFilingModal')) // prettier-ignore
const LienTerminationModal = lazy(() => import(/* webpackChunkName: 'LienTerminationModal' */ 'containers/LienFiling/LienTerminationModal')) // prettier-ignore
const NewBatch = lazy(() => import(/* webpackChunkName: 'NewBatch' */ 'containers/Settings/Batches/NewBatch')) // prettier-ignore
const ConfirmDeletePolicy = lazy(() => import(/* webpackChunkName: 'ConfirmDeletePolicy' */ 'containers/Settings/Policies/Modals/ConfirmDelete')) // prettier-ignore
const ConfirmPolicyUpdates = lazy(() => import(/* webpackChunkName: 'ConfirmPolicyUpdates' */ 'containers/Settings/Policies/Modals/ConfirmSave')) // prettier-ignore
const EditPolicyConfirmSave = lazy(() => import(/* webpackChunkName: 'EditPolicyConfirmSave' */ 'containers/Settings/Policies/Modals/EditPolicyConfirmSave')) // prettier-ignore
const SavePolicyError = lazy(() => import(/* webpackChunkName: 'SavePolicyError' */ 'containers/Settings/Policies/Modals/SavePolicyError')) // prettier-ignore
const ConfirmDeleteUser = lazy(() => import(/* webpackChunkName: 'ConfirmDeleteUser' */ 'containers/Settings/Team/ConfirmDelete')) // prettier-ignore
const NewUser = lazy(() =>import(/* webpackChunkName: 'NewUser' */ 'containers/Settings/Team/NewUser')) // prettier-ignore
const DisableWebhook = lazy(() => import(/* webpackChunkName: 'DisableWebhook' */ 'containers/Settings/Webhooks/DisableWebhook')) // prettier-ignore
const EnableWebhook = lazy(() => import(/* webpackChunkName: 'EnableWebhook' */ 'containers/Settings/Webhooks/EnableWebhook')) // prettier-ignore
const NewWebhook = lazy(() => import(/* webpackChunkName: 'NewWebhook' */ 'containers/Settings/Webhooks/NewWebhook')) // prettier-ignore
const RemoveWebhook = lazy(() => import(/* webpackChunkName: 'RemoveWebhook' */ 'containers/Settings/Webhooks/RemoveWebhook')) // prettier-ignore
const ToAgent = lazy(() => import(/* webpackChunkName: 'ToAgent' */ 'containers/ToAgent')) // prettier-ignore
const IdleTimeoutModal = lazy(() => import(/* webpackChunkName: 'IdleTimeout' */ 'containers/IdleTimeout/Modal')) // prettier-ignore

const Modals = () => (
  <Authenticated>
    <Suspense fallback={null}>
      <WelcomeModal />
      <FullScreenshot />
      <ExportCSVError />
      <SavePolicyError />
      <ToAgent />
      <NewUser />
      <NewBatch />
      <RemoveWebhook />
      <DisableWebhook />
      <EnableWebhook />
      <NewWebhook />
      <LienFilingModal />
      <LienTerminationModal />
      <SupportForm />
      <UpdateUser />
      <NewSandboxBusinessModal />
      <ConfirmPolicyUpdates />
      <EditPolicyConfirmSave />
      <ConfirmDeletePolicy />
      <ConfirmDeleteUser />
      <IdleTimeoutModal />
    </Suspense>
  </Authenticated>
)

export default Modals
