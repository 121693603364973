import React from 'react'

import { theme } from '@middesk/components'
import startCase from 'lodash/startCase'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import PageBanner from 'components/Banners/PageBanner'
import { Close } from 'components/Icons'
import { RootState } from 'types'

const { colors, typography } = theme

const Title = styled.div`
  color: ${colors.black};
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};

  span {
    font-weight: ${typography.weights.normal};
  }
`

const StyledClose = styled(Close)`
  cursor: pointer;
  fill: ${colors.black};
  height: 25px;
  stroke: ${colors.black};
  width: 25px;
`

const ImpersonateBanner = () => {
  const navigate = useNavigate()
  const user = useSelector(({ currentUser }: RootState) => currentUser)
  const impersonator = useSelector(
    ({ currentUser }: RootState) => currentUser?.impersonator
  )

  if (!impersonator) {
    return <></>
  }

  const primaryColor = colors.greenAccent
  const formattedNames = user.roles?.map(role => startCase(role)).join(', ')

  const userInformation = [
    user.name,
    user.email,
    formattedNames,
    user.account.name
  ].join(' · ')

  return (
    <PageBanner {...{ primaryColor }}>
      <Title>
        Impersonating&nbsp;&nbsp;<span>{userInformation}</span>
      </Title>
      <StyledClose
        {...{
          onClick: () => {
            navigate('/signout')
          }
        }}
      />
    </PageBanner>
  )
}

export default ImpersonateBanner
