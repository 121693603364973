import React from 'react'

import { theme } from '@middesk/components'
import styled from 'styled-components'

const { colors } = theme

const FocusIcon = styled.div`
  background-color: ${colors.dawn};
  border-radius: 4px;
  color: ${colors.graphite};
  font-size: 14px;
  height: 16px;
  line-height: 1;
  margin-right: 10px;
  padding: 2px;
  text-align: center;
`

const KBDFont = styled.kbd`
  font-family: 'Suisse Intl', sans-serif;
  font-size: 1em;
`

type KeyboardInputIconProps = {
  children: string
}
const KeyboardInputIcon = ({ children }: KeyboardInputIconProps) => {
  return (
    <FocusIcon>
      <KBDFont>{children}</KBDFont>
    </FocusIcon>
  )
}

export default KeyboardInputIcon
