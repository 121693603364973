import { AbrvState } from 'types'

import { STATES_ARRAY } from './constants'

export const API_DOCS_LINK = 'https://docs.middesk.com/docs/agent-overview'
export const API_SANDBOX_GUIDE_LINK =
  'https://docs.middesk.com/docs/entity-management-sandbox-environment'

export const STATE_UNEMPLOYMENT_INSURANCE_TYPE = 'state_unemployment_insurance'
export const STATE_TAX_WITHHOLDINGS_TYPE = 'state_tax_withholdings'
export const BOTH_SUI_AND_SWH_TYPE = 'both_sui_and_swh'
export const STATE_PAYROLL_TYPE = 'state_payroll'
export const TAX_TYPES: { [key: string]: { name: string } } = {
  [STATE_UNEMPLOYMENT_INSURANCE_TYPE]: { name: 'Unemployment Insurance' },
  [STATE_TAX_WITHHOLDINGS_TYPE]: { name: 'Withholding Tax' }
}
export const SECRETARY_OF_STATE_TYPE = 'secretary_of_state'
export const PAYROLL_DATE = 'payroll_date'
export const HIRE_DATE = 'hire_date'
export const LIABILITY = 'liability'
export const PORTAL_UNAVAILABLE = 'portal_unavailable'

export const AGENT_STATES = STATES_ARRAY.filter(
  state => ![AbrvState.PR, AbrvState.VI].includes(state.abbr)
)

export const PRODUCTS_BY_STATE: { [key: string]: string[] } = {
  AL: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  AK: [STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  AZ: [BOTH_SUI_AND_SWH_TYPE],
  AR: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  CA: [BOTH_SUI_AND_SWH_TYPE],
  CO: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  CT: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  DE: [BOTH_SUI_AND_SWH_TYPE],
  DC: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  FL: [STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  GA: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  HI: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  ID: [BOTH_SUI_AND_SWH_TYPE],
  IL: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  IN: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  IA: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  KS: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  KY: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  LA: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  ME: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  MD: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  MA: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  MI: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  MN: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  MS: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  MO: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  MT: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  NE: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  NV: [STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  NH: [STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  NJ: [BOTH_SUI_AND_SWH_TYPE],
  NM: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  NY: [BOTH_SUI_AND_SWH_TYPE],
  NC: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  ND: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  OH: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  OK: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  OR: [BOTH_SUI_AND_SWH_TYPE],
  PA: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  RI: [BOTH_SUI_AND_SWH_TYPE],
  SC: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  SD: [STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  TN: [STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  TX: [STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  UT: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  VT: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  VA: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  WA: [STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  WV: [BOTH_SUI_AND_SWH_TYPE],
  WI: [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE],
  WY: [STATE_UNEMPLOYMENT_INSURANCE_TYPE]
}

export const AVAILABLE = 'available'
export const REGISTRATION_REQUESTED = 'registration_requested'
export const REGISTRATION_STARTED = 'registration_started'
export const COMPLETED = 'completed'
export const BLOCKED_BY_GOVERNMENT = 'blocked_by_government'
export const MIDDESK_PROCESSING = 'middesk_processing'
export const GOVERNMENT_PROCESSING = 'government_processing'
export const NOT_INCLUDED = 'not_included'
export const DUPLICATE = 'duplicate'
export const UNMANAGED = 'unmanaged'
export const OPEN = 'open'
export const BLOCKED_PAYROLL_DATE = 'blocked_payroll_date'
export const BLOCKED_PORTAL_UNAVAILABLE = 'blocked_portal_unavailable'
export const INFORMATION_REQUEST = 'information_request'
export const WAITING_ON_MAIL = 'waiting_on_mail'

export const INFORMATION_REQUESTED = 'information_requested'

export const ACTION_REQUIRED = 'action_required'
export const NO_ACTION_REQUIRED = 'no_action_required'

export const TAX_IDS_AVAILABLE = 'tax_ids_available'
export const TAX_IDS_AVAILABLE_UNCOPIED = 'tax_ids_available_uncopied'
export const TAX_IDS_COMPLETED = 'tax_ids_completed'

export const AGENT_TAX_AGENCY_REGISTRATION = 'agent_tax_agency_registration'
export const PARTNER_ID = 'partner_id'
