import { ReactElement, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { page } from 'lib/segment'

const usePageViews = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    page()
  }, [pathname])
}

export default ({ children }: { children: ReactElement }) => {
  usePageViews()

  return children
}
