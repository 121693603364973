import {
  AddressSource,
  ExternalAddressSource
} from 'containers/BusinessHome/types'

export const PRIMARY_EXTERNAL_ADDRESS_SOURCE_TYPES = ['npi_record']

export const PrimaryExternalAddressSourceInfo = {
  npi_record: {
    name: 'NPPES NPI Registry',
    subtitle:
      'National Provider Identifier (NPI) registry for HIPAA-covered entities',
    path: 'npi_registry'
  }
} as const

export type ExternalAddressSourceType =
  keyof typeof PrimaryExternalAddressSourceInfo

export type ExternalAddressSourcePath =
  (typeof PrimaryExternalAddressSourceInfo)[ExternalAddressSourceType]['path']

export const addressSourcePathToType: Record<
  ExternalAddressSourcePath,
  ExternalAddressSourceType
> = {
  npi_registry: 'npi_record'
} as const

export function isExternalAddressSource(
  addressSource: AddressSource
): addressSource is ExternalAddressSource {
  return PRIMARY_EXTERNAL_ADDRESS_SOURCE_TYPES.includes(addressSource.type)
}
