import React from 'react'

import { Button, theme } from '@middesk/components'
import get from 'lodash/get'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useMatch } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { getIsAuthenticated } from 'redux-simple-auth'
import styled from 'styled-components'

import { openModal, search } from 'actions'
import ImpersonateBanner from 'components/Banners/ImpersonateBanner'
import SandboxBanner from 'components/Banners/SandboxBanner'
import TrialBanner from 'components/Banners/TrialBanner'
import { Question } from 'components/Icons'
import Omnibar from 'components/Omnibar'
import UserDropdown from 'components/UserDropdown'
import { AccountLevels } from 'constants/constants'
import { RootState, User } from 'types'
import { agentOnly } from 'utils/agent'

import cya from '../../macros/cya.macro' // eslint-disable-line no-relative-import-paths/no-relative-import-paths

import PlaceOrder from './PlaceOrder'

const { colors, spacing, typography } = theme

export const HIDE_NAV_BAR_ROUTES = Object.freeze([
  '/contact-sales',
  '/purchase-plan',
  '/pricing-options',
  '/order'
])

const Title = styled.div`
  font-size: ${typography.sizes.large};
  font-weight: ${typography.weights.bold};
  padding-left: ${spacing.normal};
`

const Spacer = styled.div`
  flex-grow: 1;
`

const ButtonBar = styled.div`
  align-items: center;
  display: flex;
  z-index: 2;

  > :not(:first-child) {
    margin-left: ${spacing.compact};
  }
`

const TeaserText = styled.span`
  color: ${colors.karl};
  font-size: ${typography.sizes.medium};
`

const IconContainer = styled(Button)`
  align-items: center;
  background-color: ${colors.dawn};
  border: none;
  border-radius: 50%;
  color: ${colors.graphite};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 35px;
  justify-content: center;
  min-width: 35px;
  padding: 0;
  transition: all ease 250ms;
  width: 35px;
  &:hover:not(:disabled) {
    background-color: ${colors.frost};
  }
  &:focus:not(:disabled) {
    background-color: ${colors.frost};
  }
  > svg {
    position: relative;
    right: 0px;
    width: 16px;
  }
`

const Header = styled.header`
  position: relative;

  /* Z index is required here to ensure the nav appears over the header on the Businesses List */
  z-index: 4;
`
const NavBarContainer = styled.nav`
  align-items: center;
  background-color: ${colors.frostLight};
  display: flex;
  flex: 0 0 auto;
  min-height: 58px;
  padding: 0 24px;
  padding-top: 22px;
  width: 100%;
`

const Banner = ({ currentUser }: { currentUser: User }) => {
  const level = currentUser?.account?.level

  if (level && level === AccountLevels.TRIAL) {
    return <TrialBanner />
  } else {
    return <SandboxBanner agentOnly={agentOnly(currentUser?.account)} />
  }
}

const NavBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const currentUser = useSelector((state: RootState) => state.currentUser)
  const session = useSelector(({ session }: RootState) => session)
  const isAuthenticated = useSelector(s => getIsAuthenticated(s))

  const summaryMatch = useMatch('/summaries/:id')
  const businessOrderAddOn = useMatch('/businesses/:id/add-on')

  const title = summaryMatch && 'Summary Report'

  const onSearch = React.useCallback(
    (query: string) => {
      return dispatch(search(query))
    },
    [dispatch]
  )

  const openSupportModal = () => {
    dispatch(openModal('support-x'))
  }

  const isLoggedInUser = () => {
    if (!isAuthenticated || !currentUser) {
      return false
    }

    const { account } = currentUser

    if (!account) {
      return false
    }

    return true
  }

  if (
    (!isLoggedInUser() && !summaryMatch) ||
    HIDE_NAV_BAR_ROUTES.includes(location.pathname) ||
    businessOrderAddOn
  ) {
    return null
  }

  const onAgentPage = location.pathname.match(/^\/agent*/)

  const omnibarEnabled =
    (get(session, 'data.account.enabled') ||
      get(session, 'data.user.account.enabled')) &&
    !onAgentPage

  return (
    <Header>
      <Banner currentUser={currentUser} />
      <ImpersonateBanner />

      <NavBarContainer>
        {title && <Title> {title} </Title>}
        <Spacer />
        <ButtonBar>
          {isLoggedInUser() ? (
            <>
              {omnibarEnabled && (
                <Omnibar search={onSearch} navigate={navigate} />
              )}
              <IconContainer
                aria-label='Contact support'
                onClick={openSupportModal}
                {...cya('StyledQuestionIcon')}
              >
                <Question />
              </IconContainer>
              {!onAgentPage && <PlaceOrder />}

              <UserDropdown size={35} />
            </>
          ) : (
            <>
              <TeaserText>Sign in to see your businesses</TeaserText>
              <Button to='/signin'>Sign in</Button>
            </>
          )}
        </ButtonBar>
      </NavBarContainer>
    </Header>
  )
}

export default NavBar
