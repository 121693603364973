import React from 'react'

import styled from 'styled-components'

import { ReactComponent as LogoSVG } from './MDK-Icon-Dark.svg'

const MiddeskDarkIcon = styled(({ className, ...props }) => {
  return (
    <LogoSVG height={'22px'} width={'40px'} className={className} {...props} />
  )
})``

export default MiddeskDarkIcon
