import {
  addressSourcePathToType,
  PrimaryExternalAddressSourceInfo
} from 'constants/primary_address_sources'
import { AbrvState, Event } from 'types'

const STATE_MAP = new Map()

STATE_MAP.set('all', new Map())

export const REVIEW_STATUSES = Object.freeze({
  APPROVED: 'approved',
  IN_REVIEW: 'in_review',
  REJECTED: 'rejected'
})

type BankruptcyChapter = {
  title: string
  description: string
  displayChapter: string
  link: string
}

type BankruptcyChapterInfoByChapter = Partial<Record<number, BankruptcyChapter>>

export const BANKRUPTCY_CHAPTER_INFO: BankruptcyChapterInfoByChapter =
  Object.freeze({
    7: {
      displayChapter: 'Chapter 7',
      title: 'Chapter 7: Liquidation Under the Bankruptcy Code',
      description:
        'The chapter of the Bankruptcy Code providing for "liquidation," ( i.e., the sale of a debtor\'s nonexempt property and the distribution of the proceeds to creditors.)',
      link: 'https://www.uscourts.gov/services-forms/bankruptcy/bankruptcy-basics/chapter-7-bankruptcy-basics'
    },
    9: {
      displayChapter: 'Chapter 9',
      title: 'Chapter 9: Municipal Bankruptcy',
      description:
        'The chapter of the Bankruptcy Code providing for reorganization of municipalities (which includes cities and towns, as well as villages, counties, taxing districts, municipal utilities, and school districts).',
      link: 'https://www.uscourts.gov/services-forms/bankruptcy/bankruptcy-basics/chapter-9-bankruptcy-basics'
    },
    11: {
      displayChapter: 'Chapter 11',
      title: 'Chapter 11: Reorganization Under the Bankruptcy Code',
      description:
        'The chapter of the Bankruptcy Code providing (generally) for reorganization, usually involving a corporation or partnership. (A chapter 11 debtor usually proposes a plan of reorganization to keep its business alive and pay creditors over time. People in business or individuals can also seek relief in chapter 11.)',
      link: 'https://www.uscourts.gov/services-forms/bankruptcy/bankruptcy-basics/chapter-11-bankruptcy-basics'
    },
    12: {
      displayChapter: 'Chapter 12',
      title:
        'Chapter 12: Family Farmer Bankruptcy or Family Fisherman Bankruptcy',
      description:
        'The chapter of the Bankruptcy Code providing for adjustment of debts of a "family farmer," or a "family fisherman" as those terms are defined in the Bankruptcy Code.',
      link: 'https://www.uscourts.gov/services-forms/bankruptcy/bankruptcy-basics/chapter-12-bankruptcy-basics'
    },
    13: {
      displayChapter: 'Chapter 13',
      title: 'Chapter 13: Individual Debt Adjustment',
      description:
        'The chapter of the Bankruptcy Code providing for adjustment of debts of an individual with regular income. (Chapter 13 allows a debtor to keep property and pay debts over time, usually three to five years.)',
      link: 'https://www.uscourts.gov/services-forms/bankruptcy/bankruptcy-basics/chapter-13-bankruptcy-basics'
    },
    15: {
      displayChapter: 'Chapter 15',
      title: 'Chapter 15: Ancillary and Other Cross-Border Cases',
      description:
        'The purpose of Chapter 15, and the Model Law on which it is based, is to provide effective mechanisms for dealing with insolvency cases involving debtors, assets, claimants, and other parties of interest involving more than one country.',
      link: 'https://www.uscourts.gov/services-forms/bankruptcy/bankruptcy-basics/chapter-15-bankruptcy-basics'
    }
  })

export type StateType = {
  name: string
  abbr: AbrvState
  center: {
    lat: number
    lng: number
  }
}

export const STATES_ARRAY: Array<StateType> = [
  {
    name: 'Alabama',
    abbr: AbrvState.AL,
    center: {
      lat: 32.799,
      lng: -86.8073
    }
  },
  {
    name: 'Alaska',
    abbr: AbrvState.AK,
    center: {
      lat: 61.385,
      lng: -152.2683
    }
  },
  {
    name: 'Arizona',
    abbr: AbrvState.AZ,
    center: {
      lat: 33.7712,
      lng: -111.3877
    }
  },
  {
    name: 'Arkansas',
    abbr: AbrvState.AR,
    center: {
      lat: 34.9513,
      lng: -92.3809
    }
  },
  {
    name: 'California',
    abbr: AbrvState.CA,
    center: {
      lat: 36.17,
      lng: -119.7462
    }
  },
  {
    name: 'Colorado',
    abbr: AbrvState.CO,
    center: {
      lat: 39.0646,
      lng: -105.3272
    }
  },
  {
    name: 'Connecticut',
    abbr: AbrvState.CT,
    center: {
      lat: 41.5834,
      lng: -72.7622
    }
  },
  {
    name: 'Delaware',
    abbr: AbrvState.DE,
    center: {
      lat: 39.3498,
      lng: -75.5148
    }
  },
  {
    name: 'District of Columbia',
    abbr: AbrvState.DC,
    center: {
      lat: 38.8974,
      lng: -77.0268
    }
  },
  {
    name: 'Florida',
    abbr: AbrvState.FL,
    center: {
      lat: 27.8333,
      lng: -81.717
    }
  },
  {
    name: 'Georgia',
    abbr: AbrvState.GA,
    center: {
      lat: 32.9866,
      lng: -83.6487
    }
  },
  {
    name: 'Hawaii',
    abbr: AbrvState.HI,
    center: {
      lat: 21.1098,
      lng: -157.5311
    }
  },
  {
    name: 'Idaho',
    abbr: AbrvState.ID,
    center: {
      lat: 44.2394,
      lng: -114.5103
    }
  },
  {
    name: 'Illinois',
    abbr: AbrvState.IL,
    center: {
      lat: 40.3363,
      lng: -89.0022
    }
  },
  {
    name: 'Indiana',
    abbr: AbrvState.IN,
    center: {
      lat: 39.8647,
      lng: -86.2604
    }
  },
  {
    name: 'Iowa',
    abbr: AbrvState.IA,
    center: {
      lat: 42.0046,
      lng: -93.214
    }
  },
  {
    name: 'Kansas',
    abbr: AbrvState.KS,
    center: {
      lat: 38.5111,
      lng: -96.8005
    }
  },
  {
    name: 'Kentucky',
    abbr: AbrvState.KY,
    center: {
      lat: 37.669,
      lng: -84.6514
    }
  },
  {
    name: 'Louisiana',
    abbr: AbrvState.LA,
    center: {
      lat: 31.1801,
      lng: -91.8749
    }
  },
  {
    name: 'Maine',
    abbr: AbrvState.ME,
    center: {
      lat: 44.6074,
      lng: -69.3977
    }
  },
  {
    name: 'Maryland',
    abbr: AbrvState.MD,
    center: {
      lat: 39.0724,
      lng: -76.7902
    }
  },
  {
    name: 'Massachusetts',
    abbr: AbrvState.MA,
    center: {
      lat: 42.2373,
      lng: -71.5314
    }
  },
  {
    name: 'Michigan',
    abbr: AbrvState.MI,
    center: {
      lat: 43.3504,
      lng: -84.5603
    }
  },
  {
    name: 'Minnesota',
    abbr: AbrvState.MN,
    center: {
      lat: 45.7326,
      lng: -93.9196
    }
  },
  {
    name: 'Mississippi',
    abbr: AbrvState.MS,
    center: {
      lat: 32.7673,
      lng: -89.6812
    }
  },
  {
    name: 'Missouri',
    abbr: AbrvState.MO,
    center: {
      lat: 38.4623,
      lng: -92.302
    }
  },
  {
    name: 'Montana',
    abbr: AbrvState.MT,
    center: {
      lat: 46.9048,
      lng: -110.3261
    }
  },
  {
    name: 'Nebraska',
    abbr: AbrvState.NE,
    center: {
      lat: 41.1289,
      lng: -98.2883
    }
  },
  {
    name: 'Nevada',
    abbr: AbrvState.NV,
    center: {
      lat: 38.4199,
      lng: -117.1219
    }
  },
  {
    name: 'New Hampshire',
    abbr: AbrvState.NH,
    center: {
      lat: 43.4108,
      lng: -71.5653
    }
  },
  {
    name: 'New Jersey',
    abbr: AbrvState.NJ,
    center: {
      lat: 40.314,
      lng: -74.5089
    }
  },
  {
    name: 'New Mexico',
    abbr: AbrvState.NM,
    center: {
      lat: 34.8375,
      lng: -106.2371
    }
  },
  {
    name: 'New York',
    abbr: AbrvState.NY,
    center: {
      lat: 42.1497,
      lng: -74.9384
    }
  },
  {
    name: 'North Carolina',
    abbr: AbrvState.NC,
    center: {
      lat: 35.6411,
      lng: -79.8431
    }
  },
  {
    name: 'North Dakota',
    abbr: AbrvState.ND,
    center: {
      lat: 47.5362,
      lng: -99.793
    }
  },
  {
    name: 'Ohio',
    abbr: AbrvState.OH,
    center: {
      lat: 40.3736,
      lng: -82.7755
    }
  },
  {
    name: 'Oklahoma',
    abbr: AbrvState.OK,
    center: {
      lat: 35.5376,
      lng: -96.9247
    }
  },
  {
    name: 'Oregon',
    abbr: AbrvState.OR,
    center: {
      lat: 44.5672,
      lng: -122.1269
    }
  },
  {
    name: 'Pennsylvania',
    abbr: AbrvState.PA,
    center: {
      lat: 40.5773,
      lng: -77.264
    }
  },
  {
    name: 'Puerto Rico',
    abbr: AbrvState.PR,
    center: {
      lat: 18.2491,
      lng: -66.628
    }
  },
  {
    name: 'Rhode Island',
    abbr: AbrvState.RI,
    center: {
      lat: 41.6772,
      lng: -71.5101
    }
  },
  {
    name: 'South Carolina',
    abbr: AbrvState.SC,
    center: {
      lat: 33.8191,
      lng: -80.9066
    }
  },
  {
    name: 'South Dakota',
    abbr: AbrvState.SD,
    center: {
      lat: 44.2853,
      lng: -99.4632
    }
  },
  {
    name: 'Tennessee',
    abbr: AbrvState.TN,
    center: {
      lat: 35.7449,
      lng: -86.7489
    }
  },
  {
    name: 'Texas',
    abbr: AbrvState.TX,
    center: {
      lat: 31.106,
      lng: -97.6475
    }
  },
  {
    name: 'Utah',
    abbr: AbrvState.UT,
    center: {
      lat: 40.1135,
      lng: -111.8535
    }
  },
  {
    name: 'Vermont',
    abbr: AbrvState.VT,
    center: {
      lat: 44.0407,
      lng: -72.7093
    }
  },
  {
    name: 'Virginia',
    abbr: AbrvState.VA,
    center: {
      lat: 37.768,
      lng: -78.2057
    }
  },
  {
    name: 'Virgin Islands',
    abbr: AbrvState.VI,
    center: {
      lat: 18.3358,
      lng: 64.8963
    }
  },
  {
    name: 'Washington',
    abbr: AbrvState.WA,
    center: {
      lat: 47.3917,
      lng: -121.5708
    }
  },
  {
    name: 'West Virginia',
    abbr: AbrvState.WV,
    center: {
      lat: 38.468,
      lng: -80.9696
    }
  },
  {
    name: 'Wisconsin',
    abbr: AbrvState.WI,
    center: {
      lat: 44.2563,
      lng: -89.6385
    }
  },
  {
    name: 'Wyoming',
    abbr: AbrvState.WY,
    center: {
      lat: 42.7475,
      lng: -107.2085
    }
  }
]

export const getStateByAbbr = (abbr: string | null): StateType | undefined =>
  STATES_ARRAY.find(state => state.abbr === abbr)

export const STATES: Map<AbrvState | 'all', StateType> = STATES_ARRAY.reduce(
  (m, s) => {
    const { name, abbr } = s

    m.get('all').set(name, s)
    m.set(abbr, s)

    return m
  },
  STATE_MAP
)

export const HIGH_RISK_INDUSTRIES = Object.freeze([
  'AGGREGATION',
  'ADULT_CONTENT',
  'CANNABIS',
  'COUNTERFEIT_GOODS',
  'DRUG_PARAPHERNALIA',
  'DRUG_OF_CONCERN',
  'GAMBLING',
  'GET_RICH_QUICK',
  'GIFT_CARD',
  'HIGH_RISK',
  'INVESTMENT_CREDIT',
  'MONEY_LEGAL',
  'MULTI_LEVEL_MARKETING',
  'PSEUDO_PHARMACEUTICAL',
  'REGULATED_ILLEGAL',
  'SOCIAL_MEDIA_ACTIVITY',
  'VIRTUAL_CURRENCY'
])

export const BATCH_EXAMPLE_FILE = 'middesk_batches.zip'

export const TAX_EXEMPT_SECTION_MAP = {
  SECTION_501_C_1: 'Section 501(c)(1)',
  SECTION_501_C_2: 'Section 501(c)(2)',
  SECTION_501_C_3: 'Section 501(c)(3)',
  SECTION_501_C_4: 'Section 501(c)(4)',
  SECTION_501_C_5: 'Section 501(c)(5)',
  SECTION_501_C_6: 'Section 501(c)(6)',
  SECTION_501_C_7: 'Section 501(c)(7)',
  SECTION_501_C_8: 'Section 501(c)(8)',
  SECTION_501_C_9: 'Section 501(c)(9)',
  SECTION_501_C_10: 'Section 501(c)(10)',
  SECTION_501_C_11: 'Section 501(c)(11)',
  SECTION_501_C_12: 'Section 501(c)(12)',
  SECTION_501_C_13: 'Section 501(c)(13)',
  SECTION_501_C_14: 'Section 501(c)(14)',
  SECTION_501_C_15: 'Section 501(c)(15)',
  SECTION_501_C_16: 'Section 501(c)(16)',
  SECTION_501_C_17: 'Section 501(c)(17)',
  SECTION_501_C_18: 'Section 501(c)(18)',
  SECTION_501_C_19: 'Section 501(c)(19)',
  SECTION_501_C_20: 'Section 501(c)(20)',
  SECTION_501_C_21: 'Section 501(c)(21)',
  SECTION_501_C_22: 'Section 501(c)(22)',
  SECTION_501_C_23: 'Section 501(c)(23)',
  SECTION_501_C_24: 'Section 501(c)(24)',
  SECTION_501_C_25: 'Section 501(c)(25)',
  SECTION_501_C_26: 'Section 501(c)(26)',
  SECTION_501_C_27: 'Section 501(c)(27)',
  SECTION_501_C_29: 'Section 501(c)(29)',
  SECTION_4947_A_1: 'Section 4947(a)(1)'
}

export const DIVEMAP = {
  address: 'addresses',
  address_document_verifications: 'address_document_verifications',
  adverse_media: 'adverse_media',
  bankruptcies: 'bankruptcies',
  ein_document_verifications: 'ein_document_verifications',
  enhanced_screenings: 'enhanced_screenings',
  global_watchlists: 'global_watchlists',
  industry: 'industry_classification',
  kyc: 'kyc',
  liens: 'liens',
  litigations: 'litigations',
  name: 'names',
  people: 'people',
  politically_exposed_persons: 'politically_exposed_persons',
  sos: 'registrations',
  tin: 'tin',
  watchlist: 'watchlists'
}

export const PRIMARY_TASK_IDENTIFIERS = [
  { category: 'name', key: 'name' },
  { category: 'name', key: 'dba_name' },
  { category: 'address', key: 'address_verification' },
  { category: 'sos', key: 'sos_active' },
  { category: 'tin', key: 'tin' },
  { category: 'people', key: 'person_verification' },
  { category: 'kyc', key: 'kyc_decision' },
  { category: 'watchlist', key: 'watchlist' },
  { category: 'global_watchlists', key: 'global_watchlists' }
]

export const SECONDARY_TASK_IDENTIFIERS = [
  { category: 'sos', key: 'sos_not_found' },
  { category: 'sos', key: 'sos_inactive' },
  { category: 'sos', key: 'sos_unknown' },
  { category: 'sos', key: 'sos_domestic' },
  { category: 'sos', key: 'sos_match' },
  { category: 'sos', key: 'sos_domestic_sub_status' },
  { category: 'address', key: 'address_deliverability' },
  { category: 'address', key: 'address_cmra' },
  { category: 'address', key: 'address_property_type' },
  { category: 'address', key: 'address_registered_agent' },
  { category: 'address', key: 'address_high_risk' },
  { category: 'address', key: 'address_frequency' },
  { category: 'industry', key: 'industry' },
  { category: 'website', key: 'website_status' },
  { category: 'website', key: 'website_parked' },
  { category: 'website', key: 'website_verification' },
  { category: 'bankruptcies', key: 'bankruptcies' },
  { category: 'liens', key: 'liens' },
  { category: 'phone', key: 'phone' },
  { category: 'loans', key: 'ppp_loans' },
  { category: 'adverse_media', key: 'adverse_media' },
  {
    category: 'politically_exposed_persons',
    key: 'politically_exposed_persons'
  }
]

export const WEBSITE_ORDER_PRIMARY_TASK_IDENTIFIERS = [
  { category: 'website', key: 'website_verification' },
  { category: 'industry', key: 'industry' }
]

export const WEBSITE_ORDER_SECONDARY_TASK_IDENTIFIERS = [
  { category: 'website', key: 'website_status' }
]

const addressSourceBreadcrumbs = Object.entries(addressSourcePathToType).reduce(
  (breadcrumbs: Record<string, string>, [path, sourceType]) => {
    breadcrumbs[path] = PrimaryExternalAddressSourceInfo[sourceType].name

    return breadcrumbs
  },
  {}
)

export const BREADCRUMB_MAP: Record<string, string> = {
  addresses: 'Office Address',
  bankruptcies: 'Bankruptcies',
  liens: 'Liens',
  litigations: 'Litigations',
  names: 'Business Names',
  people: 'People',
  registrations: 'Secretary of State Filings',
  watchlists: 'Watchlists',
  ...addressSourceBreadcrumbs
}

Object.entries(addressSourcePathToType).forEach(([path, addressSourceType]) => {
  BREADCRUMB_MAP[path] =
    PrimaryExternalAddressSourceInfo[addressSourceType].name
})

export enum BusinessSource {
  API = 'API',
  BATCH = 'Batch',
  DASHBOARD = 'Dashboard'
}

export const MONITORING_TYPES = Object.freeze([
  {
    type: 'watchlist_result',
    title: 'Watchlists',
    showOnMonitoringSettings: true,
    isEventInstanceOfType: (event: Event) =>
      event.type === 'watchlist_result.created'
  },
  {
    type: 'bankruptcy',
    title: 'Bankruptcies',
    showOnMonitoringSettings: true,
    isEventInstanceOfType: (event: Event) => event.type === 'bankruptcy.created'
  },
  {
    type: 'registration',
    title: 'SOS Filings',
    showOnMonitoringSettings: true,
    isEventInstanceOfType: (event: Event) => {
      if (
        ['name', 'person', 'address', 'registration.created'].some(t =>
          event.type.includes(t)
        )
      ) {
        return true
      }

      // only include registration updated for status change
      return (
        event.type === 'registration.updated' &&
        event.data.previous_attributes?.status
      )
    }
  },
  {
    type: 'tin',
    title: 'TIN Discovery',
    showOnMonitoringSettings: true,
    isEventInstanceOfType: (event: Event) => event.type === 'tin.retrieved'
  },
  {
    type: 'tin.retried',
    title: 'TIN Retried',
    showOnMonitoringSettings: false,
    isEventInstanceOfType: (event: Event) => event.type === 'tin.retried'
  },
  {
    type: 'certification',
    title: 'Certifications',
    showOnMonitoringSettings: false,
    isEventInstanceOfType: (event: Event) =>
      event.type.includes('certification')
  }
])

export const SANDBOX_BUSINESS_OPTIONS = [
  {
    label: 'Business: Unregistered',
    request: {
      name: 'Unregistered Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Business Name: Similar Name',
    request: {
      name: 'Similar Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Business Name: Unverified',
    request: {
      name: 'Unverified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Formation Date: Recently Formed',
    request: {
      name: 'Young Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Office Address: Approximate Match (within 0.2 mi)',
    request: {
      name: 'Verified Business Name',
      addresses: [{ full_address: '423 Grand St., New York, NY 10013' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Office Address: Similar Match',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '223 Grand St., New York, NY 10013' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Office Address: Incomplete Match',
    request: {
      name: 'Verified Business Name',
      addresses: [{ full_address: 'New York, NY' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Office Address: CMRA',
    request: {
      name: 'Verified Business Name',
      addresses: [{ full_address: '991 cmra st., New York, NY 10013' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Office Address: Registered Agent',
    request: {
      name: 'Verified Business Name',
      addresses: [
        { full_address: '991 registered agent st., New York, NY 10013' }
      ],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Office Address: Deliverability',
    request: {
      name: 'Verified Business Name',
      addresses: [
        { full_address: '991 undeliverable st., New York, NY 10013' }
      ],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Office Address: Unverified',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '123 Grand St., New York, NY 10013' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },

  {
    label: 'SOS Filings: Domestic Missing',
    request: {
      name: 'Domestic Missing',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'SOS Filings: Domestic Inactive',
    request: {
      name: 'Domestic Inactive',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'SOS Filings: Domestic Unknown',
    request: {
      name: 'Domestic Unknown',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'SOS Filings: Partially Inactive',
    request: {
      name: 'Partial Inactive',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Person: Unverified',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'J Doe' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'TIN: Name Mismatch',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: { tin: '110000099' }
    }
  },
  {
    label: 'TIN: Name Unknown and Issued',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: { tin: '111222333' }
    }
  },
  {
    label: 'TIN: Name Unknown and not Issued',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: { tin: '444555666' }
    }
  },
  {
    label: 'TIN: Denylisted',
    request: {
      name: 'Denylisted TIN Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: { tin: '334445555' }
    }
  },
  {
    label: 'TIN: Duplicate Request',
    request: {
      name: 'Duplicate Request Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: { tin: '123454321' }
    }
  },
  {
    label: 'TIN: Invalid TIN',
    request: {
      name: 'Invalid TIN Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: { tin: '000000000' }
    }
  },
  {
    label: 'TIN: IRS Unavailable',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: { tin: '333444555' }
    }
  },
  {
    label: 'Bankruptcy: Found',
    request: {
      name: 'Business with a bankruptcy',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Watchlist: Hits',
    request: {
      name: 'Business with watchlist hit',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'Person with watchlist hit' }],
      website: null,
      tin: null
    }
  },
  {
    label: 'Industry Classification: NAICS Industry',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: { url: 'www.verified.com' },
      tin: null
    }
  },
  {
    label: 'Industry Classification: High Risk Industry',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: { url: 'www.highrisk.com' },
      tin: null
    }
  },
  {
    label: 'Business: All Attributes Verified',
    request: {
      name: 'Verified Name Business',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: { tin: '123456789' }
    }
  },
  {
    label: 'Liens: Found',
    request: {
      name: 'Business with liens found',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'ucc_liens' }
      ]
    }
  },
  {
    label: 'Liens: Document',
    request: {
      name: 'Business with liens found collateral lien',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'ucc_liens', subproducts: ['ucc_documents'] }
      ]
    }
  },
  {
    label: 'Adverse Media: High risk',
    request: {
      name: 'Business with adverse media highrisk',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'adverse_media' }
      ]
    }
  },
  {
    label: 'Adverse Media: Moderate risk',
    request: {
      name: 'Business with adverse media moderaterisk',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'adverse_media' }
      ]
    }
  },
  {
    label: 'Adverse Media: Low risk',
    request: {
      name: 'Business with adverse media lowrisk',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'adverse_media' }
      ]
    }
  },
  {
    label: 'Enhanced Screenings: Watchlist name hit, no PEP hits',
    request: {
      name: 'ES Hit',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'John Smith' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'enhanced_screenings' }
      ]
    }
  },
  {
    label: 'Enhanced Screenings: Watchlist person hit, no PEP hits',
    request: {
      name: 'Verified Business Name',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'ES Hit' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'enhanced_screenings' }
      ]
    }
  },
  {
    label: 'Enhanced Screenings: No watchlist hits, direct PEP hit',
    request: {
      name: 'Verified Business Name',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'PEP Direct Hits' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'enhanced_screenings' }
      ]
    }
  },
  {
    label: 'Enhanced Screenings: No watchlist hits, associate PEP hit',
    request: {
      name: 'Verified Business Name',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'PEP Associate Hits' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'enhanced_screenings' }
      ]
    }
  },
  {
    label:
      'Enhanced Screenings: No watchlist hits, direct and associate PEP hits',
    request: {
      name: 'Verified Business Name',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'PEP Direct Associate Hits' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'enhanced_screenings' }
      ]
    }
  },
  {
    label: 'Enhanced Screenings: Watchlist Person hit, direct PEP hit',
    request: {
      name: 'Verified Business Name',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [{ name: 'PEP Direct Associate Hits ES Hit' }],
      website: null,
      tin: null,
      orders: [
        { package: 'business_verification_verify' },
        { package: 'enhanced_screenings' }
      ]
    }
  },
  {
    label: 'KYC Order',
    request: {
      name: 'Business with KYC order',
      addresses: [{ full_address: '222 California Ave, Reno, NV 89509' }],
      people: [
        {
          first_name: 'Frank',
          last_name: 'Castle',
          address_line1: '2243 W 43RD ST',
          city: 'New York',
          state: 'NY',
          postal_code: '10036',
          ssn: '234-00-0168',
          dob: '1973-09-22',
          email: 'hello@world.com',
          phone_number: '+16175551212',
          device_session_id: 'anything-is-valid-in-sandbox',
          document_uuid: '123ab45d-2e34-46f3-8d17-6f540ae90303'
        },
        {
          first_name: 'Colleen',
          last_name: 'Wing',
          address_line1: '2243 W 43RD ST',
          city: 'Forest Grove',
          state: 'OR',
          postal_code: '97123',
          ssn: '732-00-4625',
          dob: '1987-11-11',
          email: 'hello@world.com',
          phone_number: '+15035557811',
          device_session_id: 'anything-is-valid-in-sandbox',
          document_uuid: '123ab45d-2e34-46f3-8d17-6f540ae90303'
        }
      ],
      website: null,
      tin: null
    }
  }
]

export const SANDBOX_DENYLIST = [
  '/v1/users',
  '/users',
  '/password',
  '/sessions',
  '/support',
  '/me/profile',
  '/me/settings',
  '/ajax/accounts',
  '/ajax/settings',
  '/ajax/setup_card',
  '/v1/kyc/reason_codes',
  '/v1/api_keys',
  '/ajax/watchlist_sources'
]

export const LIEN_FILING_LOAN_PRINCIPAL_AMOUNT_STATES = [
  AbrvState.FL,
  AbrvState.TN
]

export const LIEN_FILING_TIN_REQUIRED_STATES = [AbrvState.SD, AbrvState.ND]
export const LIEN_FILING_ENTITY_TYPE_REQUIRED_STATES = [AbrvState.NY]

export enum AccountLevels {
  TRIAL = 'trial',
  DIRECT_SALE = 'direct_sale',
  SELF_SERVE = 'self_serve'
}
