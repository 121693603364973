import React, { Component, createRef } from 'react'

import { Icon, theme } from '@middesk/components'
import Mousetrap from 'mousetrap'
import PropTypes from 'prop-types'
import qs from 'qs'
import Autosuggest from 'react-autosuggest'
import Highlighter from 'react-highlight-words'
import styled from 'styled-components'

import DateTime from 'components/DateTime'
import { Search } from 'components/Icons'
import KeyboardInputIcon from 'components/KeyboardInputIcon'

import cya from '../../macros/cya.macro' // eslint-disable-line no-relative-import-paths/no-relative-import-paths

const { colors, spacing, typography } = theme

const Suggestion = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  line-height: 1.25rem;
  max-width: 100%;
  padding: 0.625rem 1.875rem;

  > span:first-child {
    color: ${colors.graphite};
    flex-shrink: 1;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > mark {
      background-color: transparent;
      font-weight: 600;
    }
  }

  > span:last-child {
    color: ${colors.karl};
    flex-shrink: 0;
  }
`

const DateUpdate = styled(({ className, created_date }) => {
  return (
    <abbr {...{ className }}>
      <div>
        <DateTime relative>{created_date}</DateTime>
      </div>
    </abbr>
  )
})`
  border: none !important;
  text-decoration: none !important;

  > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${spacing.compact};

    > ${DateTime} {
      color: ${colors.karl};
      font-size: ${typography.sizes.xsmall};
      font-weight: ${typography.weights.bold};
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  span {
    min-width: 125px;
    text-align: center;
    width: 125px;
  }
`

export default styled(
  // eslint-disable-next-line react-prefer-function-component/react-prefer-function-component
  class SearchBar extends Component {
    static propTypes = {
      className: PropTypes.string,
      navigate: PropTypes.func,
      search: PropTypes.func
    }

    constructor(props) {
      super(props)

      this.autosuggest = createRef()

      this.state = {
        value: '',
        suggestions: [],
        isOpen: false
      }
    }

    componentDidMount() {
      Mousetrap.bind('ctrl+/', this.onShortcut)
    }

    componentWillUnmount() {
      Mousetrap.unbind('ctrl+/', this.onShortcut)
    }

    render() {
      // eslint-disable-next-line react/prop-types
      const { className, theme, placeholder, onClose } = this.props

      const { suggestions, value } = this.state
      const inputProps = {
        placeholder: placeholder || 'Search for a business',
        value,
        onFocus: () => {
          this.setState({ isOpen: true })
        },
        onBlur: () => {
          this.setState({ isOpen: false })
        },
        onClose,
        onChange: this.onChange,
        onKeyDown: this.onKeyDown
      }

      const defaultTheme = {
        container: `${className}__container`,
        containerOpen: `${className}__container--open`,
        input: `${className}__input`,
        inputOpen: `${className}__input--open`,
        inputFocused: `${className}__input--focused`,
        suggestionsContainerOpen: `${className}__suggestions-container--open`,
        suggestionHighlighted: `${className}__suggestion--highlighted`,
        suggestionsList: `${className}__suggestions-list`,
        suggestion: `${className}__suggestion`
      }

      if (this.state.isOpen) {
        defaultTheme.container += ` ${className}__container--focused`
      }

      if (!value) {
        defaultTheme.container += ` ${className}__container--empty`
      }

      return (
        <div className={this.props.className}>
          <Autosuggest
            ref={autosuggest => (this.autosuggest = autosuggest)}
            theme={theme ? theme : defaultTheme}
            renderInputComponent={this.renderInputComponent}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={suggestion => suggestion.name}
            renderSuggestion={suggestion => (
              <Suggestion>
                <Highlighter
                  autoEscape
                  searchWords={[value]}
                  textToHighlight={suggestion.name}
                />
                <DateUpdate {...suggestion} />
              </Suggestion>
            )}
            inputProps={inputProps}
          />
        </div>
      )
    }

    renderInputComponent = props => {
      return (
        <>
          <Search onClick={this.onFocus} />
          <input {...props} {...cya('OmnibarInput')} />
          <Icon aria-label={'clear'} name='cross2' onMouseDown={this.onClear} />
          <KeyboardInputIcon>Ctrl+/</KeyboardInputIcon>
        </>
      )
    }

    onKeyDown = e => {
      if (e.defaultPrevented) {
        return
      }

      if (e.keyCode !== 13) {
        return
      }

      const query = qs.stringify(
        {
          ...qs.parse(location.search, { ignoreQueryPrefix: true }),
          ...{ q: this.state.value }
        },
        { skipNulls: true }
      )

      this.props.navigate(`/businesses?${query}`)
      this.onClear()
    }

    onChange = (event, { newValue, method }) => {
      if (method === 'type') {
        this.setState({ value: newValue })
      }
    }

    onFocus = () => {
      this.autosuggest.input.focus()
    }

    onClear = e => {
      this.setState({ value: '', suggestions: [] })

      if (e) {
        e.preventDefault()
      } else {
        this.autosuggest.onBlur()

        setTimeout(() => {
          if (this.autosuggest && this.autosuggest.input) {
            this.autosuggest.input.blur()
          }
        }, 0)
      }
    }

    onSuggestionsFetchRequested = ({ value }) => {
      // When query is less than 3 characters, backend will
      // reject the request because pg_search locks the db.
      if (value.length >= 3) {
        this.props
          .search(value)
          .then(suggestions => {
            if (value !== this.state.value) {
              return
            }
            this.setState({ suggestions })
          })
          .catch(err => {
            throw err
          })
      } else {
        this.setState({ suggestions: [] })
      }
    }

    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      })
    }

    onSuggestionSelected = (e, { suggestion: { id } }) => {
      e.preventDefault()

      this.props.navigate(`/businesses/${id}`)

      this.onClear()
    }

    onShortcut = e => {
      e.preventDefault()

      if (!this.autosuggest) {
        return ''
      }

      this.autosuggest.input.focus()
    }
  }
)`
  position: relative;
  z-index: 2;

  &__container {
    right: 0;
    transition: width 250ms ease;
    width: 40px;

    &:not(&--focused) > svg:first-child:hover {
      background-color: rgba(var(--rgb--frost), 0.75);
    }

    > svg:first-child {
      background-color: ${colors.dawn};
      border-radius: 50%;
      color: ${colors.black};
      cursor: pointer;
      height: 35px;
      left: 0.25rem;
      margin-top: -17.5px;
      padding: 0 7px 0 8px;
      position: absolute;
      top: 50%;
      transition: all ease 250ms;
      width: 35px;
      z-index: 2;
    }

    input {
      height: 35px;
      min-width: auto;
      opacity: 0;
      padding: 0;
      width: 35px;
    }

    div:nth-last-child(2) {
      opacity: 0;
      position: absolute;
      right: 0.25rem;
      top: 10px;
      transition: all ease 250ms;
    }

    > input + svg {
      color: ${colors.karl};
      cursor: pointer;
      height: 35px;
      margin-top: -17.5px;
      opacity: 0;
      padding: 0 7px 0 8px;
      position: absolute;
      right: 1.75rem;
      top: 50%;
      transition: all ease 250ms;
      width: 35px;

      &:hover {
        color: ${colors.graphite};
      }
    }

    > span {
      color: var(--oc-gray-4);
      cursor: pointer;
      margin-top: -10px;
      padding: 0 6px;
      position: absolute;
      right: 0.5rem;
      top: 55%;
      transition: color ease 250ms;
      width: 1.75rem;
      z-index: 2;
    }
  }

  &__container--focused {
    width: 400px;

    input {
      border-color: ${colors.dawn};
      opacity: 1;
      padding: 8px 10px 8px 34px;
      width: 100%;
    }

    > svg:first-child {
      background-color: transparent;
    }

    > input + svg {
      opacity: 1;
    }

    div:nth-last-child(2) {
      opacity: 1;
    }
  }

  &__container--empty > input + svg {
    display: none;
  }

  &__suggestion--highlighted {
    background-color: ${colors.dawn};
  }

  &__suggestions-container--open {
    background-clip: padding-box;
    background-color: white;
    border: 1px solid ${colors.frost};
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    color: var(--oc-gray-7);
    margin-top: 5px;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  &__suggestions-list {
    background-color: white;
    list-style-type: none;
    margin: 5px 0;
    padding-left: 0;
  }

  &__input {
    background-color: white;
    border: 4px solid transparent;
    border-radius: 43px;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 0.875rem;
    min-width: 250px;
    outline: none;
    padding: 8px 10px 8px 34px;

    &::placeholder {
      color: ${colors.karl};
    }
  }
`
