import combineEvents from '@redux-beacon/combine-events'
import Segment, { identifyUser, trackEvent } from '@redux-beacon/segment'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'
import decode from 'jwt-decode'
import get from 'lodash/get'
import { createMiddleware } from 'redux-beacon'
import {
  actionTypes,
  createAuthenticator,
  createAuthMiddleware,
  createLocalStorageStore,
  getInitialAuthState
} from 'redux-simple-auth'

import { Env } from './env'
import { api } from './lib/api'
import { EVENTS, isSegmentEnabled } from './lib/segment'
import reducers from './reducers'

const {
  REACT_APP_FULL_STORY_ORG_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_GIT_REV,
  REACT_APP_SPRIG_ENVIRONMENT_ID
} = process.env

if (REACT_APP_SENTRY_DSN && Env.isProduction()) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    ignoreErrors: [
      'MapsRequestError',
      /MapsServerError.*UNKNOWN_ERROR/,
      'ResizeObserver loop limit exceeded'
    ],
    release: REACT_APP_GIT_REV,
    integrations: [
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllText: true
      })
    ],
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0
  })
}

const identify = identifyUser((_, __, nextState) => {
  return {
    userId:
      get(nextState, 'session.data.user.id') ||
      get(nextState, 'session.data.user.sub'),
    traits: {
      accountSlug: get(nextState, 'session.data.user.account.slug'),
      accountLevel: get(nextState, 'session.data.user.account.level')
    }
  }
})

const segmentEventsMap = isSegmentEnabled
  ? {
      [actionTypes.RESTORE]: identify,
      [actionTypes.AUTHENTICATE_SUCCEEDED]: combineEvents(
        identify,
        trackEvent(() => ({
          name: EVENTS.userSignedIn
        }))
      )
    }
  : {}

const segment = Segment()
const segmentMiddleware = createMiddleware(segmentEventsMap, segment)

const sentry = store => {
  Sentry.addEventProcessor(event => {
    const {
      currentUser: { id },
      ...state
    } = store.getState()

    return {
      ...event,
      extra: {
        ...event.extra,
        'redux:state': state
      },
      user: {
        ...event.user,
        id
      }
    }
  })

  return next => action => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type
    })

    return next(action)
  }
}

export const storage = createLocalStorageStore({ key: 'middesk-session' })

function transform(data) {
  try {
    data.user = decode(data.access_token)
    data.account = get(data, 'user.account')

    try {
      if (!!REACT_APP_FULL_STORY_ORG_ID && !!window.FS) {
        const { sub, email, name } = data.user

        window.FS.identify(sub, {
          email,
          displayName: name
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }

    if (REACT_APP_SPRIG_ENVIRONMENT_ID && window.Sprig) {
      const { sub, email, account, roles } = data.user

      window.Sprig('setUserId', sub)
      window.Sprig('setAttributes', {
        accountSlug: account.slug,
        email: email,
        roles: roles.join(', '),
        accountId: account.id
      })
    }

    return data
  } catch (e) {
    return {}
  }
}

const credentialsAuthenticator = createAuthenticator({
  name: 'credentials',
  authenticate: ({ email, password, token, accessToken }) => {
    if (accessToken) {
      return Promise.resolve(transform({ access_token: accessToken }))
    }

    const headers = {
      'Content-Type': 'application/json'
    }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    } else {
      headers.Authorization = `Basic ${btoa(`${email}:${password}`)}`
    }

    return fetch('/sessions', { method: 'POST', headers })
      .then(res => (res.ok ? res.json() : Promise.reject(res)))
      .then(transform)
  },
  restore: data => {
    if (Object.keys(data).length > 0) {
      return Promise.resolve(transform(data))
    }

    return Promise.reject()
  },
  invalidate: data => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.access_token}`
    }

    fetch('/sessions', { method: 'DELETE', headers })

    if (REACT_APP_SPRIG_ENVIRONMENT_ID && window.Sprig) {
      window.Sprig('logoutUser')
    }

    return Promise.resolve({})
  }
})

const authMiddleware = createAuthMiddleware({
  authenticators: [credentialsAuthenticator],
  storage
})

const store = configureStore({
  reducer: {
    ...reducers,
    [api.reducerPath]: api.reducer
  },
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: false,
      thunk: { extraArgument: '' }
    }),
    authMiddleware,
    api.middleware,
    segmentMiddleware,
    sentry
  ],
  devTools: !Env.isProduction(),
  enhancers: defaultEnhancers => [
    getInitialAuthState({ storage }),
    ...defaultEnhancers
  ]
})

export default store
