import { Business } from 'containers/BusinessHome/types'
import { PartyAddress } from 'containers/Liens/types'
import { AbrvState, User } from 'types'
import { getFullStateNameFromAbbreviation } from 'utils/states'

export type AddressType = 'DOMESTIC' | 'FOREIGN' | 'UNKNOWN' | 'SUBMITTED'

const ADDRESS_ORDERING = {
  DOMESTIC: 0,
  FOREIGN: 1,
  UNKNOWN: 2
}

export type RecommendedStates = {
  abbr: AbrvState
  addressType: AddressType
  name: string
}[]

export const getRecommendedFilingStates = (
  business?: Business
): RecommendedStates => {
  if (!business) return []

  const recommendedStates: RecommendedStates = (business?.registrations || [])
    .map(({ jurisdiction, state }) => ({
      abbr: state,
      addressType: jurisdiction,
      name: getFullStateNameFromAbbreviation(state)
    }))
    .filter(({ name }) => name)
    .sort(
      (
        { abbr: regState1, addressType: regType1 },
        { abbr: regState2, addressType: regType2 }
      ) =>
        ADDRESS_ORDERING[regType1] - ADDRESS_ORDERING[regType2] ||
        regState1.localeCompare(regState2)
    ) as unknown as RecommendedStates

  if (recommendedStates.length === 0) {
    const submittedState = business.addresses?.find(
      ({ submitted }) => submitted
    )?.state

    if (submittedState) {
      const stateFullName = getFullStateNameFromAbbreviation(submittedState)

      if (stateFullName) {
        recommendedStates.push({
          abbr: submittedState,
          addressType: 'SUBMITTED',
          name: stateFullName
        })
      }
    }
  }

  return recommendedStates
}

export function lienFilingSettingEnabled(
  business: Business | undefined,
  currentUser: User
) {
  if (business?.account) {
    return business.account.lien_filing_enabled
  } else {
    return currentUser.account.settings.liens.lien_filing_enabled
  }
}

export function isValidAddress(address: PartyAddress) {
  return Boolean(
    address.address_line1 &&
      address.city &&
      address.state &&
      address.postal_code
  )
}
