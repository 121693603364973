import React from 'react'

import { theme, Link, Button } from '@middesk/components'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useSpring } from 'react-spring'
import styled from 'styled-components'

import PageBanner from 'components/Banners/PageBanner'
import Separator from 'components/Separator'
import { RootState } from 'types'

const { colors, typography } = theme

const Title = styled.div`
  color: white;
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};
  line-height: 28px;

  span {
    font-weight: ${typography.weights.normal};
  }
`

const StyledLink = styled(Link)`
  color: white;
  cursor: pointer;
  font-weight: ${typography.weights.normal};
  margin-right: 16px;
  text-decoration: underline;

  &[data-focus='true']:not([disabled]),
  &[data-hover='true']:not([disabled]),
  &:focus:not([disabled]),
  &[data-active='true']:not([disabled]),
  &:hover:not([disabled]) {
    color: white;
  }
`

const StyledSeparator = styled(Separator)`
  background-color: white;
  bottom: 2px;
  margin-left: 4px;
  position: relative;
`

const TrialBanner = () => {
  const sandboxMode = useSelector(
    ({ sandbox }: RootState) => sandbox.sandboxMode
  )

  const style = useSpring({
    marginTop: sandboxMode ? '0px' : '-45px',
    config: {
      duration: 250
    }
  })

  const navigate = useNavigate()
  const location = useLocation()

  const openPricingOptionsModal = () => {
    navigate('/pricing-options', { state: location })
  }

  return (
    <PageBanner {...{ style, primaryColor: colors.karlLight1 }}>
      <Title>
        Trial <StyledSeparator /> &nbsp;&nbsp;
        <span>Showing sandbox business data </span>
      </Title>
      <div>
        <StyledLink to='/settings/credentials'>
          View your test API Key
        </StyledLink>
        <Button type='secondary' onClick={() => openPricingOptionsModal()}>
          Upgrade Account
        </Button>
      </div>
    </PageBanner>
  )
}

export default TrialBanner
