import React from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { invalidateSession } from 'redux-simple-auth'

import { useSandboxMode } from 'containers/SandboxProvider'

const Signout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { toggleSandboxMode } = useSandboxMode()

  toggleSandboxMode(false)
  dispatch(invalidateSession()).finally(() => navigate('/'))

  return <></>
}

export default Signout
