import React from 'react'

import { useSelector } from 'react-redux'
import { getIsAuthenticated } from 'redux-simple-auth'

import { RootState } from 'types'

type Props = {
  children?: React.ReactNode
}

const Authenticated = ({ children }: Props) => {
  const isAuthenticated = useSelector(s => getIsAuthenticated(s))
  const currentUser = useSelector((state: RootState) => state.currentUser)

  if (isAuthenticated && currentUser.id) {
    return <>{children}</>
  }

  return <div />
}

export default Authenticated
