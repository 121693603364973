import React, { useEffect } from 'react'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'
import {
  getIsAuthenticated,
  getIsRestored,
  getSessionData
} from 'redux-simple-auth'

import { fetchCurrentUser } from 'actions'
import { lienFilingSettingEnabled } from 'containers/LienFiling/utils'

const TRIAL_ONLY_ROUTES = Object.freeze([
  '/contact-sales',
  '/purchase-plan',
  '/pricing-options'
])

const PrivateRoute = ({ children, roles = [], features = [] }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const isRestored = useSelector(getIsRestored)
  const session = useSelector(getSessionData)
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated || !isRestored) {
      return
    }

    if (!currentUser.hasError && !currentUser.isFetching && !currentUser.id) {
      dispatch(fetchCurrentUser())
    }
  }, [
    currentUser.hasError,
    currentUser.isFetching,
    currentUser.id,
    dispatch,
    isAuthenticated,
    isRestored
  ])

  if (!isRestored) return <div />

  const state = location && location.pathname !== '/signout' ? location : {}

  if (isAuthenticated && currentUser) {
    if (!isEmpty(roles)) {
      const allowed = (get(session, 'user.roles') || []).some(role => {
        return role === 'super_admin' || roles.includes(role)
      })

      if (!allowed) {
        return <Navigate to='/' state={state} replace />
      }
    }

    if (!currentUser.id) {
      return <div />
    }

    const {
      account: { level, features: accountFeatures }
    } = currentUser

    const lienFilingEnabled = lienFilingSettingEnabled(undefined, currentUser)

    if (!isEmpty(features)) {
      const on =
        features.every(feature => {
          return accountFeatures[feature]
        }) ||
        (lienFilingEnabled && features.includes('lien_filing'))

      if (!on) {
        return <Navigate to='/' state={state} replace />
      }
    }

    const { pathname } = state

    if (TRIAL_ONLY_ROUTES.includes(pathname) && level !== 'trial') {
      return <Navigate to='/' state={state} replace />
    }

    return children
  } else {
    const { pathname } = state

    return (
      <Navigate
        to={{
          pathname: '/signin',
          search: pathname === '/' ? '' : `?redirect_uri=${pathname}`
        }}
        state={state}
        replace
      />
    )
  }
}

PrivateRoute.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.array,
  features: PropTypes.array
}

export default PrivateRoute
