import { theme } from '@middesk/components'
import styled from 'styled-components'
const { colors } = theme

const Separator = styled.div`
  background-color: ${colors.frost};
  border-radius: 50%;
  display: inline-flex;
  height: 5px;
  width: 5px;
`

export const AdjustedSeparator = styled(Separator)`
  position: relative;
  top: 2px;
`

export default Separator
