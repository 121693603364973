import { Env } from 'env'

const init = () => {
  if (!isSegmentEnabled) return

  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || [])

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    // eslint-disable-next-line no-console
    if (window.console && console.error) {
      // eslint-disable-next-line no-console
      console.error('Segment snippet included twice.')
    }

    return
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'addDestinationMiddleware',
    'addIntegrationMiddleware',
    'addSourceMiddleware',
    'alias',
    'debug',
    'group',
    'identify',
    'off',
    'on',
    'once',
    'page',
    'pageview',
    'ready',
    'reset',
    'setAnonymousId',
    'track',
    'trackClick',
    'trackForm',
    'trackLink',
    'trackSubmit'
  ]

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments)

      args.unshift(method)
      analytics.push(args)

      return analytics
    }
  }

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i]

    analytics[key] = analytics.factory(key)
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.async = true
    script.src =
      'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0]

    first.parentNode.insertBefore(script, first)
    analytics._loadOptions = options
  }

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.1.0'

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY)
}

export const EVENTS = Object.freeze({
  accountUpgraded: 'account-upgraded',
  autoApprovalDisabled: 'auto-approval-disabled',
  autoApprovalEnabled: 'auto-approval-enabled',
  autoApprovalSettingSwitchClicked: 'auto-approval-setting-switch-clicked',
  autoApprovalSettingEditClicked: 'auto-approval-setting-edit-clicked',
  autoApprovalSettingSaved: 'auto-approval-setting-saved',
  autoApprovalSettingCancelClicked: 'auto-approval-setting-cancel-clicked',
  watchlistDisabled: 'watchlist-disabled',
  watchlistEnabled: 'watchlist-enabled',
  watchlistSettingSwitchClicked: 'watchlist-setting-switch-clicked',
  watchlistSettingSaved: 'watchlist-setting-saved',
  autoApprovalPillClicked: 'auto-approval-pill-clicked',
  businessBatchCreated: 'business-batch-created',
  businessClicked: 'business-clicked',
  businessCreated: 'business-created',
  businessReviewed: 'business-reviewed',
  changePasswordClicked: 'change-password-clicked',
  contactSalesClicked: 'contact_sales_clicked',
  currentUserFetched: 'current-user-fetched',
  documentsOrdered: 'documents-ordered',
  downloadBusinessesCSVCompleted: 'download-businesses-csv-completed',
  downloadBusinessesCSVOverLimit: 'download-businesses-csv-over-limit',
  downloadBusinessesCSVStarted: 'download-businesses-csv-started',
  liensOrdered: 'liens-ordered',
  litigationsOrdered: 'litigations-ordered',
  existingBusinessOrderPackagedNextClicked:
    'existing-business-order-packages-next-clicked',
  newOrderPackagesNextClicked: 'new-order-packages-next-clicked',
  newOrderBusinessInformationNextClicked:
    'new-order-business-information-next-clicked',
  placeOrderClicked: 'place-order-clicked',
  placeOrderClickNewBusinessClicked: 'place-order-new-business-clicked',
  placeOrderExistingBusinessClicked: 'place-order-existing-business-clicked',
  subscriptionUpdated: 'subscription-updated',
  updateProfileClicked: 'update-profile-clicked',
  upgradeClicked: 'upgrade-clicked',
  userCreated: 'user-created',
  userSignedIn: 'user-signed-in',
  welcomeModalCompleted: 'welcome-modal-completed',
  existingBusinessOrderPlaced: 'existing-business-order-placed',
  businessSelected: 'business-selected',
  selectedBusinessCleared: 'selected-business-cleared',
  placeOrderCanceled: 'place-order-canceled',
  placeOrderNextClicked: 'place-order-next-clicked',
  placeOrderBackClicked: 'place-order-back-clicked',
  registrationRequestModalOpened: 'registration-request-modal-opened'
})

export const isSegmentEnabled = Env.isProduction()

export const track = (...args) => {
  if (!isSegmentEnabled) return

  window.analytics.track(...args)
}

export const page = () => {
  if (!isSegmentEnabled) return

  window.analytics.page()
}

export default { init }
