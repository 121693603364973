import get from 'lodash/get'
import moment from 'moment'
import { actionTypes, reducer as session } from 'redux-simple-auth'

import {
  ADD_RECORD,
  CLEAR_FILTERED_BUSINESS_CSV,
  CLOSE_MODAL,
  CREATE_BUSINESS_FAILURE,
  CREATE_BUSINESS_REQUEST,
  CREATE_BUSINESS_SUCCESS,
  CREATE_COMPANY_FAILURE,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_ORDER_FAILURE,
  CREATE_PARTNER_ACCOUNT_USER_FAILURE,
  CREATE_PARTNER_ACCOUNT_USER_REQUEST,
  CREATE_PARTNER_ACCOUNT_USER_SUCCESS,
  CREATE_POLICY_FAILURE,
  CREATE_POLICY_REQUEST,
  CREATE_POLICY_SUCCESS,
  CREATE_RECORD_SUCCESS,
  CURRENT_USER_FAILURE,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  EDIT_BUSINESSES_FAILURE,
  EDIT_BUSINESSES_REQUEST,
  EDIT_BUSINESSES_SUCCESS,
  ENTER_FORM_STAGE,
  FETCH_AGENCIES_FAILURE,
  FETCH_AGENCIES_REQUEST,
  FETCH_AGENCIES_SUCCESS,
  FETCH_AGENT_COMPANY_FAILURE,
  FETCH_AGENT_COMPANY_REQUEST,
  FETCH_AGENT_COMPANY_SUCCESS,
  FETCH_AGENT_TAR_EXPORT_FAILURE,
  FETCH_AGENT_TAR_EXPORT_REQUEST,
  FETCH_AGENT_TAR_EXPORT_SUCCESS,
  FETCH_API_KEYS_FAILURE,
  FETCH_API_KEYS_SUCCESS,
  FETCH_API_LOG_FAILURE,
  FETCH_API_LOG_REQUEST,
  FETCH_API_LOG_SUCCESS,
  FETCH_API_LOGS_FAILURE,
  FETCH_API_LOGS_REQUEST,
  FETCH_API_LOGS_SUCCESS,
  FETCH_AUDITS_REQUEST,
  FETCH_AUDITS_SUCCESS,
  FETCH_BATCH_CSV_FAILURE,
  FETCH_BATCH_CSV_REQUEST,
  FETCH_BATCH_CSV_SUCCESS,
  FETCH_BATCH_EXAMPLE_FILE_FAILURE,
  FETCH_BATCH_EXAMPLE_FILE_REQUEST,
  FETCH_BATCH_EXAMPLE_FILE_SUCCESS,
  FETCH_BATCH_FAILURE,
  FETCH_BATCH_REQUEST,
  FETCH_BATCH_SUCCESS,
  FETCH_BULK_ORDER_EXAMPLE_FILE_FAILURE,
  FETCH_BULK_ORDER_EXAMPLE_FILE_REQUEST,
  FETCH_BULK_ORDER_EXAMPLE_FILE_SUCCESS,
  FETCH_BUSINESS_FAILURE,
  FETCH_BUSINESS_REQ_SUCCESS,
  FETCH_BUSINESS_REQUEST,
  FETCH_BUSINESS_REVIEW_FAILURE,
  FETCH_BUSINESS_REVIEW_REQUEST,
  FETCH_BUSINESS_REVIEW_SUCCESS,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESS_CLEAR,
  FETCH_BUSINESSES_FAILURE,
  FETCH_BUSINESSES_REQUEST,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANY_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_EVENT_FAILURE,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_FILTERED_BUSINESSES_FAILURE,
  FETCH_FILTERED_BUSINESSES_REQUEST,
  FETCH_FILTERED_BUSINESSES_SUCCESS,
  FETCH_FORM_FAILURE,
  FETCH_FORM_REQUEST,
  FETCH_FORM_SUCCESS,
  FETCH_INSIGHT_KEYS_FAILURE,
  FETCH_INSIGHT_KEYS_REQUEST,
  FETCH_INSIGHT_KEYS_SUCCESS,
  FETCH_KYC_REASON_CODES_FAILURE,
  FETCH_KYC_REASON_CODES_REQUEST,
  FETCH_KYC_REASON_CODES_SUCCESS,
  FETCH_MONITORING_EVENTS_FAILURE,
  FETCH_MONITORING_EVENTS_REQUEST,
  FETCH_MONITORING_EVENTS_SUCCESS,
  FETCH_MONITORING_MONITOR_FAILURE,
  FETCH_MONITORING_MONITOR_REQUEST,
  FETCH_MONITORING_MONITOR_SUCCESS,
  FETCH_EMPLOYER_COMMS_FAILURE,
  FETCH_EMPLOYER_COMMS_REQUEST,
  FETCH_EMPLOYER_COMMS_SUCCESS,
  FETCH_PARTNER_ACCOUNT_USERS_FAILURE,
  FETCH_PARTNER_ACCOUNT_USERS_REQUEST,
  FETCH_PARTNER_ACCOUNT_USERS_SUCCESS,
  FETCH_PARTNER_INSIGHTS_FAILURE,
  FETCH_PARTNER_INSIGHTS_REQUEST,
  FETCH_PARTNER_INSIGHTS_SUCCESS,
  FETCH_PDF_FAILURE,
  FETCH_PDF_REQUEST,
  FETCH_PDF_SUCCESS,
  FETCH_CURRENT_POLICY_FAILURE,
  FETCH_CURRENT_POLICY_REQUEST,
  FETCH_POLICY_RESULTS_FAILURE,
  FETCH_POLICY_RESULTS_REQUEST,
  FETCH_POLICY_RESULTS_SUCCESS,
  FETCH_CURRENT_POLICY_SUCCESS,
  FETCH_POLICIES_FAILURE,
  FETCH_POLICIES_SUCCESS,
  FETCH_POLICIES_REQUEST,
  FETCH_POLICY_FAILURE,
  FETCH_POLICY_SUCCESS,
  FETCH_POLICY_REQUEST,
  FETCH_SETTINGS_FAILURE,
  FETCH_SETTINGS_REQUEST,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SIGNALS_BATCH_EXAMPLE_REQUEST,
  FETCH_SIGNALS_BATCH_EXAMPLE_SUCCESS,
  FETCH_SIGNALS_BATCH_EXAMPLE_FAILURE,
  FETCH_TASKS,
  FETCH_TAX_REGISTRATION_FAILURE,
  FETCH_TAX_REGISTRATION_REQUEST,
  FETCH_TAX_REGISTRATION_SUCCESS,
  FETCH_TAX_REGISTRATIONS_FAILURE,
  FETCH_TAX_REGISTRATIONS_REQUEST,
  FETCH_TAX_REGISTRATIONS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_WATCHLIST_SOURCES_CSV_FAILURE,
  FETCH_WATCHLIST_SOURCES_CSV_REQUEST,
  FETCH_WATCHLIST_SOURCES_CSV_SUCCESS,
  FETCH_WEBHOOK_FAILURE,
  FETCH_WEBHOOK_LOG_FAILURE,
  FETCH_WEBHOOK_LOG_REQUEST,
  FETCH_WEBHOOK_LOG_SUCCESS,
  FETCH_WEBHOOK_LOGS_FAILURE,
  FETCH_WEBHOOK_LOGS_REQUEST,
  FETCH_WEBHOOK_LOGS_SUCCESS,
  FETCH_WEBHOOK_OBSERVABLE_EVENTS_FAILURE,
  FETCH_WEBHOOK_OBSERVABLE_EVENTS_REQUEST,
  FETCH_WEBHOOK_OBSERVABLE_EVENTS_SUCCESS,
  FETCH_WEBHOOK_REQUEST,
  FETCH_WEBHOOK_SUCCESS,
  FETCH_WEBSITE_FAILURE,
  FETCH_WEBSITE_REQUEST,
  FETCH_WEBSITE_SUCCESS,
  INVITE_USERS_CLEAR,
  INVITE_USERS_FAILURE,
  INVITE_USERS_REQUEST,
  INVITE_USERS_SUCCESS,
  OPEN_MODAL,
  REFRESH_BUSINESS_BATCH_FAILURE,
  REFRESH_BUSINESS_BATCH_REQUEST,
  REFRESH_BUSINESS_BATCH_SUCCESS,
  REFRESH_BUSINESS_FAILURE,
  REFRESH_BUSINESS_REQUEST,
  REFRESH_BUSINESS_SUCCESS,
  REINVITE_PARTNER_ACCOUNT_USER_FAILURE,
  REINVITE_PARTNER_ACCOUNT_USER_REQUEST,
  REINVITE_PARTNER_ACCOUNT_USER_SUCCESS,
  REMOVE_RECORD,
  SETUP_INTENT_SUCCESS,
  SORT_REGISTRATIONS,
  SUBMIT_FORM_FAILURE,
  SUBMIT_FORM_REQUEST,
  SUBMIT_FORM_SUCCESS,
  MONITOR_SUCCESS,
  FETCH_REFERRAL_LINK_REQUEST,
  FETCH_REFERRAL_LINK_SUCCESS,
  FETCH_REFERRAL_LINK_FAILURE,
  CREATE_REFERRAL_LINK_REQUEST,
  CREATE_REFERRAL_LINK_SUCCESS,
  CREATE_REFERRAL_LINK_FAILURE,
  TOGGLE_SANDBOX_MODE,
  TRIGGER_EVENT_FAILURE,
  TRIGGER_EVENT_REQUEST,
  TRIGGER_EVENT_SUCCESS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_FAILURE,
  UPDATE_BUSINESS_RECORD,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_SUCCESS,
  UPDATE_EMPLOYER_MAIL_FAILURE,
  UPDATE_EMPLOYER_MAIL_REQUEST,
  UPDATE_EMPLOYER_MAIL_SUCCESS,
  FORWARD_EMPLOYER_EMAIL_FAILURE,
  FORWARD_EMPLOYER_EMAIL_REQUEST,
  FORWARD_EMPLOYER_EMAIL_SUCCESS,
  CLEAR_FORWARD_EMPLOYER_EMAIL_REQUEST,
  UPDATE_POLICY_FAILURE,
  UPDATE_POLICY_REQUEST,
  UPDATE_POLICY_SUCCESS,
  UPDATE_RECORD_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPGRADE_ACCOUNT_SUCCESS,
  FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_SUCCESS,
  FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_FAILURE,
  FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_REQUEST,
  FETCH_PARTNER_COMPANY_PASSCODE_REQUEST,
  FETCH_PARTNER_COMPANY_PASSCODE_SUCCESS,
  FETCH_PARTNER_COMPANY_PASSCODE_FAILURE,
  FETCH_PARTNER_EXCEPTIONS_REQUEST,
  FETCH_PARTNER_EXCEPTIONS_REQUEST_SUCCESS,
  FETCH_PARTNER_EXCEPTIONS_REQUEST_FAILURE,
  FETCH_PARTNER_COMPANIES_REQUEST,
  FETCH_PARTNER_COMPANIES_REQUEST_SUCCESS,
  FETCH_PARTNER_COMPANIES_REQUEST_FAILURE,
  FETCH_PARTNER_COMMS_REQUEST,
  FETCH_PARTNER_COMMS_REQUEST_SUCCESS,
  FETCH_PARTNER_COMMS_REQUEST_FAILURE,
  FETCH_INSIGHT_KEY_STATUSES_REQUEST,
  FETCH_INSIGHT_KEY_STATUSES_SUCCESS,
  FETCH_INSIGHT_KEY_STATUSES_FAILURE,
  FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST,
  FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS,
  FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE,
  FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST,
  FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_SUCCESS,
  FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_FAILURE,
  FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST,
  FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS,
  FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE,
  FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST,
  FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_SUCCESS,
  FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_FAILURE,
  FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST,
  FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_SUCCESS,
  FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_FAILURE,
  FETCH_COMPANY_CLEAR,
  FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST,
  FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_SUCCESS,
  FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_FAILURE,
  UPDATE_SECURED_PARTY_DETAILS_REQUEST,
  UPDATE_SECURED_PARTY_DETAILS_SUCCESS,
  UPDATE_SECURED_PARTY_DETAILS_FAILURE,
  FETCH_PARTNER_ANALYTICS_REQUEST,
  FETCH_PARTNER_ANALYTICS_REQUEST_SUCCESS,
  FETCH_PARTNER_ANALYTICS_REQUEST_FAILURE,
  FETCH_FILTERED_BUSINESSES_ENQUEUED_SUCCESS,
  FETCH_FILE_EXPORT_FILE_FAILURE,
  FETCH_FILE_EXPORT_FILE_REQUEST,
  FETCH_FILE_EXPORT_FILE_SUCCESS,
  FETCH_BATCH_CSV_ENQUEUED_SUCCESS,
  FETCH_AFFILIATE_REFERRAL_REQUEST,
  FETCH_AFFILIATE_REFERRAL_SUCCESS,
  FETCH_AFFILIATE_REFERRAL_FAILURE,
  FETCH_AFFILIATE_ACCOUNTS_REQUEST,
  FETCH_AFFILIATE_ACCOUNTS_SUCCESS,
  FETCH_AFFILIATE_ACCOUNTS_FAILURE,
  FETCH_AFFILIATE_USERS_REQUEST,
  FETCH_AFFILIATE_USERS_SUCCESS,
  FETCH_AFFILIATE_USERS_FAILURE,
  FETCH_AFFILIATE_PROFILES_REQUEST,
  FETCH_AFFILIATE_PROFILES_SUCCESS,
  FETCH_AFFILIATE_PROFILES_FAILURE,
  FETCH_AVAILABLE_JURISDICTIONS_REQUEST,
  FETCH_AVAILABLE_JURISDICTIONS_SUCCESS,
  FETCH_AVAILABLE_JURISDICTIONS_FAILURE
} from 'actions'
import { BATCH_EXAMPLE_FILE } from 'constants/constants'
import { groupByState } from 'containers/BusinessHome/Tasks/utils'
import { localStore } from 'utils/storage'

function modal(state = {}, props) {
  const { type } = props

  switch (type) {
    case OPEN_MODAL:
      return Object.assign({}, state, {
        ...props
      })
    case CLOSE_MODAL:
      return Object.assign({}, state, {
        modal: null
      })
    default:
      return state
  }
}

function editBusinesses(state = {}, { type }) {
  switch (type) {
    case EDIT_BUSINESSES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case EDIT_BUSINESSES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false
      })
    case EDIT_BUSINESSES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function setupIntent(state = {}, { type, data }) {
  switch (type) {
    case SETUP_INTENT_SUCCESS:
      return Object.assign({}, state, {
        stripeClientSecret: data.client_secret
      })
    default:
      return state
  }
}

function upgrade(state = {}, { type }) {
  switch (type) {
    case UPGRADE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        complete: true
      })
    default:
      return state
  }
}

function business(
  state = {
    registrationSortBy: 'jurisdiction',
    registrationSortOrder: 'asc',
    isDirty: false,
    isUpdating: false
  },
  props
) {
  const { id, status, type, isSnapshot = false, errors } = props
  let { data } = props
  let { registrationSortBy, registrationSortOrder, isDirty, isUpdating } = state

  switch (type) {
    case CREATE_BUSINESS_REQUEST:
    case FETCH_BUSINESS_REQUEST:
      if (state.data && state.data.id !== id) {
        return { isFetching: true }
      }

      return Object.assign({}, state, {
        isFetching: true
      })
    case REFRESH_BUSINESS_REQUEST:
      return Object.assign({}, state, {
        isRefreshing: true
      })
    case CREATE_BUSINESS_FAILURE:
    case FETCH_BUSINESS_FAILURE:
      return Object.assign({}, state, {
        errors,
        isFetching: false,
        isRefreshing: false,
        isNotFound: status === 404,
        isSnapshot
      })
    case REFRESH_BUSINESS_FAILURE:
    case CREATE_ORDER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isRefreshing: false,
        isSnapshot
      })
    case ADD_RECORD:
    case UPDATE_BUSINESS_RECORD:
      data = state.data
      isDirty = true

      if (data && props.values && props.values.type === 'registration') {
        data.registrations.push(props.values)
      }

      break
    case REMOVE_RECORD:
      data = state.data

      if (data && props.values && props.values.type === 'registration') {
        data.registrations = data.registrations.filter(({ id }) => !!id)
      }

      isDirty = false
      break
    case CREATE_RECORD_SUCCESS:
    case UPDATE_RECORD_SUCCESS:
      isDirty = false

      return Object.assign({ ...state }, { isDirty })
    case CREATE_BUSINESS_SUCCESS:
    case FETCH_BUSINESS_SUCCESS:
    case REFRESH_BUSINESS_SUCCESS:
      if (isDirty) {
        return state
      }

      break
    case FETCH_BUSINESS_REQ_SUCCESS:
      state.requests = data.data

      return state
    case SORT_REGISTRATIONS:
      registrationSortBy = props.value
      registrationSortOrder = props.order

      data = get(state, 'data')

      break
    case UPDATE_BUSINESS:
      return Object.assign({}, state, {
        isUpdating: true
      })
    case UPDATE_BUSINESS_FAILURE:
    case UPDATE_BUSINESS_SUCCESS:
      isUpdating = false

      break
    case MONITOR_SUCCESS:
      data = state.data

      if (data && props.subscription) {
        data.subscription = props.subscription
      }
      if (data && props.monitor) {
        data.monitor = props.monitor
      }
      break
    case FETCH_BUSINESS_CLEAR:
      return {}
    default:
      return state
  }

  if (data) {
    if (data.website && data.website.url) {
      let href

      try {
        href = new URL(data.website.url).toString()
      } catch {
        href = new URL(`http://${data.website.url}`).toString()
      } finally {
        data.website.href = href || data.website.url
      }
    }

    if (data.liens) {
      const [lienStatuses] = data.liens.reduce(
        (result, lien) => {
          const [statusMap] = result

          if (lien.status_category in statusMap)
            statusMap[lien.status_category].push(lien)

          return result
        },
        [{ open: [], closed: [], unknown: [], processing: [] }]
      )

      data.lienStatuses = lienStatuses
    }

    if (data.registrations) {
      const sortValue = registrationSortOrder === 'asc' ? 1 : -1

      data.sosfilings = groupByState(data.registrations)

      data.registrations = data.registrations.sort((a, b) =>
        a[registrationSortBy] > b[registrationSortBy] ? sortValue : -sortValue
      )

      data.registration = data.registrations.reduce((memo, record) => {
        const state = record.state
        let group = memo[state]

        if (!group) {
          group = memo[state] = { state, records: [] }
        }

        group.records.push(record)

        /**
         * If any record is active, mark the state active.
         * Else if any record is unknown, mark the state unknown.
         * Else if any record is inactive, mark the state inactive.
         */
        switch (record.status) {
          case 'active':
            group.status = record.status

            break
          case 'unknown':
            if (group.status !== 'active') {
              group.status = record.status
            }

            break
          case 'inactive':
            if (group.status !== 'active' && group.status !== 'unknown') {
              group.status = record.status
            }

            break
        }

        return memo
      }, {})
    }

    if (data.documents) {
      data.registration_documents = data.documents.filter(
        document => document.source.type === 'registration'
      )
    } else {
      data.registration_documents = []
    }

    if (data.middesk_registrations) {
      data.middesk_registration = data.middesk_registrations.reduce(
        (memo, record) => {
          const state = record.state
          let group = memo[state]

          if (!group) {
            group = memo[state] = { state, records: [] }
          }

          group.records.push(record)

          return memo
        },
        {}
      )
    } else {
      data.middesk_registration = {}
    }

    if (data.subscription) {
      data.subscription.monitor_types = data.subscription.event_types.map(
        event_type => {
          if ('watchlist_result.created' === event_type.type) {
            return {
              ...event_type,
              type: 'watchlist_result'
            }
          } else if ('bankruptcy.created' === event_type.type) {
            return {
              ...event_type,
              type: 'bankruptcy'
            }
          } else if ('tin.retrieved' === event_type.type) {
            return {
              ...event_type,
              type: 'tin'
            }
          } else {
            return event_type
          }
        }
      )
    }

    if (data.monitor) {
      data.monitor.monitor_types = data.monitor.event_types.map(event_type => {
        if ('watchlist_result.created' === event_type.type) {
          return {
            ...event_type,
            type: 'watchlist_result'
          }
        } else if ('bankruptcy.created' === event_type.type) {
          return {
            ...event_type,
            type: 'bankruptcy'
          }
        } else if ('tin.retrieved' === event_type.type) {
          return {
            ...event_type,
            type: 'tin'
          }
        } else {
          return event_type
        }
      })
    }

    // Set status to completed when data is a snapshot
    // to avoid incorrectly rendering loading states
    if (isSnapshot) {
      data.status = 'completed'
    }
  }

  return Object.assign(
    { ...state },
    {
      data,
      isDirty,
      isFetching: false,
      isRefreshing: false,
      isSnapshot,
      isUpdating,
      registrationSortBy,
      registrationSortOrder
    }
  )
}

function website(state = {}, props) {
  const { id, type, data } = props

  switch (type) {
    case FETCH_WEBSITE_REQUEST:
      if (state.data && state.data.id !== id) {
        return { isFetching: true }
      }

      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_WEBSITE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    case FETCH_WEBSITE_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    default:
      return state
  }
}

function businesses(state = {}, props) {
  const { type, data, total, perPage, page } = props

  switch (type) {
    case FETCH_BUSINESSES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_BUSINESSES_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false
      })
    case FETCH_BUSINESSES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function agencies(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case FETCH_AGENCIES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_AGENCIES_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_AGENCIES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function referralLink(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case FETCH_REFERRAL_LINK_REQUEST:
      return Object.assign({}, state, { isFetching: true })
    case FETCH_REFERRAL_LINK_SUCCESS:
      return Object.assign({}, state, { link: data.link, isFetching: false })
    case FETCH_REFERRAL_LINK_FAILURE:
      return Object.assign({}, state, { isFetching: false })
    case CREATE_REFERRAL_LINK_REQUEST:
      return Object.assign({}, state, { isCreating: true })
    case CREATE_REFERRAL_LINK_SUCCESS:
      return Object.assign({}, state, { link: data.link, isCreating: false })
    case CREATE_REFERRAL_LINK_FAILURE:
      return Object.assign({}, state, { isCreating: false })
    default:
      return state
  }
}

function taxRegistrations(state = {}, props) {
  const { type, data, total, perPage, page } = props

  switch (type) {
    case FETCH_TAX_REGISTRATIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_TAX_REGISTRATIONS_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false
      })
    case FETCH_TAX_REGISTRATIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function partnerInsights(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case FETCH_PARTNER_INSIGHTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_PARTNER_INSIGHTS_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_PARTNER_INSIGHTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

/* BEGIN TO DEPRECATE */
function companies(state = {}, props) {
  const { type, data, total, perPage, page } = props

  switch (type) {
    case FETCH_COMPANIES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_COMPANIES_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false
      })
    case FETCH_COMPANIES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}
/* END TO DEPRECATE */

function tarExport(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case FETCH_AGENT_TAR_EXPORT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_AGENT_TAR_EXPORT_SUCCESS:
      downloadToBrowser(data, `tax_ids_${moment().format('YYYY-MM-DD')}.csv`)

      return {
        ...state,
        isFetching: false
      }
    case FETCH_AGENT_TAR_EXPORT_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function taxRegistration(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case FETCH_TAX_REGISTRATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_TAX_REGISTRATION_SUCCESS:
      return Object.assign({}, state, {
        data
      })
    case FETCH_TAX_REGISTRATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    case FETCH_COMPANY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    case FETCH_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: {
          ...state.data,
          company: data
        }
      })
    default:
      return state
  }
}

function company(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case FETCH_AGENT_COMPANY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_AGENT_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_AGENT_COMPANY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    case UPDATE_PARTNER_ACCOUNT_APPLICATION_INVITATION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data
      })
    case FETCH_COMPANY_CLEAR:
      return {}
    default:
      return state
  }
}

const webhook_log = (state = {}, { type, data }) => {
  switch (type) {
    case FETCH_WEBHOOK_LOG_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_WEBHOOK_LOG_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_WEBHOOK_LOG_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function createCompany(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case CREATE_COMPANY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case CREATE_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case CREATE_COMPANY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

const currentUser = (state = {}, { type, data }) => {
  switch (type) {
    case CURRENT_USER_REQUEST:
      return { hasError: false, isFetching: true, ...state }
    case CURRENT_USER_SUCCESS:
      return { isFetching: false, ...data }
    case UPDATE_CURRENT_USER_SUCCESS:
      return { isFetching: false, ...data }
    case CURRENT_USER_FAILURE:
      return { hasError: true }
    case actionTypes.INVALIDATE_SESSION:
      return {}
    default:
      return state
  }
}

function documents(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case FETCH_DOCUMENTS_SUCCESS:
      return Object.assign({}, state, {
        data
      })
    default:
      return state
  }
}

function users(state = {}, { type, data, total }) {
  switch (type) {
    case FETCH_USERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_USERS_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        isFetching: false
      })
    case FETCH_USERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function u(state = {}, { type, data, payload }) {
  switch (type) {
    case FETCH_FORM_REQUEST:
    case SUBMIT_FORM_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_FORM_SUCCESS:
    case SUBMIT_FORM_SUCCESS:
      return Object.assign({}, state, {
        form: data,
        isFetching: false
      })
    case FETCH_FORM_FAILURE:
    case SUBMIT_FORM_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    case ENTER_FORM_STAGE:
      return Object.assign({}, state, {
        stage: payload
      })
    default:
      return state
  }
}

function review(state = {}, props) {
  const { id, type, data } = props

  switch (type) {
    case FETCH_BUSINESS_REVIEW_REQUEST:
      if (state.data && state.data.id !== id) {
        return { isFetching: true }
      }

      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_BUSINESS_REVIEW_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_BUSINESS_REVIEW_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function monitoringMonitors(state = {}, { type, data, total, perPage, page }) {
  switch (type) {
    case FETCH_MONITORING_MONITOR_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_MONITORING_MONITOR_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false
      })
    case FETCH_MONITORING_MONITOR_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function monitoringEvents(state = {}, { type, data, total, perPage, page }) {
  switch (type) {
    case FETCH_MONITORING_EVENTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_MONITORING_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false
      })
    case FETCH_MONITORING_EVENTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function events(state = {}, { type, data, total, perPage, page }) {
  switch (type) {
    case FETCH_EVENTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false
      })
    case FETCH_EVENTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function event(state = {}, { type, data }) {
  switch (type) {
    case FETCH_EVENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_EVENT_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_EVENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function webhookObservableEvents(state = {}, { type, data }) {
  switch (type) {
    case FETCH_WEBHOOK_OBSERVABLE_EVENTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_WEBHOOK_OBSERVABLE_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_WEBHOOK_OBSERVABLE_EVENTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function webhook(state = {}, { type, data }) {
  switch (type) {
    case FETCH_WEBHOOK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_WEBHOOK_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_WEBHOOK_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function triggerEventAction(state = {}, { type }) {
  switch (type) {
    case TRIGGER_EVENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case TRIGGER_EVENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false
      })
    case TRIGGER_EVENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function webhook_logs(state = {}, { type, data, total, perPage, page }) {
  switch (type) {
    case FETCH_WEBHOOK_LOGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_WEBHOOK_LOGS_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false
      })
    case FETCH_WEBHOOK_LOGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function api_logs(state = {}, { type, data, total, perPage, page }) {
  switch (type) {
    case FETCH_API_LOGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_API_LOGS_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false
      })
    case FETCH_API_LOGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function api_log(state = {}, { type, data }) {
  switch (type) {
    case FETCH_API_LOG_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_API_LOG_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_API_LOG_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function pdf(state = {}, { type, id, blob }) {
  switch (type) {
    case FETCH_PDF_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PDF_SUCCESS:
      downloadToBrowser(blob, `${id}.pdf`)

      return {
        ...state,
        isFetching: false
      }
    case FETCH_PDF_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function businessBatches(state = {}, { type, data, total, perPage, page }) {
  switch (type) {
    case FETCH_BATCH_REQUEST:
    case REFRESH_BUSINESS_BATCH_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isRefreshing: true
      })

    case FETCH_BATCH_SUCCESS:
    case REFRESH_BUSINESS_BATCH_SUCCESS:
      return Object.assign({}, state, {
        data,
        total,
        perPage,
        page,
        isFetching: false,
        isRefreshing: false
      })
    case FETCH_BATCH_FAILURE:
    case REFRESH_BUSINESS_BATCH_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isRefreshing: false
      })
    default:
      return state
  }
}

function batchExampleCSV(state = {}, { type, blob }) {
  switch (type) {
    case FETCH_BATCH_EXAMPLE_FILE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_BATCH_EXAMPLE_FILE_SUCCESS:
      downloadToBrowser(blob, BATCH_EXAMPLE_FILE)

      return {
        ...state,
        isFetching: false
      }
    case FETCH_BATCH_EXAMPLE_FILE_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function audits(state = { isFetching: true }, { type, data }) {
  switch (type) {
    case FETCH_AUDITS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_AUDITS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        data,
        isFetching: false
      })

    default:
      return state
  }
}

function downloadToBrowser(blob, filename) {
  const href = window.URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.href = href
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.remove()
}

function tasks(state = {}, props) {
  const { type, data } = props

  switch (type) {
    case FETCH_TASKS:
      return Object.assign({}, state, {
        data
      })
    default:
      return state
  }
}

function invite_users(state = {}, { type, data, errors }) {
  switch (type) {
    case INVITE_USERS_CLEAR:
      return Object.assign({}, state, {
        closeModal: false,
        errors: null
      })
    case INVITE_USERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case INVITE_USERS_SUCCESS:
      return Object.assign({}, state, {
        data,
        closeModal: true,
        isFetching: false
      })
    case INVITE_USERS_FAILURE:
      return Object.assign({}, state, {
        errors,
        closeModal: false,
        isFetching: false
      })
    default:
      return state
  }
}

function api_keys(state = {}, { type, data }) {
  switch (type) {
    case FETCH_API_KEYS_SUCCESS:
    case FETCH_API_KEYS_FAILURE:
      return Object.assign({}, state, {
        data
      })
    default:
      return state
  }
}

function sandbox(state = {}, { type, sandboxMode, data }) {
  switch (type) {
    case CURRENT_USER_SUCCESS:
      return Object.assign({}, state, {
        sandboxMode:
          data.account?.level === 'trial'
            ? true
            : localStore.getItem('sandbox_mode') === 'true' || state.sandboxMode
      })
    case TOGGLE_SANDBOX_MODE:
      localStore.setItem('sandbox_mode', sandboxMode)

      return Object.assign({}, state, {
        sandboxMode
      })
    default:
      return state
  }
}

function batchCSV(state = {}, { type, id, blob }) {
  switch (type) {
    case FETCH_BATCH_CSV_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_BATCH_CSV_SUCCESS:
      downloadToBrowser(blob, `batch-${id}`)

      return {
        ...state,
        isFetching: false,
        enqueued: false
      }
    case FETCH_BATCH_CSV_ENQUEUED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        enqueued: true
      }
    case FETCH_BATCH_CSV_FAILURE:
      return {
        ...state,
        isFetching: false,
        enqueued: false
      }
    default:
      return state
  }
}

function settings(state = {}, { type, data }) {
  switch (type) {
    case FETCH_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    case UPDATE_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case UPDATE_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case UPDATE_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function securedPartyDetails(state = {}, { type, data }) {
  switch (type) {
    case UPDATE_SECURED_PARTY_DETAILS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case UPDATE_SECURED_PARTY_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case UPDATE_SECURED_PARTY_DETAILS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}

function insightKeys(state = {}, { type, data }) {
  switch (type) {
    case FETCH_INSIGHT_KEYS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_INSIGHT_KEYS_SUCCESS:
      return {
        ...state,
        data,
        isFetching: false
      }
    case FETCH_INSIGHT_KEYS_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function insightKeyStatusMappings(state = {}, { type, data }) {
  switch (type) {
    case FETCH_INSIGHT_KEY_STATUSES_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_INSIGHT_KEY_STATUSES_SUCCESS:
      return {
        ...state,
        data,
        isFetching: false
      }
    case FETCH_INSIGHT_KEY_STATUSES_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function kycReasonCodes(state = {}, { type, data }) {
  switch (type) {
    case FETCH_KYC_REASON_CODES_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_KYC_REASON_CODES_SUCCESS:
      return {
        ...state,
        data,
        isFetching: false
      }
    case FETCH_KYC_REASON_CODES_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function filteredBusinessesCSV(state = {}, { type, blob, errors }) {
  switch (type) {
    case FETCH_FILTERED_BUSINESSES_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_FILTERED_BUSINESSES_SUCCESS:
      downloadToBrowser(blob, 'businesses.csv')

      return {
        ...state,
        errors: null,
        isFetching: false,
        enqueued: false
      }
    case FETCH_FILTERED_BUSINESSES_FAILURE:
      return {
        ...state,
        errors,
        isFetching: false,
        enqueued: false
      }
    case CLEAR_FILTERED_BUSINESS_CSV:
      return {
        ...state,
        errors: null,
        isFetching: false,
        enqueued: false
      }
    case FETCH_FILTERED_BUSINESSES_ENQUEUED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: null,
        enqueued: true
      }
    default:
      return state
  }
}

function policyResults(state = {}, { type, data }) {
  switch (type) {
    case FETCH_POLICY_RESULTS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_POLICY_RESULTS_SUCCESS:
      return {
        ...state,
        data,
        isFetching: false
      }
    case FETCH_POLICY_RESULTS_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function partnerAccountUsersList(
  state = {},
  { type, data, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_ACCOUNT_USERS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_ACCOUNT_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_ACCOUNT_USERS_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function partnerAccountUserCreation(state = {}, response) {
  const { type, id, email, errors } = response

  switch (type) {
    case CREATE_PARTNER_ACCOUNT_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        success: null
      }
    case CREATE_PARTNER_ACCOUNT_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        success: true,
        id,
        email
      }
    case CREATE_PARTNER_ACCOUNT_USER_FAILURE:
      return {
        ...state,
        errors,
        isSubmitting: false,
        success: false
      }
    default:
      return state
  }
}

function partnerAccountUserReinvitation(state = {}, response) {
  const { type, id, email, errors } = response

  state = { ...state, id, email }

  switch (type) {
    case REINVITE_PARTNER_ACCOUNT_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        success: null
      }
    case REINVITE_PARTNER_ACCOUNT_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        success: true
      }
    case REINVITE_PARTNER_ACCOUNT_USER_FAILURE:
      return {
        ...state,
        errors,
        isSubmitting: false,
        success: false
      }
    default:
      return state
  }
}

function employerCommsList(
  state = {},
  { type, data, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_EMPLOYER_COMMS_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: null,
        hasMore: null,
        totalCount: null
      }
    case FETCH_EMPLOYER_COMMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_EMPLOYER_COMMS_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function employerMailUpdate(state = {}, response) {
  const { type, errors, mail } = response

  switch (type) {
    case UPDATE_EMPLOYER_MAIL_REQUEST:
      return {
        ...state,
        success: null
      }
    case UPDATE_EMPLOYER_MAIL_SUCCESS:
      return {
        ...state,
        success: true,
        mail
      }
    case UPDATE_EMPLOYER_MAIL_FAILURE:
      return {
        ...state,
        success: false,
        errors
      }
    default:
      return state
  }
}

function employerEmailForward(state = {}, response) {
  const { type, errors } = response

  switch (type) {
    case FORWARD_EMPLOYER_EMAIL_REQUEST:
      return {
        ...state,
        success: null
      }
    case FORWARD_EMPLOYER_EMAIL_SUCCESS:
      return {
        ...state,
        success: true
      }
    case FORWARD_EMPLOYER_EMAIL_FAILURE:
      return {
        ...state,
        success: false,
        errors
      }
    case CLEAR_FORWARD_EMPLOYER_EMAIL_REQUEST:
      return {
        ...state,
        success: null
      }
    default:
      return state
  }
}

function downloadBulkImportCSV(state = {}, { type, blob }) {
  switch (type) {
    case FETCH_BULK_ORDER_EXAMPLE_FILE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_BULK_ORDER_EXAMPLE_FILE_SUCCESS:
      downloadToBrowser(blob, 'bulk_import_example.csv')

      return {
        ...state,
        isFetching: false
      }
    case FETCH_BULK_ORDER_EXAMPLE_FILE_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function policies(state = {}, { type, data }) {
  switch (type) {
    case FETCH_POLICIES_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_POLICIES_SUCCESS:
      return {
        ...state,
        data,
        isFetching: false
      }
    case FETCH_POLICIES_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default: {
      return state
    }
  }
}

function policy(state = {}, { type, data, errors }) {
  switch (type) {
    case FETCH_POLICY_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_POLICY_SUCCESS:
      return {
        ...state,
        data,
        isFetching: false
      }
    case FETCH_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    case UPDATE_POLICY_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case UPDATE_POLICY_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case UPDATE_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    case CREATE_POLICY_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_POLICY_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case CREATE_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function currentPolicy(state = {}, { type, data }) {
  switch (type) {
    case FETCH_CURRENT_POLICY_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_CURRENT_POLICY_SUCCESS:
      return {
        ...state,
        data,
        isFetching: false
      }
    case FETCH_CURRENT_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default: {
      return state
    }
  }
}

function partnerAccountChildCompanyApplicationInvitations(
  state = {},
  { type, data, errors, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_ACCOUNT_APPLICATION_INVITATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function companyPasscode(state = {}, { type, data }) {
  switch (type) {
    case FETCH_PARTNER_COMPANY_PASSCODE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_COMPANY_PASSCODE_SUCCESS:
      return {
        ...state,
        ...data,
        isFetching: false
      }
    case FETCH_PARTNER_COMPANY_PASSCODE_FAILURE:
      return {
        ...state,
        isFetching: false,
        ...data
      }
    default: {
      return state
    }
  }
}

function informationRequests(
  state = {},
  { type, data, errors, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_EXCEPTIONS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_EXCEPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_EXCEPTIONS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function partnerCompanies(
  state = {},
  { type, data, errors, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_COMPANIES_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_COMPANIES_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_COMPANIES_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function partnerCommsList(
  state = {},
  { type, data, errors, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_COMMS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_COMMS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_COMMS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function partnerTaxAgencyRegistrations(
  state = {},
  { type, data, errors, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function partnerApplicationInvitations(
  state = {},
  { type, data, errors, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_APPLICATION_INVITATIONS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function partnerChildCompanyTaxAgencyRegistrations(
  state = {},
  { type, data, errors, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_CHILD_COMPANY_TAX_AGENCY_REGISTRATIONS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function partnerChildCompanyInsights(state = {}, { type, data, errors }) {
  switch (type) {
    case FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data
      }
    case FETCH_PARTNER_CHILD_COMPANY_INSIGHTS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function partnerChildCompanyStateStatuses(state = {}, { type, data, errors }) {
  switch (type) {
    case FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data
      }
    case FETCH_PARTNER_CHILD_COMPANY_STATE_STATUSES_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function partnerAgencyRegistrations(
  state = {},
  { type, data, errors, has_more: hasMore, total_count: totalCount }
) {
  switch (type) {
    case FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data,
        hasMore,
        totalCount
      }
    case FETCH_PARTNER_AGENCY_REGISTRATIONS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function downloadWatchlistSourcesCSV(state = {}, { type, blob }) {
  switch (type) {
    case FETCH_WATCHLIST_SOURCES_CSV_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_WATCHLIST_SOURCES_CSV_SUCCESS:
      downloadToBrowser(blob, 'watchlist_sources.csv')

      return {
        ...state,
        isFetching: false
      }
    case FETCH_WATCHLIST_SOURCES_CSV_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

function partnerAnalytics(state = {}, { type, response, errors }) {
  switch (type) {
    case FETCH_PARTNER_ANALYTICS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_PARTNER_ANALYTICS_REQUEST_SUCCESS:
      return {
        ...state,
        ...response,
        isFetching: false
      }
    case FETCH_PARTNER_ANALYTICS_REQUEST_FAILURE:
      return {
        ...state,
        errors,
        isFetching: false
      }
    default:
      return state
  }
}

function downloadFileExportFile(state = {}, { type, blob, errors }) {
  switch (type) {
    case FETCH_FILE_EXPORT_FILE_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true
      }
    case FETCH_FILE_EXPORT_FILE_SUCCESS:
      downloadToBrowser(blob, 'businesses.csv')

      return {
        ...state,
        errors: null,
        isFetching: false
      }
    case FETCH_FILE_EXPORT_FILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors
      }
    default:
      return state
  }
}

function affiliateReferral(state = {}, { type, data, error }) {
  switch (type) {
    case FETCH_AFFILIATE_REFERRAL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_AFFILIATE_REFERRAL_SUCCESS:
      return Object.assign({}, state, {
        data,
        isFetching: false
      })
    case FETCH_AFFILIATE_REFERRAL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error
      })
    default:
      return state
  }
}

function affiliateAccounts(state = {}, { type, data, errors }) {
  switch (type) {
    case FETCH_AFFILIATE_ACCOUNTS_REQUEST:
      return {
        ...state,
        ...data,
        isFetching: true
      }
    case FETCH_AFFILIATE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        ...data,
        totalCount: data.total_count,
        hasMore: data.has_more,
        isFetching: false
      }
    case FETCH_AFFILIATE_ACCOUNTS_FAILURE:
      return {
        ...state,
        ...data,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function affiliateUsers(state = {}, { type, data, errors }) {
  switch (type) {
    case FETCH_AFFILIATE_USERS_REQUEST:
      return {
        ...state,
        ...data,
        isFetching: true
      }
    case FETCH_AFFILIATE_USERS_SUCCESS:
      return {
        ...state,
        ...data,
        totalCount: data.total_count,
        hasMore: data.has_more,
        isFetching: false
      }
    case FETCH_AFFILIATE_USERS_FAILURE:
      return {
        ...state,
        ...data,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function affiliateProfiles(state = {}, { type, data, errors }) {
  switch (type) {
    case FETCH_AFFILIATE_PROFILES_REQUEST:
      return {
        ...state,
        ...data,
        isFetching: true
      }
    case FETCH_AFFILIATE_PROFILES_SUCCESS:
      return {
        ...state,
        ...data,
        totalCount: data.total_count,
        hasMore: data.has_more,
        isFetching: false
      }
    case FETCH_AFFILIATE_PROFILES_FAILURE:
      return {
        ...state,
        ...data,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function availableJurisdictions(state = {}, { type, data, errors }) {
  switch (type) {
    case FETCH_AVAILABLE_JURISDICTIONS_REQUEST:
      return {
        ...state,
        ...data,
        isFetching: true
      }
    case FETCH_AVAILABLE_JURISDICTIONS_SUCCESS:
      return {
        ...state,
        ...data,
        isFetching: false
      }
    case FETCH_AVAILABLE_JURISDICTIONS_FAILURE:
      return {
        ...state,
        ...data,
        isFetching: false,
        errors
      }
    default: {
      return state
    }
  }
}

function downloadSignalsBatchExample(state = {}, { type, blob }) {
  switch (type) {
    case FETCH_SIGNALS_BATCH_EXAMPLE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_SIGNALS_BATCH_EXAMPLE_SUCCESS:
      downloadToBrowser(blob, 'signals_batch.zip')

      return {
        ...state,
        isFetching: false
      }
    case FETCH_SIGNALS_BATCH_EXAMPLE_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

export default {
  agencies,
  api_log,
  api_logs,
  api_keys,
  partnerAccountChildCompanyApplicationInvitations,
  companyPasscode,
  audits,
  business,
  businessBatches,
  businesses,
  company,
  taxRegistrations,
  companies,
  partnerInsights,
  taxRegistration,
  tarExport,
  referralLink,
  currentPolicy,
  currentUser,
  documents,
  event,
  events,
  monitoringEvents,
  monitoringMonitors,
  modal,
  pdf,
  review,
  session,
  setupIntent,
  u,
  upgrade,
  users,
  website,
  webhookObservableEvents,
  webhook,
  webhook_log,
  webhook_logs,
  triggerEventAction,
  batchExampleCSV,
  tasks,
  invite_users,
  editBusinesses,
  sandbox,
  batchCSV,
  createCompany,
  settings,
  securedPartyDetails,
  insightKeys,
  insightKeyStatusMappings,
  kycReasonCodes,
  filteredBusinessesCSV,
  policyResults,
  partnerAccountUsersList,
  partnerAccountUserCreation,
  partnerAccountUserReinvitation,
  employerCommsList,
  employerMailUpdate,
  employerEmailForward,
  downloadBulkImportCSV,
  policy,
  policies,
  informationRequests,
  partnerAnalytics,
  partnerCompanies,
  partnerCommsList,
  partnerTaxAgencyRegistrations,
  partnerApplicationInvitations,
  partnerChildCompanyTaxAgencyRegistrations,
  partnerChildCompanyInsights,
  partnerChildCompanyStateStatuses,
  partnerAgencyRegistrations,
  downloadWatchlistSourcesCSV,
  downloadFileExportFile,
  affiliateReferral,
  affiliateAccounts,
  affiliateUsers,
  affiliateProfiles,
  availableJurisdictions,
  downloadSignalsBatchExample
}
