import React from 'react'

import { animated } from 'react-spring'
import styled, { CSSProperties } from 'styled-components'

const Container = styled(animated.div)<{ primaryColor: string }>`
  align-items: center;
  background: ${({ primaryColor }) => primaryColor};
  display: flex;
  flex-direction: row;
  height: 45px;
  justify-content: space-between;
  padding: 10px 40px;
  position: relative;
`

type BannerProps = {
  className?: string
  primaryColor: string
  children: React.ReactNode
  style?: CSSProperties
}

const PageBanner = ({
  primaryColor,
  style,
  children,
  className,
  ...props
}: BannerProps) => {
  return (
    <>
      <Container {...{ primaryColor, style, className, ...props }}>
        {children}
      </Container>
    </>
  )
}

export default PageBanner
