import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { RetryOptions } from '@reduxjs/toolkit/dist/query/retry'
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  retry
} from '@reduxjs/toolkit/query/react'

import { SANDBOX_DENYLIST } from 'constants/constants'
import { Order, WatchlistSource } from 'containers/BusinessHome/types'
import { LienTermination } from 'containers/Liens/types'
import { Env } from 'env'
import {
  APIKey,
  APIKeyCollection,
  APITags,
  FileExport,
  Identity,
  IndustryConstant,
  OAuthClient,
  OAuthClientCollection,
  ObservableEventCollection,
  Package,
  PaginationParams,
  RootState,
  Signal,
  SignalsBatch,
  SSOConnection,
  User,
  UserProfile,
  Webhook,
  WebhookCollection
} from 'types'

const SANDBOX_HEADER = 'X-Middesk-Sandbox-Mode'

type RequestInfo = Request | string

const prepareHeaders = (headers: Headers, state: RootState): Headers => {
  const token = state.session?.data?.user?.access_token
  const sandboxMode = state.sandbox?.sandboxMode

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  if (sandboxMode) {
    headers.set(SANDBOX_HEADER, 'true')
  }

  return headers
}

const prepareRequest = (request: RequestInfo): RequestInfo => {
  if (request instanceof Request && request.headers.get(SANDBOX_HEADER)) {
    const headers = request.headers

    /**
     * Certain endpoints are unavailable in sandbox. Remove the sandbox header for
     * these cases to ensure they hit a live environment.
     * Using includes since the full request.url contains the origin whereas the path does not.
     **/
    if (SANDBOX_DENYLIST.some(path => request.url.includes(path))) {
      headers.delete(SANDBOX_HEADER)
    }
  }

  return request
}

export const baseQuery = (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: RetryOptions
) => {
  return retry(
    fetchBaseQuery({
      baseUrl: `${Env.REACT_APP_API_HOST}/`,
      prepareHeaders: (headers, { getState }) =>
        prepareHeaders(headers, getState() as RootState),
      fetchFn: (request: RequestInfo) => fetch(prepareRequest(request))
    }),
    { maxRetries: api.type === 'mutation' ? 0 : 3 }
  )(args, api, extraOptions)
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: Object.values(APITags),
  endpoints: builder => ({
    confirmAccount: builder.mutation<User, Record<string, unknown>>({
      query: body => ({ url: 'users/confirm', method: 'POST', body })
    }),
    changePassword: builder.mutation<
      Record<string, unknown>,
      Record<string, unknown>
    >({
      query: body => ({ url: 'password', method: 'POST', body })
    }),
    resetPassword: builder.mutation<
      Record<string, unknown>,
      Record<string, unknown>
    >({
      query: body => ({ url: 'password/reset', method: 'POST', body })
    }),
    getAPIKeys: builder.query<APIKeyCollection, void>({
      query: () => 'v1/api_keys'
    }),
    deleteAPIKey: builder.mutation<APIKeyCollection, Partial<APIKey>>({
      query: ({ id }) => ({ url: `v1/api_keys/${id}`, method: 'DELETE' })
    }),
    createAPIKey: builder.mutation<
      APIKey,
      { name: string; environment: string }
    >({
      query: body => ({ url: 'v1/api_keys', method: 'POST', body })
    }),
    getWebhooks: builder.query<WebhookCollection, string | null | void>({
      query: slug => {
        const route = 'v1/webhooks'

        return slug ? `${route}?account=${slug}` : route
      }
    }),
    getOAuthClients: builder.query<OAuthClientCollection, void>({
      query: () => 'v1/oauth_clients'
    }),
    createOAuthClient: builder.mutation<OAuthClient, Partial<OAuthClient>>({
      query: body => ({ url: 'v1/oauth_clients', method: 'POST', body })
    }),
    createWebhook: builder.mutation<Webhook, Webhook>({
      query: body => ({ url: 'v1/webhooks', method: 'POST', body })
    }),
    patchOAuthClient: builder.mutation<OAuthClient, OAuthClient>({
      query: body => ({
        url: `v1/oauth_clients/${body.client_id}`,
        method: 'PATCH',
        body
      })
    }),
    deleteWebhook: builder.mutation<WebhookCollection, Webhook>({
      query: ({ id }) => ({ url: `v1/webhooks/${id}`, method: 'DELETE' })
    }),
    getObservableEventTypes: builder.query<ObservableEventCollection, void>({
      query: () => '/ajax/webhooks/observable_events'
    }),
    patchUserProfile: builder.mutation<UserProfile, UserProfile>({
      query: body => ({
        url: 'me/profile',
        method: 'PATCH',
        body
      })
    }),
    terminateLien: builder.mutation<
      LienTermination,
      { lien_id: string; packet_number: string | null }
    >({
      query: ({ lien_id, packet_number }) => ({
        url: `v1/liens/${lien_id}/termination`,
        method: 'POST',
        body: { packet_number }
      })
    }),
    updateWebhook: builder.mutation<Webhook, Webhook>({
      query: body => ({ url: `v1/webhooks/${body.id}`, method: 'PATCH', body })
    }),
    impersonate: builder.mutation<
      Record<string, unknown>,
      Record<string, unknown>
    >({
      query: body => ({
        url: 'sessions/impersonate',
        method: 'POST',
        body
      })
    }),
    getIndustryConstants: builder.query<
      { data: IndustryConstant[] },
      { categories: string[] }
    >({
      query: ({ categories }) => ({
        url: `ajax/industry/constants?${categories
          .map(c => `categories[]=${c}`)
          .join('&')}`
      })
    }),
    getReviewTaskConstants: builder.query<
      { data: Record<string, string[]> },
      Record<string, never>
    >({
      query: () => ({
        url: 'ajax/review_task/constants'
      })
    }),
    // NOTE: using builder.mutation here because we do not want to cache the
    // result of this query since it is used for authentication.
    getSSOAdminPortalLink: builder.mutation<{ link: string }, void>({
      query: () => '/sso/settings/admin_portal_link'
    }),
    // NOTE: using builder.mutation here because we do not want to cache the
    // result of this query since it is used for authentication.
    getSSOConnections: builder.mutation<
      { connections: SSOConnection[] },
      { email: string }
    >({
      query: ({ email }) => {
        return {
          url: '/sso/connections',
          params: { email }
        }
      }
    }),
    // NOTE: using builder.mutation here because we do not want to cache the
    // result of this query since it is used for authentication.
    getSSOAuthURL: builder.mutation<
      { auth_url: string },
      { conn_id: string; nonce: string }
    >({
      query: ({ conn_id, nonce }) => {
        return {
          url: '/sso/auth_url',
          params: { conn_id, state: nonce }
        }
      }
    }),
    // NOTE: using builder.mutation here because we do not want to cache the
    // result of this query since it is used for authentication. We also do
    // not want to retry this request if it fails because the authorization
    // codes can only be used once and subsequent requests will result in a
    // different error.
    postSSOCallback: builder.mutation<
      { access_token: string },
      { code: string }
    >({
      query: ({ code }) => {
        return {
          method: 'POST',
          url: '/sso/callback',
          body: { code }
        }
      }
    }),
    getBusinessOrder: builder.query<
      Order,
      { businessId: string; orderId: string }
    >({
      query: ({ businessId, orderId }) => ({
        url: `v1/businesses/${businessId}/orders/${orderId}`
      })
    }),
    getBusinessOrders: builder.query<
      Order[],
      { businessId: string; packages?: Package[] }
    >({
      query: ({ businessId, packages }) => {
        return {
          url: `v1/businesses/${businessId}/orders`,
          params: { packages }
        }
      },
      transformResponse: (response: { data: Order[] }) => response.data
    }),
    getWatchlistSources: builder.query<
      { data: WatchlistSource[]; total_count: number },
      { enabled?: boolean; limit?: number }
    >({
      query: ({ enabled, limit }) => {
        return {
          url: 'ajax/watchlist_sources',
          params: { enabled, limit }
        }
      }
    }),
    getFileExports: builder.query<
      { data: FileExport[]; has_more: boolean; total_count: number },
      { type: string; paginationParams: PaginationParams }
    >({
      query: ({ type, paginationParams }) => ({
        url: 'ajax/file_exports',
        params: {
          ...paginationParams,
          type
        }
      }),
      providesTags: [APITags.BUSINESS_EXPORT]
    }),
    getSignal: builder.query<Signal, string | undefined>({
      query: id => ({ url: `v1/signals/${id}` })
    }),
    getSignals: builder.query<Signal[], PaginationParams>({
      query: params => ({ url: 'v1/signals', params }),
      transformResponse: ({ data }: { data: Signal[] }) => data
    }),
    createSignal: builder.mutation<
      Signal,
      { name: string; tin?: string; addresses: string[]; people: string[] }
    >({
      query: body => ({ url: 'v1/signals', method: 'POST', body })
    }),
    createSignalsBatch: builder.mutation<
      SignalsBatch,
      { name: string; csv: string; filename: string }
    >({
      query: body => ({ url: 'v1/signals/batches', method: 'POST', body })
    }),
    getSignalsBatch: builder.query<SignalsBatch, string>({
      query: id => ({ url: `v1/signals/${id}` })
    }),
    getIdentities: builder.query<Identity[], string>({
      query: q => ({ url: `ajax/identities/autocomplete?q=${q}&per_page=25` }),
      transformResponse: (response: { data: Identity[] }) => response.data
    }),
    createAdverseMediaFeedback: builder.mutation<
      void,
      {
        business_id: string
        reason_for_flag: string
        relevant_links: string[]
        adverse_media_screening_result_id?: string
        search_name: string
      }
    >({
      query: params => ({
        url: 'v1/adverse_media/feedback',
        method: 'POST',
        params
      })
    })
  })
})

export const {
  useGetAPIKeysQuery,
  useGetWebhooksQuery,
  useGetOAuthClientsQuery,
  useGetBusinessOrderQuery,
  useGetBusinessOrdersQuery,
  useGetObservableEventTypesQuery,
  useCreateOAuthClientMutation,
  useCreateWebhookMutation,
  useTerminateLienMutation,
  usePatchOAuthClientMutation,
  useUpdateWebhookMutation,
  useDeleteWebhookMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useConfirmAccountMutation,
  usePatchUserProfileMutation,
  useImpersonateMutation,
  useGetIndustryConstantsQuery,
  useGetReviewTaskConstantsQuery,
  useGetSSOAdminPortalLinkMutation,
  useGetSSOConnectionsMutation,
  useGetSSOAuthURLMutation,
  usePostSSOCallbackMutation,
  useGetWatchlistSourcesQuery,
  useDeleteAPIKeyMutation,
  useCreateAPIKeyMutation,
  useGetFileExportsQuery,
  useGetSignalQuery,
  useGetSignalsQuery,
  useCreateSignalMutation,
  useGetIdentitiesQuery,
  useCreateAdverseMediaFeedbackMutation,
  useCreateSignalsBatchMutation,
  useGetSignalsBatchQuery
} = api
