import React, { Suspense } from 'react'

import { Outlet } from 'react-router-dom'

import NavBar from 'components/NavBar'
import SideNavBar from 'components/SideNavBar'

const RootContainer: React.FC = () => {
  return (
    <div className='root__container'>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <SideNavBar />
          <div style={{ flexDirection: 'column', flexGrow: 1 }}>
            <NavBar />
            <Suspense fallback={() => <div />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RootContainer
