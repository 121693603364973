import React, { ReactElement } from 'react'

import { Button, theme } from '@middesk/components'
import styled from 'styled-components'

import cya from '../../macros/cya.macro' // eslint-disable-line no-relative-import-paths/no-relative-import-paths

const { colors } = theme

const StyledButton = styled(Button)`
  background: ${colors.dawn};
  border-color: ${colors.dawn};
  color: ${colors.graphite};

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background: ${colors.frost};
    border-color: ${colors.frost};
  }
`

const PlaceOrderButton = ({
  onClick
}: {
  onClick: () => void
}): ReactElement => {
  return (
    <StyledButton type='secondary' onClick={onClick} {...cya('placeOrder')}>
      Place order
    </StyledButton>
  )
}

export default PlaceOrderButton
