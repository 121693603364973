import { useState, useEffect, useCallback } from 'react'

import { captureException } from '@sentry/browser'

import { localStore, sessionStore } from 'utils/storage'

type StorageType = 'session' | 'local'

export function useStorage<T>(
  storageType: StorageType,
  key: string,
  initialValue?: T
) {
  const storage = storageType === 'local' ? localStore : sessionStore

  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const item = storage.getItem(key)

      return item ? (JSON.parse(item) as T) : initialValue
    } catch (error) {
      captureException(error)

      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      storage.setItem(key, JSON.stringify(value))
      setStoredValue(value)
    } catch (error) {
      captureException(error)
    }
  }

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (
        (storageType === 'local' &&
          event.storageArea === window.localStorage) ||
        (storageType === 'session' &&
          event.storageArea === window.sessionStorage) ||
        event.oldValue === '__factory__'
      ) {
        if (event.key === key) {
          const newValue = event.newValue
            ? (JSON.parse(event.newValue) as T)
            : initialValue

          if (newValue !== storedValue) {
            setStoredValue(newValue)
          }
        }
      }
    },
    [key, initialValue, storageType, storedValue]
  )

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [handleStorageChange])

  return [storedValue, setValue] as const
}

export default useStorage
