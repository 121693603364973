import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'normalize.css/normalize.css'

import React, { lazy, Suspense } from 'react'

import { GoogleOAuthProvider } from '@react-oauth/google'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom'
import styled from 'styled-components'

import Signout from 'components/Signout'
import ErrorBoundary from 'containers/ErrorBoundary'
import IdleTimeoutProvider from 'containers/IdleTimeout/Provider'
import { SandboxProvider } from 'containers/SandboxProvider'
import SegmentProvider from 'containers/SegmentProvider'

import DeveloperRoute from './components/DeveloperRoute'
import Footer from './components/Footer'
import Layout from './components/Layout'
import Modals from './components/Modals'
import PrivateRoute from './components/PrivateRoute'
import { Env } from './env'
import Segment from './lib/segment'
import store from './store'

import './index.css'

const Orders = lazy(() => import(/* webpackChunkName: 'Orders' */ 'containers/PlaceOrderFlow')) // prettier-ignore
const Summary = lazy(() => import(/* webpackChunkName: 'Summary' */ 'components/Summary')) // prettier-ignore
const ContactSales = lazy(() => import(/* webpackChunkName: 'ContactSales' */ 'components/Trial/ContactSales')) // prettier-ignore
const PricingOptions = lazy(() => import(/* webpackChunkName: 'PricingOptions' */ 'components/Trial/PricingOptions')) // prettier-ignore
const Purchase = lazy(() => import(/* webpackChunkName: 'Purchase' */ 'components/Trial/Purchase')) // prettier-ignore
const Home = lazy(() => import(/* webpackChunkName: 'Home' */ 'containers/Home')) // prettier-ignore
const AgentExport = lazy(() => import(/* webpackChunkName: 'AgentExport' */ 'containers/AgentExport')) // prettier-ignore
const Businesses = lazy(() => import(/* webpackChunkName: 'Businesses' */ 'containers/Businesses')) // prettier-ignore
const BusinessHome = lazy(() => import(/* webpackChunkName: 'BusinessHome' */ 'containers/BusinessHome')) // prettier-ignore
const ChangePassword = lazy(() => import(/* webpackChunkName: 'ChangePassword' */ 'containers/ChangePassword')) // prettier-ignore
const AgentCompany = lazy(() => import('containers/AgentCompany'))
const Confirm = lazy(() => import(/* webpackChunkName: 'Confirm' */ 'containers/Confirm')) // prettier-ignore
const AddPolicyWithActions = lazy(() => import(/* webpackChunkName: 'AddPolicy' */ 'containers/Settings/Policies/PolicyForms/AddPolicy')) // prettier-ignore
const DuplicatePolicyWithActions = lazy(() => import(/* webpackChunkName: 'DuplicatePolicy' */ 'containers/Settings/Policies/PolicyForms/DuplicatePolicy')) // prettier-ignore
const EditPolicyWithActions = lazy(() => import(/* webpackChunkName: 'EditPolicy' */ 'containers/Settings/Policies/PolicyForms/EditPolicy')) // prettier-ignore
const PasswordReset = lazy(() => import(/* webpackChunkName: 'PasswordReset' */ 'containers/PasswordReset')) // prettier-ignore
const AddressSourceSettings = lazy(() => import(/* webpackChunkName: 'PasswordReset' */'containers/Settings/AddressesSources')) // prettier-ignore
const Batches = lazy(() => import(/* webpackChunkName: 'Batches' */ 'containers/Settings/Batches')) // prettier-ignore
const Credentials = lazy(() => import(/* webpackChunkName: 'Credentials' */ 'containers/Settings/Credentials')) // prettier-ignore
const Event = lazy(() => import(/* webpackChunkName: 'Event' */ 'containers/Settings/Event')) // prettier-ignore
const Events = lazy(() => import(/* webpackChunkName: 'Events' */ 'containers/Settings/Events')) // prettier-ignore
const Log = lazy(() => import(/* webpackChunkName: 'Log' */ 'containers/Settings/Log')) // prettier-ignore
const Logs = lazy(() => import(/* webpackChunkName: 'Logs' */ 'containers/Settings/Logs')) // prettier-ignore
const Monitoring = lazy(() => import(/* webpackChunkName: 'Monitoring' */ 'containers/Settings/Monitoring')) // prettier-ignore
const LienFiling = lazy(() => import(/* webpackChunkName: 'LienFiling' */ 'containers/Settings/LienFiling')) // prettier-ignore
const PoliciesWithActions = lazy(() => import(/* webpackChunkName: 'Policies' */ 'containers/Settings/Policies')) // prettier-ignore
const Profile = lazy(() => import(/* webpackChunkName: 'Profile' */ 'containers/Settings/Profile')) // prettier-ignore
const Team = lazy(() => import(/* webpackChunkName: 'Team' */ 'containers/Settings/Team')) // prettier-ignore
const Screenings = lazy(() => import(/* webpackChunkName: 'Screenings' */ 'containers/Settings/Screenings')) // prettier-ignore
const WebhookLogs = lazy(() => import(/* webpackChunkName: 'WebhookLogs' */ 'containers/Settings/WebhookLogs')) // prettier-ignore
const Webhooks = lazy(() => import(/* webpackChunkName: 'Webhooks' */ 'containers/Settings/Webhooks')) // prettier-ignore
const WebhookLogDetails = lazy(() => import(/* webpackChunkName: 'WebhookLogDetails' */ 'containers/Settings/Webhooks/WebhookLogDetails')) // prettier-ignore
const Setup = lazy(() => import(/* webpackChunkName: 'Setup' */ 'containers/Setup')) // prettier-ignore
const Signin = lazy(() => import(/* webpackChunkName: 'Signin' */ 'containers/Signin')) // prettier-ignore
const SSOCallback = lazy(() => import(/* webpackChunkName: 'SSOCallback' */ 'containers/SSOCallback')) // prettier-ignore
const ImpersonationCallback = lazy(() => import(/* webpackChunkName: 'ImpersonationCallback' */ 'containers/ImpersonationCallback')) // prettier-ignore
const Companies = lazy(() => import(/* webpackChunkName: 'Companies' */ 'containers/agent/Companies')) // prettier-ignore
const TaxAgencyAccounts = lazy(() => import(/* webpackChunkName: 'TaxAgencyAccounts' */ 'containers/agent/TaxAgencyRegistrations')) // prettier-ignore
const RequestedRegistrations = lazy(() => import(/* webpackChunkName: 'RequestedRegistrations' */ 'containers/agent/RequestedRegistrations')) // prettier-ignore
const Communications = lazy(() => import(/* webpackChunkName: 'Communications' */ 'containers/agent/Communications')) // prettier-ignore
const AgentHome = lazy(() => import(/* webpackChunkName: 'AgentHome' */ 'containers/agent/Home')) // prettier-ignore
const AgentInformationRequests = lazy(() => import(/* webpackChunkName: 'AgentInformationRequests' */ 'containers/agent/InformationRequests')) // prettier-ignore
const AgentAnalytics = lazy(() => import(/* webpackChunkName: 'AgentInformationRequests' */ 'containers/agent/Analytics')) // prettier-ignore
const BusinessExports = lazy(() => import(/* webpackChunkName: 'BusinessExports' */ 'containers/Settings/BusinessExports')) // prettier-ignore
const AffiliateReferralSignup = lazy(() => import(/* webpackChunkName: 'AffiliateReferralSignup' */ 'containers/agent/AffiliateReferralSignup')) // prettier-ignore
const Signals = lazy(() => import(/* webpackChunkName: 'Signals' */ 'containers/Signals')) // prettier-ignore
const Denylist = lazy(() => import(/* webpackChunkName: 'Denylist' */ 'containers/Settings/Denylist')) // prettier-ignore
const AgentAffiliate = lazy(() => import(/* webpackChunkName: 'AgentAffiliate' */ 'containers/agent/Affiliate')) // prettier-ignore
const PartnerAccountRedirect = lazy(() => import(/* webpackChunkName: 'AgentPartnerAccountRedirect' */ 'containers/agent/PartnerAccountRedirect')) // prettier-ignore

/* eslint-disable  @typescript-eslint/no-var-requires */
const { ModalProvider, BaseModalBackground } = require('styled-react-modal')
const viewportUnitsBuggyfill = require('viewport-units-buggyfill')
/* eslint-enable  @typescript-eslint/no-var-requires */

viewportUnitsBuggyfill.init()
Segment.init()

const ModalBackground = styled(BaseModalBackground)`
  &:before {
    background-color: rgba(255, 255, 255, 0.75);
    content: '';
    display: block;
    filter: blur(1px);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  background-color: rgba(0, 0, 0, 0.2);
`

const Root = () => {
  return (
    <Provider store={store}>
      <IdleTimeoutProvider>
        <GoogleOAuthProvider clientId={Env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
          <SegmentProvider>
            <SandboxProvider>
              <ModalProvider backgroundComponent={ModalBackground}>
                <Modals />
                <Layout />
                <Footer />
              </ModalProvider>
            </SandboxProvider>
          </SegmentProvider>
        </GoogleOAuthProvider>
      </IdleTimeoutProvider>
    </Provider>
  )
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '*',
        element: <Navigate to='/' replace />
      },
      {
        path: '/',
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        )
      },
      {
        path: 'signout',
        element: <Signout />
      },
      {
        path: 'setup',
        element: <Setup />
      },
      {
        path: 'signin',
        element: <Signin />
      },
      {
        path: 'confirm',
        element: <Confirm />
      },
      {
        path: 'forgot-password',
        element: <PasswordReset />
      },
      {
        path: 'reset-password',
        element: <ChangePassword />
      },
      {
        path: 'sso-callback',
        element: <SSOCallback />
      },
      { path: 'impersonation-callback', element: <ImpersonationCallback /> },
      {
        path: 'summaries/:id',
        element: <Summary />
      },
      {
        path: 'affiliate_referral/:code',
        element: <AffiliateReferralSignup />
      },
      {
        path: 'pricing-options',
        element: (
          <PrivateRoute>
            <PricingOptions />
          </PrivateRoute>
        )
      },
      {
        path: 'purchase-plan',
        element: (
          <PrivateRoute>
            <Purchase />
          </PrivateRoute>
        )
      },
      {
        path: 'contact-sales',
        element: (
          <PrivateRoute>
            <ContactSales />
          </PrivateRoute>
        )
      },
      /* Settings */
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: (
              <PrivateRoute>
                <Navigate to='profile' replace />
              </PrivateRoute>
            )
          },
          {
            path: 'profile',
            element: (
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            )
          },
          {
            path: 'team',
            element: (
              <PrivateRoute roles={['admin']}>
                <Team />
              </PrivateRoute>
            )
          },
          {
            path: 'batches',
            element: (
              <PrivateRoute>
                <Batches />
              </PrivateRoute>
            )
          },
          {
            path: 'policies',
            element: (
              <PrivateRoute>
                <PoliciesWithActions />
              </PrivateRoute>
            )
          },
          {
            path: 'policies/new',
            element: (
              <PrivateRoute roles={['admin']}>
                <AddPolicyWithActions />
              </PrivateRoute>
            )
          },
          {
            path: 'policies/:id/edit',
            element: (
              <PrivateRoute roles={['admin']}>
                <EditPolicyWithActions />
              </PrivateRoute>
            )
          },
          {
            path: 'policies/:id/duplicate',
            element: (
              <PrivateRoute roles={['admin']}>
                <DuplicatePolicyWithActions />
              </PrivateRoute>
            )
          },
          {
            path: 'monitoring',
            element: (
              <PrivateRoute>
                <Monitoring />
              </PrivateRoute>
            )
          },
          {
            path: 'lien-filing',
            element: (
              <PrivateRoute features={['lien_filing']}>
                <LienFiling />
              </PrivateRoute>
            )
          },
          {
            path: 'credentials',
            element: (
              <DeveloperRoute>
                <Credentials />
              </DeveloperRoute>
            )
          },

          {
            path: 'events',
            element: (
              <DeveloperRoute>
                <Events />
              </DeveloperRoute>
            )
          },
          {
            path: 'webhooks/:id/logs/:log_id/event/:event_id',
            element: (
              <DeveloperRoute>
                <Event />
              </DeveloperRoute>
            )
          },
          {
            path: 'logs',
            element: (
              <DeveloperRoute>
                <Logs />
              </DeveloperRoute>
            )
          },
          {
            path: 'logs/:id',
            element: (
              <DeveloperRoute>
                <Log />
              </DeveloperRoute>
            )
          },
          {
            path: 'webhooks',
            element: (
              <DeveloperRoute>
                <Webhooks />
              </DeveloperRoute>
            )
          },
          {
            path: 'screenings',
            element: (
              <DeveloperRoute>
                <Screenings />
              </DeveloperRoute>
            )
          },
          {
            path: 'address_sources',
            element: (
              <DeveloperRoute>
                <AddressSourceSettings />
              </DeveloperRoute>
            )
          },
          {
            path: 'business_exports',
            element: (
              <PrivateRoute>
                <BusinessExports />
              </PrivateRoute>
            )
          },
          {
            path: 'webhooks/:id/logs',
            element: (
              <DeveloperRoute>
                <WebhookLogs />
              </DeveloperRoute>
            )
          },
          {
            path: 'webhooks/:id/logs/:log_id',
            element: (
              <DeveloperRoute>
                <WebhookLogDetails />
              </DeveloperRoute>
            )
          },
          {
            path: 'keys',
            element: (
              <DeveloperRoute>
                <Navigate to='credentials' replace />
              </DeveloperRoute>
            )
          },
          {
            path: 'developer',
            element: (
              <DeveloperRoute>
                <Navigate to='credentials' replace />
              </DeveloperRoute>
            )
          },
          {
            path: 'developers',
            element: (
              <DeveloperRoute>
                <Navigate to='developer' replace />
              </DeveloperRoute>
            )
          },
          {
            path: 'denylist',
            element: (
              <DeveloperRoute>
                <Denylist />
              </DeveloperRoute>
            )
          }
        ]
      },

      /* Identity */
      {
        path: 'businesses',
        element: (
          <PrivateRoute>
            <Businesses />
          </PrivateRoute>
        )
      },
      {
        path: 'businesses/:id/*',
        element: (
          <PrivateRoute features={['identity']}>
            <BusinessHome />
          </PrivateRoute>
        )
      },
      {
        path: 'order',
        element: (
          <PrivateRoute>
            <Orders />
          </PrivateRoute>
        )
      },
      /* Signals */
      {
        path: 'signals/:id?',
        element: (
          <PrivateRoute>
            <Signals />
          </PrivateRoute>
        )
      },
      /* Agent */
      {
        path: 'agent',
        element: (
          <PrivateRoute>
            <PartnerAccountRedirect />
          </PrivateRoute>
        )
      },
      {
        path: 'entity',
        element: (
          <PrivateRoute>
            <AgentHome />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/export',
        element: (
          <PrivateRoute>
            <AgentExport />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/affiliate',
        element: (
          <PrivateRoute>
            <AgentAffiliate />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner',
        element: (
          <PrivateRoute>
            <PartnerAccountRedirect />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id',
        element: (
          <PrivateRoute>
            <PartnerAccountRedirect>
              <AgentHome />
            </PartnerAccountRedirect>
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/companies',
        element: (
          <PrivateRoute>
            <Companies />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/tax_agency_accounts',
        element: (
          <PrivateRoute>
            <TaxAgencyAccounts />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/analytics',
        element: (
          <PrivateRoute>
            <AgentAnalytics />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/requested_registrations',
        element: (
          <PrivateRoute>
            <RequestedRegistrations />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/communications',
        element: (
          <PrivateRoute>
            <Communications />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/companies/:company_id',
        element: (
          <PrivateRoute features={['tax_registrations_dashboard']}>
            <AgentCompany />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/companies/:company_id/requested_registrations',
        element: (
          <PrivateRoute features={['tax_registrations_dashboard']}>
            <AgentCompany />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/companies/:company_id/users',
        element: (
          <PrivateRoute features={['tax_registrations_dashboard']}>
            <AgentCompany />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/companies/:company_id/communications',
        element: (
          <PrivateRoute features={['tax_registrations_dashboard']}>
            <AgentCompany />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/companies/:company_id/local_taxes',
        element: (
          <PrivateRoute features={['tax_registrations_dashboard']}>
            <AgentCompany />
          </PrivateRoute>
        )
      },
      {
        path: 'entity/partner/:partner_id/information_requests',
        element: (
          <PrivateRoute>
            <AgentInformationRequests />
          </PrivateRoute>
        )
      }
    ]
  }
])

ReactDOM.render(
  <Suspense fallback={null}>
    <RouterProvider router={router} />
  </Suspense>,
  document.getElementById('root') || document.createElement('div') // For jest
)
