import React from 'react'

import { theme, Icons } from '@middesk/components'
import { ReactComponent as AddIcon } from 'ionicons/dist/ionicons/svg/ios-add.svg'
import { ReactComponent as DownArrowIcon } from 'ionicons/dist/ionicons/svg/ios-arrow-down.svg'
import { ReactComponent as CheckmarkCircleIcon } from 'ionicons/dist/ionicons/svg/ios-checkmark-circle-outline.svg'
import { ReactComponent as CheckmarkIcon } from 'ionicons/dist/ionicons/svg/ios-checkmark.svg'
import {
  ReactComponent as OfflineIcon,
  ReactComponent as XCircleIcon
} from 'ionicons/dist/ionicons/svg/ios-close-circle-outline.svg'
import { ReactComponent as CloseIcon } from 'ionicons/dist/ionicons/svg/ios-close.svg'
import { ReactComponent as ReviewGroupIcon } from 'ionicons/dist/ionicons/svg/ios-contacts.svg'
import { ReactComponent as DocIcon } from 'ionicons/dist/ionicons/svg/ios-document.svg'
import { ReactComponent as UnknownIcon } from 'ionicons/dist/ionicons/svg/ios-help-circle-outline.svg'
import { ReactComponent as FeedbackIcon } from 'ionicons/dist/ionicons/svg/ios-help.svg'
import { ReactComponent as LinkIcon } from 'ionicons/dist/ionicons/svg/ios-link.svg'
import { ReactComponent as MenuIcon } from 'ionicons/dist/ionicons/svg/ios-menu.svg'
import { ReactComponent as SearchIcon } from 'ionicons/dist/ionicons/svg/ios-search.svg'
import { ReactComponent as WarningIcon } from 'ionicons/dist/ionicons/svg/ios-warning.svg'
import { ReactComponent as BookmarkIcon } from 'ionicons/dist/ionicons/svg/md-bookmark.svg'
import { ReactComponent as BusinessIcon } from 'ionicons/dist/ionicons/svg/md-business.svg'
import { ReactComponent as ContactsIcon } from 'ionicons/dist/ionicons/svg/md-contacts.svg'
import { ReactComponent as CopyIcon } from 'ionicons/dist/ionicons/svg/md-copy.svg'
import { ReactComponent as EditIcon } from 'ionicons/dist/ionicons/svg/md-create.svg'
import { ReactComponent as ExitIcon } from 'ionicons/dist/ionicons/svg/md-exit.svg'
import { ReactComponent as FlagIcon } from 'ionicons/dist/ionicons/svg/md-flag.svg'
import { ReactComponent as HomeIcon } from 'ionicons/dist/ionicons/svg/md-home.svg'
import { ReactComponent as ListIcon } from 'ionicons/dist/ionicons/svg/md-list.svg'
import { ReactComponent as RefreshIcon } from 'ionicons/dist/ionicons/svg/md-refresh.svg'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import { ReactComponent as AccountIcon } from './agent/account.svg'
import { ReactComponent as ClipboardIcon } from './agent/clipboard.svg'
import { ReactComponent as ClipboardHoverIcon } from './agent/clipboard_hover.svg'
import { ReactComponent as ClockIcon } from './agent/clock.svg'
import { ReactComponent as DeveloperIcon } from './agent/developer.svg'
import { ReactComponent as DocumentIcon } from './agent/document.svg'
import { ReactComponent as EnvelopeIcon } from './agent/envelope.svg'
import { ReactComponent as GrowthIcon } from './agent/growth.svg'
import { ReactComponent as AgentLinkIcon } from './agent/link.svg'
import { ReactComponent as MailIcon } from './agent/mail.svg'
import { ReactComponent as MailCircleIcon } from './agent/mail_circle.svg'
import { ReactComponent as MoneyIcon } from './agent/money.svg'
import { ReactComponent as NewDocumentIcon } from './agent/new_document.svg'
import { ReactComponent as OnlinePortalIcon } from './agent/online_portal.svg'
import { ReactComponent as PhoneIcon } from './agent/phone.svg'
import { ReactComponent as SendAnInviteIcon } from './agent/send-an-invite.svg'
import { ReactComponent as TimeIcon } from './agent/time.svg'
import { ReactComponent as UserIcon } from './agent/user.svg'
import { ReactComponent as AutomationFilledIcon } from './automation-filled.svg'
import { ReactComponent as AutomationIcon } from './automation-outlined.svg'
import { ReactComponent as BuildingIcon } from './building.svg'
import { ReactComponent as BusinessOnboardingFilledIcon } from './business-onboarding-filled.svg'
import { ReactComponent as BusinessOnboardingIcon } from './business-onboarding-outlined.svg'
import { ReactComponent as CaretDownOutlineIcon } from './caret-down.svg'
import { ReactComponent as CaretForwardOutlineIcon } from './caret-forward.svg'
import { ReactComponent as CaretRightIcon } from './caret-right.svg'
import { ReactComponent as CircleLogoIcon } from './circle_logo.svg'
import { ReactComponent as CodeIcon } from './code.svg'
import { ReactComponent as ErrorIcon } from './error.svg'
import { ReactComponent as EyeIcon } from './eye.svg'
import { ReactComponent as FacebookIcon } from './facebook.svg'
import { ReactComponent as GearFilledIcon } from './gear-filled.svg'
import { ReactComponent as GearIcon } from './gear-outlined.svg'
import { ReactComponent as LockShieldIcon } from './lock-shield.svg'
import { ReactComponent as MiddeskNameIcon } from './middesk-name.svg'
import { ReactComponent as MiddeskIcon } from './middesk.svg'
import { ReactComponent as OverflowMenuIcon } from './overflow_menu.svg'
import { ReactComponent as PlusCircleIcon } from './plus-circle.svg'
import { ReactComponent as PlusIcon } from './plus.svg'
import { ReactComponent as QuestionIcon } from './question-mark.svg'
import { ReactComponent as SearchNoResultsIcon } from './search-no-results.svg'
import { ReactComponent as SignalsIcon } from './signals.svg'
import { ReactComponent as TaxRegistrationFilledIcon } from './tax-registrations-filled.svg'
import { ReactComponent as TaxRegistrationIcon } from './tax-registrations-outlined.svg'
import { ReactComponent as TrashIcon } from './trash.svg'
import { ReactComponent as TwitterIcon } from './twitter.svg'
import { ReactComponent as VerifyPersonIcon } from './verify-person.svg'

const { colors } = theme
const { Warning: WarningComponent } = Icons

export const CheckmarkCircle = CheckmarkCircleIcon
export const XCircle = XCircleIcon

export const Middesk = MiddeskIcon
export const MiddeskName = MiddeskNameIcon

export const Social = ({ type }: { type: string }) => {
  switch (type) {
    case 'facebook':
      return <FacebookIcon />
    case 'twitter':
      return <TwitterIcon />
    default:
      return null
  }
}

Social.propTypes = {
  type: PropTypes.string
}
const RedWarningIcon = styled(WarningComponent)`
  height: 16px;
  margin-right: 4px;
  stroke: ${colors.red};
  width: 16px;
`

export const Add = AddIcon
export const AutomationFilled = AutomationFilledIcon
export const Automation = AutomationIcon
export const Bookmark = BookmarkIcon
export const Building = BuildingIcon
export const Business = BusinessIcon
export const CaretDown = CaretDownOutlineIcon
export const CaretForward = CaretForwardOutlineIcon
export const CaretRight = CaretRightIcon
export const Checkmark = CheckmarkIcon
export const CircleLogo = CircleLogoIcon
export const ClipboardHover = ClipboardHoverIcon
export const Close = CloseIcon
export const Contacts = ContactsIcon
export const Copy = CopyIcon
export const Developer = DeveloperIcon
export const Doc = DocIcon
export const Document = DocumentIcon
export const DownArrow = DownArrowIcon
export const Edit = EditIcon
export const Error = ErrorIcon
export const Exit = ExitIcon
export const Feedback = FeedbackIcon
export const Flag = FlagIcon
export const Growth = GrowthIcon
export const Home = HomeIcon
export const Link = LinkIcon
export const List = ListIcon
export const LockShield = LockShieldIcon
export const Mail = MailIcon
export const MailCircle = MailCircleIcon
export const Account = AccountIcon
export const User = UserIcon
export const Menu = MenuIcon
export const Money = MoneyIcon
export const Offline = OfflineIcon
export const OnlinePortal = OnlinePortalIcon
export const OverflowMenu = OverflowMenuIcon
export const Phone = PhoneIcon
export const PlusCircle = PlusCircleIcon
export const Plus = PlusIcon
export const Question = QuestionIcon
export const RedWarning = RedWarningIcon
export const Refresh = RefreshIcon
export const ReviewGroup = ReviewGroupIcon
export const Search = SearchIcon
export const SearchNoResults = SearchNoResultsIcon
export const SendAnInvite = SendAnInviteIcon
export const Time = TimeIcon
export const Unknown = UnknownIcon
export const VerifyPerson = VerifyPersonIcon
export const Warning = WarningIcon
export const Trash = TrashIcon
export const Eye = EyeIcon
export const AgentLink = AgentLinkIcon
export const Envelope = EnvelopeIcon
export const Clipboard = ClipboardIcon
export const Clock = ClockIcon
export const NewDocument = NewDocumentIcon
export const BusinessOnboardingFilled = BusinessOnboardingFilledIcon
export const BusinessOnboarding = BusinessOnboardingIcon
export const Signals = SignalsIcon
export const TaxRegistrationFilled = TaxRegistrationFilledIcon
export const TaxRegistration = TaxRegistrationIcon
export const GearFilled = GearFilledIcon
export const Gear = GearIcon
export const Code = CodeIcon

export const X = styled(({ className, onMouseDown }) => (
  <CSSTransition timeout={250}>
    <Plus className={className} onMouseDown={onMouseDown} />
  </CSSTransition>
))`
  transform: rotate(45deg);
`
