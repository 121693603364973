import React from 'react'

import { theme } from '@middesk/components'
import styled from 'styled-components'

import {
  AutomationFilled,
  Automation,
  BusinessOnboardingFilled,
  BusinessOnboarding,
  TaxRegistration,
  TaxRegistrationFilled,
  Gear,
  GearFilled,
  Signals as SignalsIcon,
  Code
} from 'components/Icons'

import { SideNavIcon, SideNavIconOption } from '.'

const { colors } = theme

const SignalsContainer = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active }) =>
    active ? colors.graphite : 'transparent'};
  border: 1.5px solid ${colors.graphite};
  border-radius: 2px;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;

  svg {
    color: ${({ active }) => (active ? colors.white : colors.graphite)};
    height: 12px;
    width: 12px;
  }
`

const SignalsNavItem = ({ active = false }: { active?: boolean }) => (
  <SignalsContainer active={active}>
    <SignalsIcon />
  </SignalsContainer>
)

const entityOptions = (partner_id: string, has_agent_affiliates: boolean) => {
  if (has_agent_affiliates) {
    return [
      {
        label: 'Affiliates',
        linkAddress: '/entity/affiliate'
      }
    ]
  }

  return [
    {
      label: 'Home',
      linkAddress: `/entity/partner/${partner_id}`
    },
    {
      label: 'Export IDs',
      linkAddress: `/entity/partner/${partner_id}/export?hide_completed=true`
    },
    {
      label: 'Information requests',
      linkAddress: `/entity/partner/${partner_id}/information_requests`
    }
  ]
}

const settingsOptions = (
  roles: string[] | undefined,
  lienFilingEnabled: boolean
): SideNavIconOption[] => {
  let options: SideNavIconOption[] = [{ label: 'General', subHeading: true }]

  if (roles?.some(role => ['admin'].includes(role))) {
    options.push({ label: 'Team', linkAddress: '/settings/team' })
  }

  if (lienFilingEnabled) {
    options.push({ label: 'Lien filing', linkAddress: '/settings/lien-filing' })
  }

  if (roles?.some(role => ['admin', 'developer'].includes(role))) {
    options = options.concat([
      { label: 'Screenings', linkAddress: '/settings/screenings' },
      { label: 'Address sources', linkAddress: '/settings/address_sources' },
      { label: 'Developer', subHeading: true },
      { label: 'Credentials', linkAddress: '/settings/credentials' },
      { label: 'API Logs', linkAddress: '/settings/logs' },
      { label: 'Webhooks', linkAddress: '/settings/webhooks' }
    ])
  }

  return options
}

const environmentOption = {
  options: [
    { label: 'Live', sandboxMode: false },
    { label: 'Sandbox', sandboxMode: true },
    {
      label: 'Docs',
      externalLink: true,
      linkAddress: 'https://docs.middesk.com/docs/getting-started'
    }
  ],
  label: 'Environment',
  icon: <Code height={20} width={20} />
}

const identityIcons = (
  entityAccess: boolean | undefined,
  roles: string[] | undefined,
  signalsEnabled: boolean,
  lienFilingEnabled: boolean,
  partner_id: string,
  has_agent_affiliates: boolean
): SideNavIcon[] => {
  const icons: SideNavIcon[] = [
    {
      options: [
        { label: 'Businesses', linkAddress: '/businesses' },
        { label: 'Place order', linkAddress: '/order' },
        { label: 'Batch orders', linkAddress: '/settings/batches' },
        {
          label: 'Business exports',
          linkAddress: '/settings/business_exports'
        }
      ],
      label: 'Business onboarding',
      icon: <BusinessOnboarding height={20} width={20} />
    },
    {
      options: [
        { label: 'Policies', linkAddress: '/settings/policies' },
        { label: 'Monitoring', linkAddress: '/settings/monitoring' }
      ],
      label: 'Automation',
      icon: <Automation height={20} width={20} />
    },
    environmentOption
  ]

  if (signalsEnabled) {
    const signalsOption: SideNavIcon = {
      label: 'Signals',
      options: [],
      linkAddress: '/signals',
      icon: <SignalsNavItem />
    }

    icons.splice(1, 0, signalsOption)
  }

  if (entityAccess) {
    const entityOption: SideNavIcon = {
      options: entityOptions(partner_id, has_agent_affiliates),
      label: 'Tax registrations',
      linkAddress: '/agent',
      icon: <TaxRegistration height={20} width={20} />
    }

    icons.splice(1, 0, entityOption)
  }

  if (
    roles?.some(role => ['admin', 'developer'].includes(role)) ||
    lienFilingEnabled
  ) {
    const settingsOption: SideNavIcon = {
      options: settingsOptions(roles, lienFilingEnabled),
      label: 'Settings',
      icon: <Gear height={20} width={20} />
    }

    icons.splice(icons.length - 1, 0, settingsOption)
  }

  return icons
}

const agentIcons = (
  roles: string[] | undefined,
  lienFilingEnabled: boolean,
  partner_id: string,
  has_agent_affiliates: boolean
): SideNavIcon[] => {
  const icons: SideNavIcon[] = [
    {
      options: entityOptions(partner_id, has_agent_affiliates),
      label: 'Tax registrations',
      icon: <TaxRegistration height={20} width={20} />
    },
    environmentOption
  ]

  if (
    roles?.some(role => ['admin', 'developer'].includes(role)) ||
    lienFilingEnabled
  ) {
    const settingsOption: SideNavIcon = {
      options: settingsOptions(roles, lienFilingEnabled),
      label: 'Settings',
      icon: <Gear height={20} width={20} />
    }

    icons.splice(2, 0, settingsOption)
  }

  return icons
}

export const getIcons = (
  agentOnly: boolean,
  entityAccess: boolean | undefined,
  roles: string[] | undefined,
  signalsEnabled: boolean,
  lienFilingEnabled: boolean,
  partner_id: string,
  has_agent_affiliates: boolean
): SideNavIcon[] => {
  if (agentOnly) {
    return agentIcons(
      roles,
      lienFilingEnabled,
      partner_id,
      has_agent_affiliates
    )
  }

  return identityIcons(
    entityAccess,
    roles,
    signalsEnabled,
    lienFilingEnabled,
    partner_id,
    has_agent_affiliates
  )
}

export const selectedNavIcon = (selectedLabel: string): JSX.Element => {
  switch (selectedLabel) {
    case 'Business onboarding':
      return <BusinessOnboardingFilled height={20} width={20} />
    case 'Signals':
      return <SignalsNavItem active />
    case 'Tax registrations':
      return <TaxRegistrationFilled height={20} width={20} />
    case 'Automation':
      return <AutomationFilled height={20} width={20} />
    case 'Settings':
      return <GearFilled height={20} width={20} />
    default:
      return <></>
  }
}
