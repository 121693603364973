import flatten from 'lodash/flatten'
import remove from 'lodash/remove'

import {
  PRIMARY_TASK_IDENTIFIERS,
  SECONDARY_TASK_IDENTIFIERS,
  WEBSITE_ORDER_PRIMARY_TASK_IDENTIFIERS,
  WEBSITE_ORDER_SECONDARY_TASK_IDENTIFIERS
} from 'constants/constants'

const matchTasks = (tasks, taskList) =>
  taskList.map(identifier =>
    remove(
      tasks,
      task =>
        task.category === identifier.category && task.key === identifier.key
    )
  )

const splitTasks = (tasks, orders = []) => {
  if (!tasks) {
    return null
  }

  let primaryTaskIdentifiers = PRIMARY_TASK_IDENTIFIERS
  let secondaryTaskIdentifiers = SECONDARY_TASK_IDENTIFIERS

  const websiteOnlyOrder =
    orders.find(({ package: package_type }) => package_type === 'website') !==
      undefined && orders.length === 1

  if (websiteOnlyOrder) {
    primaryTaskIdentifiers = WEBSITE_ORDER_PRIMARY_TASK_IDENTIFIERS
    secondaryTaskIdentifiers = WEBSITE_ORDER_SECONDARY_TASK_IDENTIFIERS
  }

  const primaryTasks = flatten(matchTasks(tasks, primaryTaskIdentifiers))
  const secondaryTasks = flatten(matchTasks(tasks, secondaryTaskIdentifiers))

  // `tasks` array is modified by `matchTasks`
  // above and should be empty at this point.
  // Any tasks that have not been matched will be
  // pushed into secondaryTasks as a fallback.
  secondaryTasks.push.apply(secondaryTasks, tasks)

  // Filtering out old 'Registered Agent' task if new high risk address task is applicable
  // Also filtering out old 'CMRA' task if the new high risk address task is applicable
  const secondaryTaskKeys = secondaryTasks.map(task => task.key)

  // For Enhanced Screening Demo - Frontend should hide 'U.S. Watchlists' task
  // in favor of 'Global and U.S. Watchlist' task
  const primaryTaskKeys = primaryTasks.map(task => task.key)

  if (
    primaryTaskKeys.includes('watchlist') &&
    primaryTaskKeys.includes('global_watchlists')
  ) {
    return {
      primaryTasks: primaryTasks.filter(task => task.key !== 'watchlist'),
      secondaryTasks
    }
  }
  if (
    secondaryTaskKeys.includes('address_registered_agent') &&
    secondaryTaskKeys.includes('address_high_risk')
  ) {
    return {
      primaryTasks,
      secondaryTasks: secondaryTasks.filter(
        task => task.key !== 'address_registered_agent'
      )
    }
  }

  if (
    secondaryTaskKeys.includes('address_cmra') &&
    secondaryTaskKeys.includes('address_high_risk')
  ) {
    return {
      primaryTasks,
      secondaryTasks: secondaryTasks.filter(task => task.key !== 'address_cmra')
    }
  }

  return {
    primaryTasks,
    secondaryTasks,
    allTasks: [...primaryTasks, ...secondaryTasks]
  }
}

export default splitTasks
