import { createContext } from 'react'

export type IdleTimeoutContextType = {
  timeUntilSignout?: number
  active?: boolean
}

export const IdleTimeoutContext = createContext<IdleTimeoutContextType>({})

export const IDLE_TIMEOUT_MODAL_KEY = 'idle-timeout'
