import { STATES, STATES_ARRAY } from 'constants/constants'
import { Task } from 'containers/Businesses/BusinessList/types'
import { Registration } from 'containers/BusinessHome/types'
import { AbrvState } from 'types'

export const ORDER_TYPES = [
  'adverse_media',
  'bankruptcies',
  'enhanced_screenings',
  'documents',
  'identity',
  'business_verification_qualify',
  'business_verification_verify',
  'kyc',
  'liens',
  'litigations',
  'people_bankruptcies',
  'tin',
  'tax_liens',
  'ucc_liens',
  'watchlist',
  'website'
]

const isOrderPage = (section: string): boolean => {
  return ORDER_TYPES.includes(section)
}

export function filterSectionTasks(
  tasks: Task[] = [],
  orders: string[],
  sectionReviewTaskMap: Record<string, string[]>,
  section?: string
): Task[] {
  if (!sectionReviewTaskMap) return []
  if (!section) return tasks

  const orderTypes = isOrderPage(section) ? [section] : orders

  return tasks.filter(({ key }) =>
    orderTypes.some(order => {
      return (sectionReviewTaskMap[order] || []).includes(key)
    })
  )
}

type Filing = [AbrvState, string, string]

type FilingGroups = {
  active: Array<Filing>
  inactive: Array<Filing>
  unknown: Array<Filing>
  none: Array<[AbrvState, null, null]>
  total: number
}

export function groupByState(registrations: ReadonlyArray<Registration>) {
  const statuses: FilingGroups = {
    active: [],
    inactive: [],
    unknown: [],
    none: [],
    total: 0
  }

  const groups = STATES_ARRAY.reduce((current, state) => {
    return {
      ...current,
      [state.abbr]: []
    }
  }, {} as Record<AbrvState | 'all', Array<Registration>>)

  for (const registration of registrations) {
    const { state } = registration

    if (!groups[state]) {
      groups[state] = []
    }

    groups[state].push(registration)
  }

  Array.from(STATES.entries()).forEach(([abbr]) => {
    if (abbr === 'all') {
      return
    }

    const group = groups[abbr] || []

    for (const state of group) {
      if (state.status === 'active') {
        statuses.active.push([abbr, 'active', state.id])
      } else if (state.status === 'unknown') {
        statuses.unknown.push([abbr, 'unknown', state.id])
      } else if (!state.status || state.status === 'inactive') {
        statuses.inactive.push([abbr, 'inactive', state.id])
      }
    }

    if (group.length === 0) {
      statuses.none.push([abbr, null, null])
    }
  })

  statuses.total =
    statuses.active.length + statuses.inactive.length + statuses.unknown.length

  return statuses
}
