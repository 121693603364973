import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { v4 as uuid } from 'uuid'

import { openModal } from 'actions'
import { useAccountFeature } from 'hooks/useAccountFeature'
import { EVENTS, track } from 'lib/segment'
import { RootState } from 'types'

import PlaceOrderButton from './PlaceOrderButton'

const PlaceOrder = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const { sandboxMode } = useSelector((state: RootState) => state.sandbox)
  const { id = '' } =
    useSelector(({ business }: RootState) => business?.data) || {}
  const sessionId = uuid()
  const locationState = {
    origin: pathname + search,
    sessionId: sessionId,
    businessId: id
  }
  const v2PackagesEnabled = useAccountFeature('v2_packages')

  const openSandboxOrderModal = () => {
    dispatch(openModal('add-sandbox-business'))
  }

  return sandboxMode ? (
    <PlaceOrderButton onClick={openSandboxOrderModal} />
  ) : (
    <PlaceOrderButton
      onClick={() => {
        track(EVENTS.placeOrderClicked, {
          v2_packages_enabled: v2PackagesEnabled,
          business_id: id,
          session_id: sessionId
        })
        navigate('/order', { state: locationState })
      }}
    />
  )
}

export default PlaceOrder
