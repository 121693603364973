import { STATES } from 'constants/constants'
import { AbrvState } from 'types'

export const getFullStateNameFromAbbreviation = (
  stateAbbreviation: AbrvState
): string | null => {
  const state = STATES.get(stateAbbreviation)

  if (!state) {
    return null
  }

  return state.name
}
