import React from 'react'

import { Link, theme } from '@middesk/components'
import { useSelector } from 'react-redux'
import { useLocation, useMatch } from 'react-router'
import { getIsAuthenticated } from 'redux-simple-auth'
import styled from 'styled-components'

import MiddeskDarkIcon from 'components/Logos/MiddeskDarkIcon'
import { HIDE_NAV_BAR_ROUTES } from 'components/NavBar'
import { lienFilingSettingEnabled } from 'containers/LienFiling/utils'
import { useAccountFeatures } from 'hooks/useAccountFeatures'
import { RootState } from 'types'
import { agentOnly } from 'utils/agent'

import { getIcons, selectedNavIcon } from './constants'
import SideNavIcon from './SideNavIcon'

const { colors } = theme

const Header = styled.div<{ $sandboxMode?: boolean }>`
  height: fit-content;
  padding-top: 30px;
  position: sticky;
  top: 0;
  transition: all ease 0.37s;
  width: 74px;

  /* Z index is required here to ensure the nav appears over the header on the Businesses List */
  z-index: 5;

  ${({ $sandboxMode }) =>
    $sandboxMode &&
    `
    padding-top: 75px;
    top: -45px;
    transition: all ease 0.37s;
  `}
`

const NavBarContainer = styled.div`
  align-items: center;
  background-color: ${colors.frostLight};
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 100%;
  width: 74px;
`

const SideNavIconContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-left: 20px;
    &:first-child {
      margin-top: 24px;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

const MiddeskIconContainer = styled(Link)`
  margin-left: 20px;
`

export type SideNavIconOption = {
  label: string
  linkAddress?: string
  subHeading?: boolean
  sandboxMode?: boolean
  externalLink?: boolean
}

export type SideNavIcon = {
  options: SideNavIconOption[]
  label: string
  linkAddress?: string
  icon: JSX.Element
}

const SideNavBar = () => {
  const location = useLocation()
  const user = useSelector((state: RootState) => state.currentUser)
  const isAuthenticated = useSelector(s => getIsAuthenticated(s))
  const account = user.account
  const entityAccess = account?.agent_partner_profile?.dashboard_access
  const roles = user.roles

  const sandboxMode = useSelector(
    (state: RootState) => state.sandbox.sandboxMode
  )

  const userLoaded = !!user.settings
  const features = useAccountFeatures()
  const icons = getIcons(
    agentOnly(account),
    entityAccess,
    roles,
    !!features?.signals,
    userLoaded ? lienFilingSettingEnabled(undefined, user) : false,
    user.account?.id,
    user.account?.has_active_agent_affiliate_profiles
  )

  const isLoggedInUser = () => {
    if (!isAuthenticated || !user) {
      return false
    }

    const { account } = user

    if (!account) {
      return false
    }

    return true
  }

  const businessOrderAddOn = useMatch('/businesses/:id/add-on')
  const summaryMatch = useMatch('/summaries/:id')
  const to = '/businesses'

  if (
    (!isLoggedInUser() && !summaryMatch) ||
    HIDE_NAV_BAR_ROUTES.includes(location.pathname) ||
    businessOrderAddOn
  ) {
    return null
  }

  return (
    <Header $sandboxMode={sandboxMode}>
      <NavBarContainer>
        {/* eslint-disable-next-line jsx-a11y/tabindex-no-positive */}
        <MiddeskIconContainer tabIndex={1} to={to} aria-label='Middesk Home'>
          <MiddeskDarkIcon />
        </MiddeskIconContainer>
        <SideNavIconContainer>
          {icons.map((navIcon, i) => {
            const links = navIcon.options
              .filter(option => option.linkAddress !== undefined)
              .map(option => option.linkAddress)
              .flat()

            if (navIcon.linkAddress) {
              links.push(navIcon.linkAddress)
            }

            let selected = links.some(option =>
              location.pathname.includes(option as string)
            )

            if (
              location.pathname.match(/^\/agent*/) &&
              navIcon.linkAddress === '/agent'
            ) {
              selected = true
            }
            let icon = navIcon.icon
            let selectedMenuItem

            if (selected) {
              icon = selectedNavIcon(navIcon.label)
              selectedMenuItem = navIcon.options.filter(
                option =>
                  option.linkAddress === location.pathname + location.search
              )[0]?.label
            }

            return (
              <SideNavIcon
                tabIndex={i + 1}
                key={i}
                label={navIcon.label}
                options={navIcon.options}
                icon={icon}
                linkAddress={navIcon.linkAddress}
                selected={selected}
                selectedMenuItem={selectedMenuItem}
              />
            )
          })}
        </SideNavIconContainer>
      </NavBarContainer>
    </Header>
  )
}

export default SideNavBar
