import React, { useState } from 'react'

import { Dropdown, Button, theme } from '@middesk/components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import { RootState } from 'types'

import cya from '../../macros/cya.macro' // eslint-disable-line no-relative-import-paths/no-relative-import-paths

const { colors, typography } = theme

const UserContainer = styled.div`
  > div {
    color: ${colors.graphite};
  }

  > div:first-child {
    font-size: ${typography.sizes.large};
    font-weight: ${typography.weights.bold};
    padding-bottom: 5px;
    white-space: nowrap;
  }
`

const Container = styled.div`
  ${Dropdown.Menu} {
    width: fit-content;
  }
`

const StyledAvatarButton = styled(Button)`
  background-color: transparent;
  border: none;
  border-radius: 50%;
  margin: auto 0;
  max-height: min-content;
  min-width: min-content;
  padding: 0px;
`

const StyledButton = styled.button`
  text-align: left;
  width: 100%;
`

const UserDropdown = ({ size }: { size: number }) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(false)
  const user = useSelector((state: RootState) => state.session.data?.user)

  return user ? (
    <Container {...cya('UserDropdown')}>
      <Dropdown
        onToggle={({ isOpen }: { isOpen: boolean }) => setExpanded(isOpen)}
      >
        <Dropdown.Toggle>
          <StyledAvatarButton
            aria-expanded={expanded}
            aria-label='Account settings'
          >
            <Avatar
              src={user.image_url}
              name={user.name}
              size={`${size || 32}px`}
              round='50%'
            />
          </StyledAvatarButton>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Subheader>
            <UserContainer>
              <div>{user.name}</div>
              <div>{user.email}</div>
            </UserContainer>
          </Dropdown.Subheader>
          <StyledButton onClick={() => navigate('/settings/profile')}>
            <Dropdown.Option>Profile settings</Dropdown.Option>
          </StyledButton>
          <StyledButton onClick={() => navigate('/signout')}>
            <Dropdown.Option>Sign out</Dropdown.Option>
          </StyledButton>
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  ) : null
}

export default UserDropdown
